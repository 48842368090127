import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import Cookies from 'js-cookie';
import axios from 'axios';
import qs from 'qs';

import { backendUrl } from '@config';

import {
  authLoginRequest,
  authLoginSuccess,
  authLoginFailure,
  authLogoutRequest,
  authLogoutSuccess,
  authUnauthorized,
  authGotoNext,
  authGotoOn,
  authLoginChoice,
  authLoginNewPasswordSuccess,
  authLoginNewPasswordFailure,
} from '@actions/auth.actions';

import { getSecondData } from '../selectors/auth.selectors';

import { authLoginNewPasswordRequest } from '../actions/auth.actions';

function* gotoNext(action) {
  if (action.payload === 'grape') {
    const secondData = yield select(getSecondData);
    const { username, password } = secondData;
    yield call(
      () =>
        axios
          .request({
            method: 'POST',
            url: `${backendUrl}/api/login`,
            data: qs.stringify({
              password,
              user_name: username,
              login: 1,
            }),
            withCredentials: true,
          })
          .then(({ data }) => data),
      { username, password }
    );
    yield put(authGotoOn());
    window.location = backendUrl;
  } else {
    yield put(authGotoOn());
    window.location.reload();
  }
}

function* login(action) {
  const { username, password } = action.payload;
  try {
    const { login, valid } = yield call(
      ({ username: user_name, password }) =>
        axios
          .request({
            method: 'POST',
            url: `${backendUrl}/api/login`,
            data: qs.stringify({
              password,
              user_name,
              login: 1,
            }),
            withCredentials: true,
          })
          .then(({ data }) => data),
      { username, password }
    );

    if (!valid) {
      throw new Error('Invalid user');
    }

    const { session_id } = login;
    Cookies.set('identity_login_attempted', 0);
    Cookies.set('tg-visit', session_id);
    localStorage.removeItem('orgId');
    yield put({ type: 'RESET_STORE' });

    const { positions } = yield call(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/positions`,
          params: {
            fields: 'id,title',
            user_name: username,
          },
          withCredentials: true,
        })
        .then(({ data }) => data)
    );

    /*
     * CHECK IF USE SHOULD BE HERE OR IN GRAPE.
     * */
    const grape_names = [
      'Administrasjon',
      'Administrerende direktør',
      'Daglig leder',
      'Direktør',
      'Disponent',
      'Varehussjef',
      'Distriktssjef',
      'Driftsleder',
      'Driftssjef',
      'Hovedkontor - leder',
      'Hovedkontor - medarbeider',
      'Leder',
      'Regionssjef',
    ];

    let hasChoice = false;
    if (Array.isArray(positions)) {
      const pos = positions.some(p => grape_names.includes(p.title));
      hasChoice = pos;
    }
    if (!hasChoice) {
      yield put(authLoginSuccess({ authProfile: login }));
    } else {
      yield put(authLoginChoice({ authProfile: login, username, password }));
    }
  } catch (error) {
    yield put(
      stopSubmit('login', {
        errorMsg: 'Kunne ikke logge inn',
      })
    );
    yield put(authLoginFailure({ error }));
  }
}

function* newPassword(action) {
  try {
    const { username } = action.payload;
    const { data } = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          email_or_user_name: username,
        },
        url: `${backendUrl}/sendreminder`,
        withCredentials: true,
      })
    );

    if (data.statuscode === 0) {
      yield put(authLoginNewPasswordSuccess({ message: data.status }));
    } else {
      yield put(authLoginNewPasswordFailure({ message: data.status }));
    }
  } catch (error) {
    yield put(authLoginNewPasswordFailure({}));
  }
}

function* logout() {
  try {
    yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/api/logout`,
        withCredentials: true,
      })
    );
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    yield put(authLogoutSuccess());
  } catch (error) {
    yield put(authLogoutSuccess());
  }
}

export default [
  takeLatest(authLoginRequest().type, login),
  takeLatest(authLogoutRequest().type, logout),
  takeLatest(authLoginNewPasswordRequest().type, newPassword),
  takeLatest(authGotoNext().type, gotoNext),
  takeEvery(authUnauthorized().type, logout),
];
