import * as profileActions from '@actions/profile.actions';

const initialState = {
  person: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
    storeManager: false,
    orgId: null,
    organisations: null,
  },
  tab: 'role',
  competences: {
    isFetching: false,
    isSubFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  passed: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  passed_full: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  events: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  summary: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  show_role: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  roles: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  isFetching: false,
  updated: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case profileActions.PROFILE_FETCH_PERSON_REQUEST:
      return {
        ...state,
        person: {
          ...state.person,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PERSON_FULL:
      return {
        ...state,
        isFetching: true,
      };
    case profileActions.PROFILE_EDIT_PERSON_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case profileActions.PROFILE_EDIT_PERSON_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case profileActions.PROFILE_SET_MANAGER:
      return {
        ...state,
        person: {
          ...state.person,
          storeManager: true,
        },
      };
    case profileActions.SWITCH_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case profileActions.PROFILE_FETCH_PERSON_FULL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        updated: new Date(),
      };
    case profileActions.PROFILE_FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          isFetching: false,
          data: action.payload.events.personevents,
        },
      };
    case profileActions.PROFILE_FETCH_PERSON_SUCCESS:
      return {
        ...state,
        person: {
          ...state.person,
          isFetching: false,
          data: action.payload.person,
          competencelevel: action.payload.competencelevel,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_PERSON_FAILURE:
      return {
        ...state,
        person: {
          ...state.person,
          isFetching: false,
          data: null,
          error: action.payload.error,
          updated: null,
        },
      };
    case profileActions.PROFILE_SET_LAST_MESSAGE_TIMESTAMP:
      return {
        ...state,
        person: {
          ...state.person,
          data: {
            ...state.person.data,
            data: {
              ...state.person.data.data,
              last_message: action.payload.last_message,
            },
          },
        },
      };
    case profileActions.PROFILE_FETCH_ALLORGS_SUCCESS:
      return {
        ...state,
        person: {
          ...state.person,
          organisations: action.payload.data,
        },
      };
    case profileActions.PROFILE_FETCH_ROLE_REQUEST:
      return {
        ...state,
        show_role: {
          ...state.show_role,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_ROLE_SUCCESS:
      return {
        ...state,
        show_role: {
          ...state.show_role,
          isFetching: false,
          data: action.payload.role,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_ROLE_FAILURE:
      return {
        ...state,
        show_role: {
          ...state.show_role,
          isFetching: false,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.roles,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isFetching: false,
          data: action.payload.roles,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_ROLES_FAILURE:
      return {
        ...state,
        roles: {
          ...state.roles,
          isFetching: false,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {
          ...state.summary,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          isFetching: false,
          data: action.payload.summary,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          ...state.summary,
          isFetching: false,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_REQUEST:
      return {
        ...state,
        passed: {
          ...state.passed,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_SUCCESS:
      return {
        ...state,
        passed: {
          isFetching: false,
          data: action.payload.competences,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FAILURE:
      return {
        ...state,
        passed: {
          isFetching: null,
          data: null,
          updated: null,
          error: true,
        },
      };

    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_REQUEST:
      return {
        ...state,
        passed_full: {
          ...state.passed,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_SUCCESS:
      return {
        ...state,
        passed_full: {
          isFetching: false,
          data: action.payload.competences,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_PASSED_COMPETENCES_FULL_FAILURE:
      return {
        ...state,
        passed_full: {
          isFetching: null,
          data: null,
          updated: null,
          error: true,
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_REQUEST:
      return {
        ...state,
        competences: {
          ...state.competences,
          isFetching: true,
          data: null,
          updated: null,
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_REQUEST:
      return {
        ...state,
        competences: {
          ...state.competences,
          isSubFetching: true,
        },
      };
    case profileActions.PROFILE_FETCH_SHOWROLE_CHILDREN_REQUEST:
      return {
        ...state,
        competences: {
          ...state.competences,
          isSubFetching: true,
        },
      };
    case profileActions.PROFILE_FETCH_SHOWROLE_CHILDREN_SUCCESS:
      return {
        ...state,
        competences: {
          ...state.competences,
          isSubFetching: false,
        },
        show_role: {
          ...state.show_role,
          isSubFetching: false,
          data: action.payload.competences,
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_FAILURE:
      return {
        ...state,
        competences: {
          ...state.competences,
          isSubFetching: false,
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_CHILDREN_SUCCESS:
      return {
        ...state,
        competences: {
          ...state.competences,
          isSubFetching: false,
          data: action.payload.competences,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_SUCCESS:
      return {
        ...state,
        competences: {
          ...state.competences,
          isFetching: false,
          data: action.payload.competences,
          updated: new Date(),
        },
      };
    case profileActions.PROFILE_FETCH_COMPETENCES_FAILURE:
      return {
        ...state,
        competences: {
          ...state.competences,
          isFetching: false,
          data: null,
          error: action.payload.error,
          updated: null,
        },
      };
    default:
      return state;
  }
};

export default profile;
