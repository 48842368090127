import React from 'react';
import { Icon, Label } from '@src/components/Layout';
import { PropTypes } from 'prop-types';
import { backendUrl } from '@config';
import { format } from '@src/utils/date.utils';
import styled from 'styled-components';
import { capitalize } from '@src/utils/string.utils';
import DropdownSelect from '@src/components/DropdownSelect';
import { useAxiosRequest } from '@src/hooks/hooks';
import { colors } from '@src/styles/index';

const CourseEventPicker = ({
  courseId,
  placeholder,
  displayCoursesEventsOnTop,
  ...props
}) => {
  const {
    data: { events },
    loading,
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        children: 1,
        view: 'full',
        fields: 'location,startdate,enddate,title,competence_id',
      },
      url: `${backendUrl}/api/competences/${courseId}/events`,
      withCredentials: true,
    },
    [courseId]
  );

  const noCoursesAvailable =
    !loading && Array.isArray(events) && !events.length;

  const StyledLabel = styled(Label)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;
  `;

  return (
    <DropdownSelect
      {...props}
      css={`
        color: ${colors.link};

        ::placeholder {
          color: ${colors.font};
        }
      `}
      displayResultsOnTop={displayCoursesEventsOnTop}
      placeholder={
        !loading && (noCoursesAvailable ? 'Ingen kurs' : placeholder)
      }
      options={events}
      loading={loading}
      formatValue={value =>
        value && value.startdate
          ? format(new Date(value.startdate), "d. MMMM yyyy 'kl.' HH:mm")
          : null
      }
      dropdown={
        noCoursesAvailable && (
          <StyledLabel lineHeight="1.4" size="0.9em">
            Det er ingen kursdatoer for det valgte kurset
            <br />
            Velg et annet kurs eller en annen aktivitet
          </StyledLabel>
        )
      }
      renderOption={({ startdate, enddate, location: { title: location } }) => (
        <>
          <Label lineHeight="1.4">
            {`${capitalize(format(new Date(startdate), 'EEE d. MMM yyyy'))}
                            – ${format(new Date(enddate), 'd. MMM yyyy')}`}
          </Label>
          <Label lineHeight="1.4" size="0.9em">
            <Icon className="fa fa-clock" marginRight="1em" />
            {format(new Date(startdate), 'HH:mm')}
          </Label>
          <Label lineHeight="1.4" size="0.9em">
            <Icon className="fa fa-calendar" marginRight="1em" />
            {location}
          </Label>
        </>
      )}
    />
  );
};

CourseEventPicker.propTypes = {
  courseId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  displayCoursesEventsOnTop: PropTypes.bool.isRequired,
};

CourseEventPicker.defaultProps = {
  placeholder: null,
};

export default CourseEventPicker;
