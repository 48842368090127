import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@src/styles';
import { propsUtils } from '@src/utils';
import { Link } from 'react-router-dom';
import { Block, TextElement, A } from './LayoutBase';

const GridItem = styled.div``;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ width }) => width || 'auto'}, 1fr)
  );
  margin-right: -${({ gap }) => gap};

  ${GridItem} {
    margin-right: ${({ gap }) => gap};
  }
`;

export const Grid = ({ children, width: defaultWidth, gap, ...props }) => {
  const [width, setWidth] = useState(defaultWidth);

  const setColumnWidths = columnRef => {
    if (!columnRef || defaultWidth) {
      return;
    }

    for (let i = 0; i < columnRef.children.length; i += 1) {
      const { style } = columnRef.children[i];

      style.width = 'min-content';
      style.fontWeight = 'normal';
    }

    const maxWidth = Array.from(columnRef.children).reduce((maxWidth, elem) => {
      const elemWidth = elem.clientWidth;
      if (elemWidth > maxWidth) {
        return elemWidth;
      }
      return maxWidth;
    }, 0);

    for (let i = 0; i < columnRef.children.length; i += 1) {
      const { style } = columnRef.children[i];
      style.width = 'auto';
      style.fontWeight = null;
    }

    setWidth(`${maxWidth + parseFloat(gap)}px`);
  };

  return (
    <StyledGrid width={width} gap={gap} ref={setColumnWidths} {...props}>
      {children.map((child, key) => (
        <GridItem key={key}>{child}</GridItem>
      ))}
    </StyledGrid>
  );
};

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gap: PropTypes.number,
};

Grid.defaultProps = {
  width: null,
  gap: 0,
};

export const Seperator = styled(Block)`
  flex: 0 0 auto;
  background: ${colors.cellBorder};
  width: 100%;
  height: 1px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;

Seperator.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
};

export const Cell = styled(Block)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${({ grow }) => (grow ? '1' : '0')}
    ${({ shrink }) => (shrink ? '0' : '1')};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'no-wrap')};
  border: ${({ border }) =>
    border ? `1px solid ${colors.cellBorder}` : 'none'};
  border-radius: ${({ border }) => (border ? `3px` : '0')};
`;

Cell.propTypes = {
  grow: PropTypes.bool,
  shrink: PropTypes.bool,
  wrap: PropTypes.bool,
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.bool,
};

Cell.defaultProps = {
  grow: false,
  noShrink: false,
  wrap: false,
  flexBasis: 'auto',
  border: false,
};

export const Row = styled(Cell)`
  flex-direction: row;

  > ${Seperator} {
    width: 1px;
    height: 100%;
  }
`;

export const Column = styled(Cell)``;

export const Page = ({ title, ...props }) => {
  window.document.title = `${title} - NSF Utviklingssamtale`;

  return <Column grow {...props} />;
};

export const Section = styled(Column)`
  flex: 1 1 auto;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;
  @media screen and (max-width: 650px) {
    padding: 0 10px;
  }
`;

export const Label = styled(TextElement)`
  vertical-align: middle;
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};
`;

Label.PropTypes = {
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  inline: PropTypes.bool,
  noWrap: PropTypes.bool,
};

Label.defaultProps = {
  lineHeight: '1.25',
  bold: false,
  italic: false,
  inline: false,
  noWrap: false,
};

const StyledLinkLabel = styled(Label)`
  color: ${({ color }) => color || colors.link};
  cursor: pointer;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const LinkLabel = ({
  target,
  href,
  title,
  onClick,
  children,
  to,
  ...props
}) =>
  (to && (
    <Link to={to} title={title}>
      <StyledLinkLabel {...propsUtils.onClick(onClick)} {...props}>
        {children}
      </StyledLinkLabel>
    </Link>
  )) || (
    <A target={target} href={href} title={title}>
      <StyledLinkLabel {...propsUtils.onClick(onClick)} {...props}>
        {children}
      </StyledLinkLabel>
    </A>
  );

LinkLabel.propTypes = {
  target: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

LinkLabel.defaultProps = {
  target: null,
  href: null,
  title: null,
  onClick: null,
};

export const Icon = styled(TextElement)``;

Icon.PropTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Icon.defaultProps = {
  size: '1em',
  color: 'inherit',
};

export const CircleIcon = styled(Icon)`
  width: 2em;
  line-height: 2em;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
`;

export const HTML = ({ children }) => {
  return children
    ? React.Children.map(children, (child, key) => (
        <span
          ref={current => {
            if (current) {
              const div = current;
              div.innerHTML = child;
            }
          }}
          key={key}
        />
      ))
    : null;
};
