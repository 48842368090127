import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import LabeledInput from '@components/common/labeled-input';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { profileEditCV } from '@actions/cvs.actions';
import { DatePicker } from '@src/components/Input';
import { isValidDate } from '@src/utils/date.utils';
import CVEditExperienceType from './CVEditExperienceType';
import {
  WorkElems,
  EducationItems,
  ProjectItems,
  LanguageItems,
} from './CVView';
import UnsavedChangesDisplay from './UnsavedChangesBanner';

export const ManageWorkExperiencesFormElements = () => (
  <>
    <LabeledInput autoFocus label="Stilling" name="designation" isRequired />
    <LabeledInput label="Selskap" name="name" />
    <DatePicker small label="Fra" name="startdate" valueControlledByForm />
    <DatePicker
      valueControlledByForm
      small
      label="Til (tom hvis du ikke har sluttet)"
      name="enddate"
    />
  </>
);

export const ManageEducationFormElements = () => (
  <>
    <LabeledInput autoFocus label="Sted" name="designation" />
    <LabeledInput label="Navn på utdanning" name="name" />
    <LabeledInput
      type="number"
      min="1900"
      max="2099"
      label="Start (år)"
      step="1"
      name="startdate"
    />
    <LabeledInput
      type="number"
      lmin="1900"
      max="2099"
      label="Slutt (år)"
      step="1"
      name="enddate"
    />
  </>
);

export const ManageProjectFormElements = () => (
  <>
    <LabeledInput autoFocus label="Navn" name="designation" isRequired />
    <LabeledInput label="Beskrivelse" name="name" />
    <DatePicker small label="Fra" name="startdate" valueControlledByForm />
    <DatePicker
      small
      valueControlledByForm
      label="Til (blank hvis du ikke er ferdig)"
      name="enddate"
    />
  </>
);

export const ManageLanguageFormElements = () => (
  <>
    <LabeledInput autoFocus label="Språk" name="name" />
    <LabeledInput label="Ferdighet i språket" name="designation" />
  </>
);

const CVEdit = ({
  initialExperienceData,
  initialDescription,
  cvID,
  profileEditCV,
}) => {
  const [experienceData, setExperienceData] = useState(initialExperienceData);

  const [description, setDescription] = useState(initialDescription);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const saveData = () => {
    const padWithZeroIfLessThan10 = val => (val < 10 ? `0${val}` : val);
    const createDateString = date => {
      if (!date) {
        return '';
      }

      let dateToFormat = date;
      if (!isValidDate(date)) {
        dateToFormat = new Date(date);
      }
      if (/^[0-9]{4}$/.test(date)) {
        const year = new Date();
        year.setDate(0);
        year.setMonth(0);
        year.setHours(0);
        year.setMinutes(0);
        year.setMilliseconds(0);
        year.setFullYear(parseInt(date, 10));
        dateToFormat = year;
      }
      return `${dateToFormat.getFullYear()}-${padWithZeroIfLessThan10(
        dateToFormat.getMonth() + 1 // Since dates are 0-based in js but stored as 1-based
      )}-${padWithZeroIfLessThan10(dateToFormat.getDate())}`;
    };

    const flattedExperiences = [];
    Object.getOwnPropertyNames(experienceData).forEach(key => {
      experienceData[key].forEach(e => {
        const curr = e;
        curr.startdate = createDateString(curr.startdate);
        curr.enddate = createDateString(curr.enddate);
        curr.experience_type = key;
        delete curr.UID;
        flattedExperiences.push(curr);
      });
    });

    const dataRequest = {
      id: cvID,
      description,
      experiences: flattedExperiences,
    };

    profileEditCV({ data: dataRequest });
    setHasUnsavedChanges(false);
  };

  const updateData = (kind, changedData) => {
    const copyOfStoredExperienceData = experienceData;
    copyOfStoredExperienceData[kind] = changedData;
    setExperienceData(copyOfStoredExperienceData);
    setHasUnsavedChanges(true);
  };

  const Header = styled.h3`
    color: #494949;
    font-weight: 500;
    margin-left: 25px;
    margin-top: 15px;
  `;

  const Desc = styled.p`
    margin-left: 25px;
  `;

  const descTxtAreaStyle = {
    padding: '4px',
    marginBottom: '30px',
    marginLeft: '25px',
    border: '1px solid #c9c9c9',
    width: '80%',
  };

  const formSchemaWork = Yup.object().shape({
    designation: Yup.string().required('Stilling må fylles ut'),
    name: Yup.string(),
    startdate: Yup.string().nullable(),
    enddate: Yup.string().nullable(),
  });

  const formSchemaEducation = Yup.object().shape({
    designation: Yup.string().required('Sted må fylles ut'),
    name: Yup.string().required('Navn på utdanning må fylles ut'),
    startdate: Yup.string()
      .matches(/([0-9]{4})?/, 'År må være på dette formatet ÅÅÅÅ')
      .nullable(),
    enddate: Yup.string()
      .matches(/([0-9]{4})?/, 'År må være på dette formatet ÅÅÅÅ')
      .nullable(),
  });

  const formSchemaProject = Yup.object().shape({
    designation: Yup.string().required('Navn må fylles ut'),
    name: Yup.string(),
    startdate: Yup.string().nullable(),
    enddate: Yup.string().nullable(),
  });

  const formSchemalanguage = Yup.object().shape({
    name: Yup.string().required('Språk må fylles ut'),
    designation: Yup.string().required('Fedighet i språket må fylles ut'),
  });

  return (
    <>
      {hasUnsavedChanges && <UnsavedChangesDisplay onSave={saveData} />}
      <Header>Om</Header>
      <Desc>Generell beskrivelse av din kompetanse</Desc>

      {/* Do not wrap this element (or use in styled component). It will cause problems related to focus */}
      <textarea
        key="ierioreioreioreio"
        style={descTxtAreaStyle}
        rows="8"
        cols="80"
        value={description}
        onChange={evt => {
          setDescription(evt.target.value);
          setHasUnsavedChanges(true);
        }}
      />
      <CVEditExperienceType
        title="Arbeidserfaring"
        experienceKind="work"
        description="Registrer alle dine relevante jobberfaringer. Det er opp til deg selv å vurdere hva som er relevant eller ikke. Har du mange år i arbeidslivet, ta med det viktigste og det som er relevant ift. jobben du søker. Husk også å unngå “hull i CV-en” (perioder uten noen oppføringer i CV-en). Har du det, har du kanskje utelukket noe som bør være med."
        addNewBtnTxt="Legg til ny arbeidserfaring"
        experienceListComponent={WorkElems}
        onAlteredData={updateData}
        formSchema={formSchemaWork}
        manageExperienceFormElementsComponent={
          ManageWorkExperiencesFormElements
        }
        initialExperienceData={experienceData.work || []}
      />

      <CVEditExperienceType
        title="Utdanning"
        description="Liste over din formelle utdanning."
        experienceKind="education"
        addNewBtnTxt="Legg til ny utdanning"
        onAlteredData={updateData}
        experienceListComponent={EducationItems}
        formSchema={formSchemaEducation}
        manageExperienceFormElementsComponent={ManageEducationFormElements}
        initialExperienceData={experienceData.education || []}
      />

      <CVEditExperienceType
        title="Prosjekt"
        experienceKind="project"
        description="Relevante prosjekter du ønker å fremheve."
        addNewBtnTxt="Legg til nytt prosjekt"
        onAlteredData={updateData}
        experienceListComponent={ProjectItems}
        formSchema={formSchemaProject}
        manageExperienceFormElementsComponent={ManageProjectFormElements}
        initialExperienceData={experienceData.project || []}
      />

      <CVEditExperienceType
        title="Språk"
        description="Dine skriftlige og muntlige språkferdigheter."
        addNewBtnTxt="Legg til ny språkferdighet"
        experienceKind="language"
        onAlteredData={updateData}
        experienceListComponent={LanguageItems}
        formSchema={formSchemalanguage}
        manageExperienceFormElementsComponent={ManageLanguageFormElements}
        initialExperienceData={experienceData.language || []}
      />
    </>
  );
};

CVEdit.propTypes = {
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialDescription: PropTypes.string.isRequired,
  profileEditCV: PropTypes.func.isRequired,
  cvID: PropTypes.number.isRequired,
};

CVEdit.defaultProps = {};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      profileEditCV,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CVEdit);
