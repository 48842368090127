import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  getProfileId,
  getOrganisationId,
  isManager,
  getProfile,
} from '@selectors/profile.selectors';

import { withRouter, matchPath } from 'react-router';
import { getIsMobile } from '@selectors/global.selectors';
import { routes } from '@routes/routes.manifest';

import logo from '@src/assets/images/logo.png';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import { authLogoutRequest } from '@actions/auth.actions';
import './menu.scss';

const Menu = ({ isManager, orgId, personId, location, dispatch, ...props }) => {
  const menuLinks = [
    {
      name: 'Dashboard',
      path: routes.dashboard,
      icon: () => <i className="fa fa-home" />,
      active: matchPath(location.pathname, {
        path: routes.dashboard,
        exact: false,
      }),
    },
    {
      name: 'Min profil',
      path: routes.myProfile,
      icon: () => <i className="fa fa-user" />,
      active: matchPath(location.pathname, {
        path: '/my-profile',
        exact: false,
      }),
    },
    {
      name: 'Utviklingssamtale',
      path: routes.personalImprovement,
      icon: () => <i className="fa fa-comments" />,
      active: matchPath(location.pathname, {
        path: routes.personalImprovement,
        exact: false,
      }),
    },
    {
      name: 'Mine medarbeidere',
      path: routes.myEmployees,
      icon: () => <i className="fa fa-users" />,
      active: matchPath(location.pathname, {
        path: routes.myEmployees,
        exact: false,
      }),
    },
  ];

  return (
    <div className="menu" {...props}>
      <img className="menu__logo" src={logo} alt="Norges Sykepleierforbund" />
      <div className="menu__navigation">
        {menuLinks.map(
          ({ icon: Icon, path, name, active, exact = false }, index) => (
            <NavLink
              className={classNames('menu__link', 'menu__navigation-item', {
                'menu__link--active': active,
              })}
              activeClassName="menu__link--active"
              exact={exact}
              to={path}
              strict={false}
              key={index}>
              <span className="menu__link__icon" title={name}>
                <Icon />
              </span>
            </NavLink>
          )
        )}
      </div>
      <div className="menu__navigation-bottom">
        <button
          className="menu__link menu__link menu__link menu__link--logout"
          onClick={() => dispatch(authLogoutRequest())}
          title="Logg ut"
          type="button">
          <i className="menu__logout__icon fas fa-sign-out-alt" />
        </button>
      </div>
    </div>
  );
};

Menu.propTypes = {
  isManager: PropTypes.bool.isRequired,
  orgId: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  profile: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isManager: isManager(state),
  orgId: getOrganisationId(state),
  personId: getProfileId(state),
  isMobile: getIsMobile(state),
  profile: getProfile(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
