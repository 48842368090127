import styled from 'styled-components';
import React from 'react';

const PointSeperatorStyle = styled.span`
  &::after {
    content: '•';
  }
  margin-left: 6px;
  margin-right: 6px;
  font-size: 0.7em;
`;

const PointSeparator = () => <PointSeperatorStyle aria-hidden="true" />;

export default PointSeparator;
