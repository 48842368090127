/* eslint-disable no-redeclare */
import React from 'react';
import { getRoles, getMainOrganisations } from '@selectors/profile.selectors';
import { bindActionCreators } from 'redux';
import {
  profileChangeProfilePicture,
  profileEditPerson,
  profileEditPassword,
} from '@actions/profile.actions';
import { connect } from 'react-redux';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import { PersonShape } from '@types/person';
import Dropzone from 'react-dropzone';
import ProfileLogo from '@components/profile-logo/profile-logo';
import styled from 'styled-components';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import LabeledInput from '@components/common/labeled-input';
import Button from '@src/components/Button';

import { Page, Section } from '@src/components/Layout';
import PropTypes from 'prop-types';

const ProfileEdit = ({
  profile,
  // eslint-disable-next-line no-unused-vars
  roles,
  // eslint-disable-next-line no-unused-vars
  organisations,
  onCancel,
  profileEditPerson,
  profileChangeProfilePicture,
  onDone,
}) => {
  const handleProfileImageChange = ([file]) => {
    const { person_id } = profile.data;
    profileChangeProfilePicture({ person_id, file });
    // setAvatarPreveiw({ avatarPreview: file.preview });
  };

  const handlePersonFormSubmit = personFromForm => {
    const changedProfile = {
      ...profile.data,
      ...personFromForm,
      profile_image_dataURL: profile.data.profile_image.url,
      roles: profile.data.roles || [],
    };

    profileEditPerson({ person: { ...changedProfile } });
    onDone();
    // todo notification
    // setShouldNavigateToMyProfile(true);
  };

  const handleCancelEdit = () => {
    onCancel();
  };

  const ProfilePictureLabel = styled.label`
    display: block;
    text-align: center;
  `;

  const ProfilePictureChangeLabel = styled.p`
    font-size: 0.8em;
    color: #474747;
    :hover {
      cursor: pointer;
    }
    margin-top: 3px;
  `;

  const ProfileLogoWrapper = styled.div`
    :hover {
      cursor: pointer;
    }
  `;

  const ProfileEditWrapper = styled.div`
    display: flex;
  `;

  const ProfilePictureWrapper = styled.div`
    margin-right: 65px;
  `;

  const FormWrapper = styled.div`
    width: 100%;

    label {
      margin-bottom: 20px;
    }
  `;

  const ActionButtonsWrapper = styled.div`
    float: right;
    margin: 5px;
    margin-top: 25px;
    margin-bottom: 20px;
  `;

  const validateMobile = input => {
    if (!input || !input.trim()) {
      return false;
    }
    const number = input
      .replace(/^\s*(?:\+|00)(?:45|46|47|358|354)\s*/g, '')
      .trim();
    const digits = number.match(/\d/g);
    if (
      !digits ||
      !/^[\s\d]+$/g.test(number) ||
      digits.length < 5 ||
      digits.length > 12
    ) {
      return false;
    }

    return true;
  };

  const formSchema = Yup.object().shape({
    firstname: Yup.string().required('Fornavn er påkrevd'),
    lastname: Yup.string().required('Etternavn er påkrevd'),
    email: Yup.string()
      .email('Epost har feil format')
      .required('Epost er påkrevd'),
    mobile: Yup.mixed()
      .test('mobile', 'Telefonnummer har feil format', validateMobile)
      .required('Telefonnummer er påkrevd'),
  });

  const Header = styled.h2`
    margin-left: 10px;
    font-size: 1.8em;
    margin-bottom: 40px;
  `;

  const PersonInformationLoadingCont = styled.div`
    font-size: 1.5em;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    left: 12px;
  `;

  const StyledForm = styled(Form)`
    label {
      span:nth-child(2) {
        color: red;
      }
    }
  `;

  return (
    <Page title="Rediger profil">
      <Section>
        <div className="profile-page">
          <Header>Rediger profil</Header>
          <ProfileEditWrapper>
            {(profile.isFetching && (
              <PersonInformationLoadingCont>
                <i className="fa fa-spin fa-spinner" /> Laster...
              </PersonInformationLoadingCont>
            )) ||
              ((profile.error && 'Failed to load data...') ||
                (profile.data && (
                  <>
                    <ProfilePictureWrapper>
                      <Dropzone
                        onDrop={handleProfileImageChange}
                        accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                          <div className="form-input" {...getRootProps()}>
                            <ProfilePictureLabel
                              htmlFor="profile_picture"
                              className="form-input__label">
                              Profilbilde
                              <input
                                id="profile_picture"
                                {...getInputProps()}
                              />
                            </ProfilePictureLabel>
                            <ProfileLogoWrapper>
                              <ProfileLogo
                                person={profile.data}
                                sizeScale={1.5}
                              />
                            </ProfileLogoWrapper>
                            <ProfilePictureChangeLabel className="profile-page__add-profile-picture-label">
                              {profile.data.profile_image &&
                              profile.data.profile_image.url
                                ? 'Klikk for å endre bilde'
                                : 'Klikk for å laste opp bilde'}
                            </ProfilePictureChangeLabel>
                          </div>
                        )}
                      </Dropzone>
                    </ProfilePictureWrapper>
                    <FormWrapper>
                      <StyledForm
                        onSubmit={handlePersonFormSubmit}
                        initialData={{
                          firstname: profile.data.firstname,
                          lastname: profile.data.lastname,
                          email: profile.data.email,
                          mobile: profile.data.mobile,
                        }}
                        schema={formSchema}>
                        <LabeledInput name="firstname" label="Fornavn" />
                        <LabeledInput name="lastname" label="Etternavn" />
                        <LabeledInput name="email" label="E-post" />
                        <LabeledInput
                          name="mobile"
                          label="Telefonnummer"
                          type="tel"
                        />
                        <ActionButtonsWrapper>
                          <Button
                            secondary
                            onClick={handleCancelEdit}
                            marginRight="20px">
                            Avbryt
                          </Button>
                          <Button action type="submit">
                            Lagre
                          </Button>
                        </ActionButtonsWrapper>
                      </StyledForm>
                    </FormWrapper>
                  </>
                )))}
          </ProfileEditWrapper>
        </div>
      </Section>
    </Page>
  );
};

const mapStateToProps = state => ({
  roles: getRoles(state),
  organisations: getMainOrganisations(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      profileChangeProfilePicture,
      profileEditPassword,
      profileEditPerson,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEdit);

ProfileEdit.propTypes = {
  profile: fetchedDataWrapperShape(PersonShape()).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

ProfileEdit.defaultProps = {};
