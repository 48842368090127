import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ProfileCard from '@components/profile-card/profile-card';

const PersonInformation = ({ person, color, profileCardStyle }) => {
  const personaliaPropsAndIcons = [
    { prop: 'Testveien 24', icon: 'fas fa-home' },
    { prop: person.mobile, icon: 'fas fa-phone' },
    { prop: person.email, icon: 'fas fa-envelope' },
  ];

  const personaliaIcons = personaliaPropsAndIcons
    .filter(p => p.prop)
    .map(p => {
      return (
        <PersonaliaEntity key={p.prop}>
          <PersonaliaIconCont>
            <PersonaliaIcon className={p.icon} />
          </PersonaliaIconCont>
          <PersonaliaTxt>{p.prop}</PersonaliaTxt>
        </PersonaliaEntity>
      );
    });

  const StyledProfileCard = styled(ProfileCard)`
    margin-bottom: 20px;
  `;

  return (
    <>
      <StyledProfileCard
        person={person}
        color={color}
        style={profileCardStyle}
      />
      <PersonaliaCont>{personaliaIcons}</PersonaliaCont>
    </>
  );
};

export const PersonaliaEntity = styled.div`
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
`;

const PersonaliaCont = styled.div``;

const PersonaliaIconCont = styled.div`
  padding: 8px;
  background-color: #ecf4fd;
  width: 30px;
  height: 30px;
  border-radius: 12px;
  display: table;
  float: left;
`;

const PersonaliaIcon = styled.i`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3em;
  padding: 4px;
  color: #4493f4;
`;

export const PersonaliaTxt = styled.p`
  float: left;
  margin-left: 15px;
  word-break: break-all;
  margin-right: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default PersonInformation;

PersonInformation.propTypes = {
  person: PropTypes.shape({
    fullname: PropTypes.string,
    user_name: PropTypes.string,
    image_url: PropTypes.string,
    address: PropTypes.string,
    birthdate: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string, // can contain whitespace
  }),
  color: PropTypes.string, // hexadecimal format
  profileCardStyle: PropTypes.shape({}),
};
