import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { authUnauthorized } from '@actions/auth.actions';
import retry from '@utils/sagas.utils';
import {
  profileFetchPersoncompetencesRequest,
  profileFetchPersoncompetencesSuccess,
  profileFetchPersoncompetencesFailure,
  profileEditPersoncompetenceRequest,
  profileEditPersoncompetenceFailure,
  PROFILE_FETCH_PERSONCOMPETENCES,
  PROFILE_EDIT_PERSONCOMPETENCE,
  FETCH_COMPETENCES,
  fetchCompetencesRequest,
  fetchCompetencesSuccess,
  PROFILE_ADD_PERSONCOMPETENCE,
  PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES,
  PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES,
  profileAddPersoncompetenceSuccess,
  profileAddMultiplePersoncompetencesSuccess,
  profileEditPersoncompetenceSuccess,
  profileEditMultiplePersoncompetencesSuccess,
  profileAddMultiplePersoncompetencesRequest,
  profileEditMultiplePersoncompetencesRequest,
  profileEditMultiplePersoncompetencesFailure,
  profileAddMultiplePersoncompetencesFailure,
} from '@actions/competences.actions';
import { backendUrl } from '@config';
import { fetchCompetencesFailure } from '@actions/profile.actions';

function* profileFetchPersoncompetences() {
  yield put(profileFetchPersoncompetencesRequest());
  try {
    const personcompetences = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/personcompetences?fields=first_active_group,competence(title),passed`,
        withCredentials: true,
      })
    );

    yield put(
      profileFetchPersoncompetencesSuccess({
        data: personcompetences.data.personcompetences,
      })
    );
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(profileFetchPersoncompetencesFailure({ error }));
    }
  }
}

function* profileAddPersoncompetence(
  action,
  fetchNewDataAfterSuccess = true,
  isOnlyPartOfOperation = true
) {
  yield put(profileEditPersoncompetenceRequest());
  try {
    const personcompetences = yield retry(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/api/personcompetences?passed=${
          action.payload.personcompetence.passed
        }&competence_id=${action.payload.personcompetence.competence_id}`,
        withCredentials: true,
      })
    );

    if (isOnlyPartOfOperation) {
      yield put(profileAddPersoncompetenceSuccess());
    }

    if (fetchNewDataAfterSuccess) {
      yield put(
        profileEditPersoncompetenceRequest({
          data: personcompetences.data.personcompetences,
        })
      );
    }
    return { result: true };
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(profileEditPersoncompetenceFailure({ error }));
    }
    return { result: false, error };
  }
}

function* fetchCompetences() {
  yield put(fetchCompetencesRequest());
  try {
    const competences = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/competences?fields=competence_groups,title,description`,
        withCredentials: true,
      })
    );

    yield put(
      fetchCompetencesSuccess({
        data: competences.data.competences,
      })
    );

    return { result: true };
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(fetchCompetencesFailure({ error }));
    }

    return { result: false, error };
  }
}
function* profileEditPersoncompetence(
  action,
  fetchNewDataAfterSuccess = true,
  isOnlyPartOfOperation = true
) {
  yield put(profileEditPersoncompetenceRequest());
  try {
    const personcompetences = yield retry(() =>
      axios.request({
        method: 'PUT',
        url: `${backendUrl}/api/personcompetences/${
          action.payload.personcompetence.person_competence_id
        }?passed=${action.payload.personcompetence.passed}`,
        withCredentials: true,
      })
    );

    if (isOnlyPartOfOperation) {
      yield put(profileEditPersoncompetenceSuccess());
    }

    if (fetchNewDataAfterSuccess) {
      yield put(
        profileEditPersoncompetenceRequest({
          data: personcompetences.data.personcompetences,
        })
      );
    }
    return { result: true };
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(profileEditPersoncompetenceFailure({ error }));
    }
    return { result: false, error };
  }
}

function* profileManageMultiplePersoncompetences(action, kind) {
  yield put(
    kind === 'add'
      ? profileAddMultiplePersoncompetencesRequest()
      : profileEditMultiplePersoncompetencesRequest()
  );
  for (let i = 0; i < action.payload.personcompetences.length; i += 1) {
    const payload = {
      personcompetence: action.payload.personcompetences[i].personcompetence,
    };
    const sagaToCall =
      kind === 'add' ? profileAddPersoncompetence : profileEditPersoncompetence;
    const res = yield call(
      sagaToCall,
      {
        ...action,
        payload,
      },
      false,
      false
    );

    if (!res.result) {
      yield put(
        kind === 'add'
          ? profileAddMultiplePersoncompetencesFailure({
              error: res ? res.error : 'Ukjent feil',
            })
          : profileEditMultiplePersoncompetencesFailure({
              error: res ? res.error : 'Ukjent feil',
            })
      );
      return;
    }
  }
  yield put(
    kind === 'add'
      ? profileAddMultiplePersoncompetencesSuccess()
      : profileEditMultiplePersoncompetencesSuccess()
  );

  yield call(profileFetchPersoncompetences);
}

function* profileEditMultiplePersoncompetences(action) {
  yield call(profileManageMultiplePersoncompetences, action, 'edit');
}
function* profileAddMultiplePersoncompetences(action) {
  yield call(profileManageMultiplePersoncompetences, action, 'add');
}

export default [
  takeLatest(PROFILE_FETCH_PERSONCOMPETENCES, profileFetchPersoncompetences),
  takeLatest(FETCH_COMPETENCES, fetchCompetences),
  takeLatest(PROFILE_EDIT_PERSONCOMPETENCE, profileEditPersoncompetence),
  takeLatest(PROFILE_ADD_PERSONCOMPETENCE, profileAddPersoncompetence),
  takeLatest(
    PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES,
    profileEditMultiplePersoncompetences
  ),
  takeLatest(
    PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES,
    profileAddMultiplePersoncompetences
  ),
];
