import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Label } from '@src/components/Layout';
import { useOnVisibilityChange } from '@src/hooks/hooks';
import { propsUtils } from '@src/utils';

const TimelineWrapper = styled.div`
  display: block;
  position: relative;
  margin: 0 20px;
  margin-top: 110px;
  margin-bottom: 60px;
`;

const NodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: space-between;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

const Node = ({ active, completed, text, onClick, clickable }) => {
  const label = useRef();

  const Dot = styled.div`
    position: absolute;
    top: 9px;
    left: 9px;
    bottom: 9px;
    right: 9px;
    border-radius: 50%;
    position: absolute;
    background: ${active ? '#1573e6' : '#ffffff'};
    ${clickable && 'cursor: pointer;'}
  `;

  const FloatingLabel = styled(Label)`
    overflow: visible;
    position: absolute;
    top: -2.1em;
    text-align: center;
    line-height: 0;
    white-space: nowrap;
    transform: translateX(-50%);
    left: 14px;
    font-size: 14px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    ${clickable &&
      `
      cursor: pointer;

      :hover {
        font-weight: bold;
      }
    `}

    @media (max-width: 580px) {
      top: 14px;
      left: 42px;
      transform: none;
    }
  `;

  const onVisibilityChange = () => {
    const { style } = label.current;
    style.left = null;
    const rect = label.current.getBoundingClientRect();
    let diff = rect.right - document.body.innerWidth + 14;
    if (diff >= 0) {
      style.left = `${-diff + 14}px`;
    }
    diff = rect.left - 124 - 14;
    if (diff <= 0) {
      style.left = `${-diff + 14}px`;
    }
  };

  useOnVisibilityChange(onVisibilityChange);

  return (
    <div
      {...propsUtils.onClick(onClick)}
      css={`
        position: relative;
        background: ${(active && ((completed && '#2a5b2a') || '#0c4386')) ||
          ((completed && '#55B554') || '#1573E6')};
        border-radius: 50%;
        flex: 0 0 auto;
        height: 28px;
        width: 28px;
        ${clickable && 'cursor: pointer;'}

        @media (max-width: 580px) {
          margin: 4px 0;
        }
      `}>
      {(!completed && <Dot active={active} />) || (
        <i
          css="
            color: #fff;
            position: absolute;
            top: 6px;
            left: 6px;
          "
          className="fa fa-check"
        />
      )}
      <FloatingLabel
        {...propsUtils.onClick(onClick)}
        active={active}
        ref={label}>
        {text}
      </FloatingLabel>
    </div>
  );
};

Node.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  clickable: PropTypes.bool,
};

Node.defaultProps = {
  active: false,
  clickable: false,
};

const Line = styled.div`
  position: absolute;
  background: #1573e6;
  height: 10px;
  left: 14px;
  margin-top: -5px;
  position: absolute;
  right: 14px;
  top: 50%;

  @media (max-width: 580px) {
    top: 16px;
    bottom: 16px;
    left: 14px;
    margin-top: 0;
    margin-left: -6px;
    right: auto;
    height: auto;
    width: 12px;
  }
`;

const LineProgress = styled.div`
  position: absolute;
  background: #67bd66;
  left: 0;
  top: 0;
  width: ${({ progress }) => (progress ? progress * 100 : '0')}%;
  height: 100%;
  transition: width 0.8s ease-out;

  @media (max-width: 580px) {
    width: 100%;
    height: ${({ progress }) => (progress ? progress * 100 : '0')}%;
    transition: height 0.8s ease-out;
  }
`;

const ProgressTimeline = ({ steps, current, onStepClick }) => {
  const [completion, setCompletion] = useState(0);

  useEffect(() => {
    if (current > completion) {
      setCompletion(current);
    }
  }, [current]);

  return (
    <TimelineWrapper>
      <Line>
        <LineProgress
          progress={Math.min(
            Math.max(
              steps.length !== 1
                ? completion / (steps.length - 1)
                : completion + 1,
              0
            ),
            1
          )}
        />
      </Line>
      <NodeWrapper>
        {steps.map((text, index) => (
          <Node
            onClick={() => {
              if (index < completion + 2) {
                onStepClick(index);
              }
            }}
            key={index}
            text={text}
            completed={index < completion}
            active={index === current}
            clickable={index < completion + 2}
          />
        ))}
      </NodeWrapper>
    </TimelineWrapper>
  );
};

ProgressTimeline.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  current: PropTypes.number.isRequired,
  onStepClick: PropTypes.func.isRequired,
};

export default ProgressTimeline;
