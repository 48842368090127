import React, { useState, useEffect, useContext } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import EmployeeTalkInfo from '@routes/personal-improvement/EmployeeTalkInfo';
import { Section, Label, Cell } from '@src/components/Layout';
import { Context } from '@src/Context';

const CircleWithNumber = ({ number, totalNumber }) => {
  const Circle = styled.div`
    border-radius: 18px;
    background-color: #ecf4fd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 56px;
    float: left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  `;

  const NumberTxt = styled.p`
    display: inline-block;
    margin: 0;
    color: #1673e6;
    font-weight: bold;
  `;

  return (
    <Circle>
      {(totalNumber && <NumberTxt>{`${number}/${totalNumber}`}</NumberTxt>) || (
        <NumberTxt>{number}</NumberTxt>
      )}
    </Circle>
  );
};

const Circular = ({ green, number, total }) => {
  const percentage = (number / total) * 100;
  const Wrapper = styled.div`
    width: 90px;
    min-width: 90px;
  `;

  const Fancy = styled(CircularProgressbar)`
    font-weight: bold;
  `;

  return (
    <Wrapper>
      <Fancy
        value={percentage}
        text={`${number} / ${total}`}
        styles={{
          trail: { stroke: '#edeeef' },
          path: {
            stroke: green ? '#28e114' : '#3177ef',
            strokeWidth: '5',
          },
          text: {
            fill: '#3177ef',
          },
        }}
      />
    </Wrapper>
  );
};

const PersonalImprovementDashboard = ({
  // eslint-disable-next-line no-unused-vars
  completedTalks,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  plannedTalks,
  onEmployeeTalkSelect,
}) => {
  const {
    employees: { list: employees },
  } = useContext(Context);

  const WidgetCont = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 94px;
    vertical-align: middle;
    padding-top: 40px;
  `;

  const Wrapper = styled.div`
    position: relative;
    background-color: #f7f9fc;
    padding-bottom: 10px;
    min-height: 542px;
    @media screen and (max-width: 1260px) {
      ${WidgetCont} {
        margin-right: 5%;
      }
      padding-bottom: 15px;
    }
  `;

  const Header = styled.h2`
    font-size: 2em;
    margin-bottom: 0;
    margin-top: 65px;
  `;

  const Description = styled.p`
    margin-top: 5px;
    color: #515254;
    margin-bottom: 35px;
    font-size: 1.15em;
  `;

  const WidgetTxtCont = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 80px;
  `;

  const WidgetTxtHeader = styled.h3`
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    font-size: 1.25rem;
  `;

  const WidgetTxtDescription = styled.p`
    font-size: 1em;
    color: #767676;
    margin: 0;
    margin-bottom: 5px;
    margin-top: 4px;
    width: 100%;
  `;

  const WidgetsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    white-space: nowrap;
    flex-wrap: wrap;
  `;

  const HeaderNextTalk = styled.h3`
    color: #191919;
    margin-top: 75px;
    font-size: 1.3em;
    margin-bottom: 10px;
  `;

  const getNextTalkEmployee = employees => {
    const timeNow = new Date();
    let smallestTime = Number.MAX_VALUE;
    let currentSmallest;

    employees.forEach(employee => {
      if (employee.nextTalkDate) {
        const entryTime = new Date(employee.nextTalkDate);
        const timeBetweenEntryAndNow = entryTime - timeNow;

        const talkIsInFuture = entryTime > timeNow;
        if (talkIsInFuture) {
          const timeBetweenSmallestAndNow = smallestTime - timeNow;
          if (timeBetweenEntryAndNow < timeBetweenSmallestAndNow) {
            currentSmallest = employee;
            smallestTime = entryTime;
          }
        }
      }
    });
    return currentSmallest;
  };

  const [nextTalkEmployee, setNextTalkEmployee] = useState(null);

  useEffect(() => {
    if (employees) {
      setNextTalkEmployee(getNextTalkEmployee(employees));
    }
  }, [employees]);

  const LoadingCont = styled.div`
    font-size: 1.5em;
    position: absolute;
    top: 70%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    left: 50%;
  `;

  const NextTalk = () => (
    <Cell>
      <HeaderNextTalk>Neste samtale</HeaderNextTalk>
      {(employees &&
        ((nextTalkEmployee && (
          <EmployeeTalkInfo
            onStartTalk={employee => onEmployeeTalkSelect(employee)}
            employee={nextTalkEmployee}
          />
        )) ||
          (!isLoading && (
            <Label marginBottom="6px">Ingen planlagte samtaler</Label>
          )))) || <LoadingCont>Laster...</LoadingCont>}
    </Cell>
  );

  return (
    <Wrapper>
      {isLoading && <LoadingCont>Laster...</LoadingCont>}
      <Section>
        <Header>Utviklingssamtale</Header>
        <Description>
          Her finner du informasjon om dine utviklingssamtaler.
        </Description>

        {!isLoading && (
          <>
            <WidgetsWrapper>
              <WidgetCont>
                <Circular number={completedTalks} total={employees.length} />
                <WidgetTxtCont>
                  <WidgetTxtHeader>Gjennomførte samtaler</WidgetTxtHeader>
                  <WidgetTxtDescription>
                    Antall samtaler du har gjennomført
                  </WidgetTxtDescription>
                </WidgetTxtCont>
              </WidgetCont>
              <WidgetCont>
                <Circular number={plannedTalks} total={employees.length} />
                <WidgetTxtCont>
                  <WidgetTxtHeader>Planlagte samtaler</WidgetTxtHeader>
                  <WidgetTxtDescription>
                    Antall samtaler du har planlagt
                  </WidgetTxtDescription>
                </WidgetTxtCont>
              </WidgetCont>
            </WidgetsWrapper>

            <NextTalk />
          </>
        )}
      </Section>
    </Wrapper>
  );
};

export default PersonalImprovementDashboard;

PersonalImprovementDashboard.propTypes = {
  completedTalks: PropTypes.number.isRequired,
  plannedTalks: PropTypes.number.isRequired,
  onEmployeeTalkSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

PersonalImprovementDashboard.defaultProps = {};

CircleWithNumber.propTypes = {
  number: PropTypes.number.isRequired,
  totalNumber: PropTypes.number,
};

CircleWithNumber.defaultProps = {
  totalNumber: null,
};

Circular.propTypes = {
  green: PropTypes.bool,
  number: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

Circular.defaultProps = {
  green: false,
};
