/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Page,
  Section,
  Row,
  Cell,
  Seperator,
  Column,
  Label,
} from '@src/components/Layout';
import PersonalImprovementDashboard from '@routes/personal-improvement/PersonalImprovementDashboard';

const MyEmployees = () => {
  return (
    <Page title="Mine medarbeidere">
      <Section>
        <h1>Mine medarbeidere</h1>
        <Label italic>Ikke implementert</Label>
        <Seperator />
      </Section>
    </Page>
  );
};

export default MyEmployees;

MyEmployees.propTypes = {};
