import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  Children,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { propsUtils } from '@src/utils/index';
import { colors } from '@src/styles/index';
import { Cell } from './Layout';

const TableWrapper = styled(Cell)``;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  td,
  th {
    padding: 18px 15px;
    border: 0;
    border-bottom: 1px solid ${colors.cellBorder};
  }

  td {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: 15px;
      bottom: 15px;
      width: 1px;
      background: ${colors.cellBorder};
    }

    &:last-child {
      border-right: 0;

      &::after {
        content: none;
        display: none;
      }
    }
  }

  ${({ highlightOddRows }) =>
    highlightOddRows &&
    `
    tr:nth-child(odd) {
      td {
        background-color: ${colors.oddBackground};
      }
    }
  `}
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;

  transition: box-shadow 0.115s ease-out;
`;
const TableHeaderCell = styled.th`
  text-align: left;
  position: relative;
  background: #fff;
`;

const SortIcon = styled.i`
  position: absolute;
  right: 18px;
  line-height: 0;
  top: 50%;
  color: #000;
`;

const SortIconDisabled = styled(SortIcon)`
  color: #dcdcdc;
`;

const sort = Object.freeze([undefined, 'desc', 'asc']);

const Table = ({
  sortable,
  sortMulticolumn,
  headings,
  children,
  highlightOddRows,
  scrollShadow,
  ...props
}) => {
  const [rows, setRows] = useState([]);
  const [columnSort, setColumnSort] = useState([]);

  const refTableWrapper = useRef();
  const refTableHeader = useRef();

  const onTableScroll = useCallback(
    ({ target }) => {
      if (!target || !refTableHeader.current) {
        return;
      }
      const { style } = refTableHeader.current;
      if (target.scrollTop) {
        style.boxShadow = scrollShadow || '0 0 6px 0 rgba(0, 0, 0, 0.3)';
      } else {
        style.boxShadow = null;
      }
    },
    [refTableWrapper.current, refTableHeader.current]
  );

  useEffect(() => {
    if (refTableWrapper.current) {
      refTableWrapper.current.addEventListener('scroll', onTableScroll);

      return () => {
        refTableWrapper.current.removeEventListener('scroll', onTableScroll);
      };
    }
    return undefined;
  }, [onTableScroll]);

  const toggleSort = key => {
    let newSort;
    if (sortMulticolumn) {
      newSort = Array.from(columnSort);
    } else {
      newSort = [];
      newSort[key] = columnSort[key];
    }
    newSort[key] = sort[(sort.indexOf(newSort[key]) + 1) % sort.length];
    setColumnSort(newSort);
  };

  useEffect(() => {
    const sortData = (node, i) => {
      if (!node.props.children) {
        return null;
      }
      return node.props.children[i].props['data-sort'] !== undefined
        ? node.props.children[i].props['data-sort']
        : node.props.children[i].props.children;
    };

    const sortRows = (a, b) => {
      for (let i = 0; i < headings.length; i += 1) {
        if (columnSort[i] && sortData(a, i) !== sortData(b, i)) {
          if (sortData(a, i) < sortData(b, i)) {
            return columnSort[i] === 'asc' ? 1 : -1;
          }
          return columnSort[i] === 'desc' ? 1 : -1;
        }
      }
      return 0;
    };
    setRows(Children.toArray(children).sort(sortRows));
  }, [columnSort, children]);

  const isSortable = key =>
    Array.isArray(sortable) ? sortable[key] : sortable;

  const cssHoverMixin = `
      cursor: pointer;
      transition: background 0.115s ease-out;
      
      &:hover {
        background: #ececec;
      }
    `;

  return (
    <TableWrapper ref={refTableWrapper} {...props}>
      <StyledTable highlightOddRows={highlightOddRows}>
        {headings && (
          <TableHeader ref={refTableHeader}>
            <tr>
              {headings.map((heading, key) => (
                <TableHeaderCell
                  key={key}
                  css={`
                    ${isSortable(key) && cssHoverMixin}
                  `}
                  {...(isSortable(key) &&
                    propsUtils.onClick(() => toggleSort(key))) ||
                    {}}>
                  {heading}
                  {(columnSort[key] === 'asc' && (
                    <SortIcon className="fa fa-sort-up" />
                  )) ||
                    (columnSort[key] === 'desc' && (
                      <SortIcon className="fa fa-sort-down" />
                    )) ||
                    (isSortable(key) && (
                      <SortIconDisabled className="fa fa-sort" />
                    ))}
                </TableHeaderCell>
              ))}
            </tr>
          </TableHeader>
        )}
        <tbody>{rows}</tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default Table;

Table.propTypes = {
  sortable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
  sortMulticolumn: PropTypes.bool,
  headings: PropTypes.node,
  children: PropTypes.node.isRequired,
  highlightOddRows: PropTypes.bool,
  scrollShadow: PropTypes.string,
};

Table.defaultProps = {
  sortable: false, // FIXME: sorting doesn't work sometimes (low priority)
  sortMulticolumn: false,
  headings: null,
  highlightOddRows: false,
  scrollShadow: null,
};
