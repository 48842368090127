/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Block,
  Label,
  Cell,
  Column,
  Row,
  Seperator,
  CircleIcon,
} from '@src/components/Layout';
import { propsUtils } from '@src/utils';
import Button from '@src/components/Button';
import { breakpoints } from '../../styles';
import { useMediaQuery } from '../../hooks/hooks';

const FileObject = styled.object`
  width: 100%;
  height: 100%;
  min-width: 320px;
`;

const mediaSmall = '(max-width: 935px)';

const StyledDocument = styled(Column)``;

const Document = ({
  onClick,
  hasDocument,
  onShowDocumentClick,
  showHoverEffect,
  expanded,
  fullscreenButton,
  showDocumentFulllscreen,
  smallMedia,
  amountExpanded,
  document: { title, file, text },
  ...props
}) => {
  const [isCurrFullscreen, setIsCurrFullscreen] = useState(
    showDocumentFulllscreen
  );

  const cssHoverMixin = `
    cursor: pointer;
    transition: background 0.115s ease-out;
    width 0.4s ease-out;
    
    &:hover {
      background: #ececec;
    }
  `;

  const cssFullscreenMixin = `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 124px;
    right: 0;
    z-index: 9999;
  `;

  const CloseIcon = styled.i`
    color: #000;
    position: absolute;
    right: 30px;
    top: 50%;
    line-height: 0;
    :hover {
      cursor: pointer;
    }
  `;

  const FullscreenButton = styled.button`
    margin-left: auto; /*Place rightmost*/
    margin-right: 5px;
    :hover {
      cursor: pointer;
    }
  `;

  return (
    <StyledDocument
      border
      grow={expanded}
      css={`
        background: #fff;
        flex-basis: ${expanded && amountExpanded
          ? `${100 / amountExpanded}%`
          : 'auto'};
        margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }

        ${!expanded && cssHoverMixin}
        ${file &&
          expanded &&
          (smallMedia || isCurrFullscreen) &&
          cssFullscreenMixin}
      `}
      {...!expanded && propsUtils.onClick(onClick)}
      {...props}>
      <Row
        padding="14px"
        css={expanded && showHoverEffect && cssHoverMixin}
        {...expanded && propsUtils.onClick(onClick)}>
        <Label bold noWrap lineHeight="2rem">
          {file ? (
            <CircleIcon
              background="#ecf4fd"
              color="#297fe8"
              marginRight={(expanded && '1em') || '0'}
              className="fa fa-file-alt"
            />
          ) : (
            <CircleIcon
              background="#ecf4fd"
              color="#297fe8"
              marginRight={(expanded && '1em') || '0'}
              className="fa fa-tasks"
            />
          )}
          {(expanded || smallMedia) && title}
          {file && expanded && smallMedia && (
            <CloseIcon className="fa fa-times" />
          )}
        </Label>
        {fullscreenButton && !smallMedia && expanded && file && (
          <FullscreenButton
            onClick={e => {
              e.stopPropagation();
              setIsCurrFullscreen(!isCurrFullscreen);
            }}>
            <i
              className={isCurrFullscreen ? 'fa fa-compress' : 'fa fa-expand'}
            />
          </FullscreenButton>
        )}
      </Row>
      <Seperator />
      {expanded && (
        <>
          {(file && (
            <Cell grow>
              <FileObject data={file} type="application/pdf" aria-label={title}>
                <Row margin="auto" marginTop="38px" marginBottom="0px">
                  <Label
                    css="
                      margin: auto !important;
                    ">
                    Last ned dokumentet
                  </Label>
                </Row>
                <Row margin="auto" marginTop="10px" marginBottom="0px">
                  <Button
                    css="
                      margin: auto !important;
                    "
                    small
                    action
                    href={file}>
                    Last ned
                  </Button>
                </Row>
              </FileObject>
            </Cell>
          )) || (
            <Cell padding="36px">
              <Label>{text}</Label>
              {hasDocument && (
                <Cell
                  marginTop="24px"
                  marginRight="auto"
                  {...propsUtils.onClick(onShowDocumentClick)}>
                  <Button small action>
                    Les hele dokumentet
                  </Button>
                </Cell>
              )}
            </Cell>
          )}
        </>
      )}
    </StyledDocument>
  );
};

Document.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasDocument: PropTypes.bool.isRequired,
  onShowDocumentClick: PropTypes.func.isRequired,
  showHoverEffect: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  smallMedia: PropTypes.bool.isRequired,
  fullscreenButton: PropTypes.bool,
  amountExpanded: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  document: PropTypes.shape({
    text: PropTypes.string,
    file: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

Document.defaultProps = {
  fullscreenButton: false,
};

const StyledDocumentView = styled(Row)`
  @media screen and ${mediaSmall} {
    flex-direction: column;

    ${StyledDocument} {
      margin-bottom: 18px;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const DocumentView = ({ documents, fullscreenButton, ...props }) => {
  const [expanded, setExpanded] = useState([]);

  const smallMedia = useMediaQuery(mediaSmall).matches;

  const amountExpanded = expanded.reduce(
    (amt, expanded) => (expanded && amt + 1) || amt,
    0
  );

  const toggleExpanded = index => {
    if (smallMedia) {
      const oneExpanded = [];
      if (expanded[index]) {
        if (index === 0 && documents.length > 1) {
          oneExpanded[1] = true;
        } else if (!documents[0].file) {
          oneExpanded[0] = true;
        }
      } else {
        oneExpanded[index] = true;
      }
      setExpanded(oneExpanded);
      return;
    }
    expanded[index] = !expanded[index];
    if (amountExpanded <= 1 && !expanded[index]) {
      if (index === 0 && documents.length > 1) {
        expanded[1] = true;
      } else {
        expanded[0] = true;
      }
    }
    setExpanded(expanded.slice());
  };

  useEffect(() => {
    if (!smallMedia || !documents[0].file) {
      setExpanded([true]);
    } else {
      setExpanded([]);
    }
  }, [documents, smallMedia]);

  return (
    <StyledDocumentView {...props}>
      {documents &&
        documents.map((document, key) => (
          <Document
            key={key}
            onClick={() => toggleExpanded(key)}
            hasDocument={key < documents.length - 1 && !expanded[key + 1]}
            onShowDocumentClick={() => toggleExpanded(key + 1)}
            showHoverEffect={documents.length > 1}
            expanded={expanded[key] || false}
            smallMedia={smallMedia}
            fullscreenButton={fullscreenButton}
            amountExpanded={amountExpanded}
            document={document}
          />
        ))}
    </StyledDocumentView>
  );
};

export default DocumentView;

DocumentView.propTypes = {
  documents: PropTypes.arrayOf(Document.propTypes.document).isRequired,
  fullscreenButton: PropTypes.bool,
  showDocumentFullscreen: PropTypes.bool,
};

DocumentView.defaultProps = {
  fullscreenButton: false,
  showDocumentFullscreen: false,
};
