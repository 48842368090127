import React from 'react';
import PropTypes from 'prop-types';
import InfoCollection from '@routes/my-profile/InfoCollection';
import Button from '@src/components/Button';
import { CVShape } from '@types/cv';
import styled from 'styled-components';
import { Section } from '@src/components/Layout';
import CVView from '@routes/my-profile/CVView';
import CVEdit from '@routes/my-profile/CVEdit';
import { viewModeView, viewModeEdit } from './MyProfile';

const CVManage = ({ CVData, onModeChange, manageMode }) => {
  const modeStrings = {};
  modeStrings[viewModeView] = { actionBtnTxt: 'Rediger CV', header: 'CV' };
  modeStrings[viewModeEdit] = { actionBtnTxt: 'Tilbake', header: 'Rediger CV' };
  const styelCVContainer = {
    marginTop: '50px',
  };

  const onChangeManageMode = () => {
    onModeChange();
  };


  // UIDs are unique identifiers for each experience used to indentify which experience to manage. It is necesarry bacause the experiences may
  // not yet have assigned ids.
  // experience types listed in typesToOnlyGetYearFromDate will get convrerted to years
  const divideByTypeAssignUIDFixDate = (CVData, typesToOnlyGetYearFromDate) => {
    const dividedByType = {};
    // eslint-disable-next-line prefer-destructuring
    const experiences = CVData.experiences;
    if (!experiences) {
      return dividedByType;
    }
    let UIDcounter = 0;
    for (let i = 0; i < experiences.length; i += 1) {
      const currKey = experiences[i].experience_type;
      if (!dividedByType[currKey]) {
        dividedByType[currKey] = [];
      }

      const currExperience = experiences[i];
      if (currExperience.startdate) {
        if (
          typesToOnlyGetYearFromDate.indexOf(currExperience.experience_type) >=
          0
        ) {
          if (!/^[0-9]{4}$/.test(currExperience.startdate)) {
            const date = new Date(currExperience.startdate);
            currExperience.startdate = date.getFullYear();
          }
        } else {
          currExperience.startdate = new Date(currExperience.startdate);
        }
      }

      if (currExperience.enddate) {
        if (
          typesToOnlyGetYearFromDate.indexOf(currExperience.experience_type) >=
          0
        ) {
          if (!/^[0-9]{4}$/.test(currExperience.enddate)) {
            const date = new Date(currExperience.enddate);
            currExperience.enddate = date.getFullYear();
            currExperience.enddate = currExperience.enddate;
          }
        } else {
          currExperience.enddate = new Date(currExperience.enddate);
        }
      }

      currExperience.UID = UIDcounter;
      UIDcounter += 1;
      dividedByType[currKey].push(experiences[i]);
    }

    return dividedByType;
  };

  const PersonInformationLoadingCont = styled.div`
    font-size: 1.5em;
    position: relative;
    top: 34%;
    transform: translate(0, -50%);
    text-align: center;
  `;

  const NoDataSection = styled(Section)`
    height: 500px;
  `;

  const ErrorIcon = styled.i`
    font-size: 1.5em;
    font-size: 2em;
    margin-right: 5px;
    position: relative;
    top: 9px;
  `;

  if (CVData.error) {
    return (
      <NoDataSection>
        <InfoCollection
          title={modeStrings[manageMode].header}
          style={styelCVContainer}
        />
        <PersonInformationLoadingCont>
          <ErrorIcon className="fas fa-exclamation-circle" /> Det oppsto en feil
          ved henting av dataen
        </PersonInformationLoadingCont>
      </NoDataSection>
    );
  }

  if (CVData.isFetching || !CVData.data) {
    return (
      <NoDataSection>
        <InfoCollection
          title={modeStrings[manageMode].header}
          style={styelCVContainer}
        />
        <PersonInformationLoadingCont>
          <i className="fa fa-spin fa-spinner" /> Laster...
        </PersonInformationLoadingCont>
      </NoDataSection>
    );
  }

  const dividedByExperienceTypeWithUIDs = divideByTypeAssignUIDFixDate(
    CVData.data,
    ['education']
  );

  return (
    <Section>
      <InfoCollection
        title={modeStrings[manageMode].header}
        style={styelCVContainer}
        actionButton={
          <Button small alternative onClick={onChangeManageMode}>
            {modeStrings[manageMode].actionBtnTxt}
          </Button>
        }>
        {manageMode === viewModeView ? (
          <CVView
            experiencesDividedByType={dividedByExperienceTypeWithUIDs}
            description={CVData.data.description}
          />
        ) : (
          <CVEdit
            initialExperienceData={dividedByExperienceTypeWithUIDs}
            initialDescription={CVData.data.description}
            cvID={CVData.data.id}
          />
        )}
      </InfoCollection>
    </Section>
  );
};

export default CVManage;

CVManage.propTypes = {
  CVData: PropTypes.shape(CVShape.isRequired).isRequired,
  onModeChange: PropTypes.func.isRequired,
  manageMode: PropTypes.string.isRequired,
};
