import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Icon, Column, Cell, Label } from '@src/components/Layout';
import { Input } from '@src/components/Input';
import { colors } from '@src/styles/index';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { useField } from '@rocketseat/unform';
import { propsUtils } from '@src/utils';
import scrollIntoView from 'scroll-into-view-if-needed';

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0 0.5em;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  right: 0.2em;
  color: ${colors.fieldIcon};
  cursor: pointer;
  font-size: 0.8em;
`;

const DropdownMenu = styled(Column)`
  margin-top: 18px;
  padding: 8px;
  background: #fff;
  border: 1px solid ${colors.cellBorder};
  width: 340px;
  max-width: 100%;
  min-height: 108px;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;

  ${({ displayOnTop }) =>
    displayOnTop
      ? css`
          position: relative;
          bottom: 182px;
        `
      : ''}
`;

const DropdownArrow = styled.div`
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  top: 0;
  left: 40px;
  margin-top: -8px;
  border-top: none;
  border-bottom-color: ${colors.cellBorder};

  ::after {
    content: '';
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    margin-top: 1px;
    left: -7px;
    border-top: none;
    border-bottom-color: #fff;
  }
`;
const DropupArrow = styled.div`
  && {
    position: absolute;
    box-sizing: content-box;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    top: 116px;
    left: 40px;
    margin-top: -8px;
    border-bottom: none;
    border-top-color: ${colors.cellBorder};
    ::after {
      content: '';
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      margin-top: 1px;
      bottom: 2px;
      left: -7px;
      border-bottom: none;
      border-top-color: #fff;
    }
  }
`;

const ResultsList = styled(Column)`
  max-height: 40vh;
  overflow-y: auto;
`;

const Option = styled(Cell)`
  border-radius: 6px;
  padding: 14px;

  ${({ selected }) =>
    selected &&
    `
      background: ${colors.link};
      color: #fff;
  `}
  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled(Input)`
  background: none;
  text-align: left;

  ${({ pointerCursorOnHover }) =>
    pointerCursorOnHover
      ? css`
          :hover {
            cursor: pointer;
          }
        `
      : ''}
`;

const DropdownSelect = ({
  courseId,
  name,
  formatValue,
  renderOption,
  options,
  loading,
  showClearButton,
  onChange,
  dropdown,
  disabled,
  value,
  disableTyping,
  displayResultsOnTop,
  error: errorProp,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inputText, setInputText] = useState('');

  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [fieldValue, setFieldValue] = useState(defaultValue || value);

  const setValue = index => {
    setMenuOpen(false);
    setFieldValue(options[index]);
    setSelectedIndex(0);
  };

  const clear = () => {
    if (ref.current) {
      ref.current.focus();
    }
    setFieldValue(null);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const onKeyUp = useCallback(
    e => {
      if (!menuOpen) {
        return true;
      }
      if (e.code === 'Enter') {
        setValue(selectedIndex);
        return false;
      }
      if (e.code === 'ArrowUp') {
        setSelectedIndex(Math.max(0, selectedIndex - 1));
        return false;
      }
      if (e.code === 'ArrowDown') {
        setSelectedIndex(
          Math.min(selectedIndex + 1, options ? options.length - 1 : 0)
        );
        return false;
      }
      return true;
    },
    [options, selectedIndex]
  );

  useEffect(() => {
    if (onChange) {
      onChange(fieldValue);
    }
    setInputText((formatValue ? formatValue(fieldValue) : fieldValue) || '');
  }, [fieldValue]);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  const optionProps = key => ({
    ref: current =>
      current &&
      selectedIndex === key &&
      scrollIntoView(current, {
        scrollMode: 'if-needed',
      }),
    selected: selectedIndex === key,
    onMouseOver: () => setSelectedIndex(key),
    onFocus: () => setSelectedIndex(key),
  });

  return (
    <>
      <StyledInput
        pointerCursorOnHover={disableTyping}
        css={`
          ${showClearButton && 'padding-right: 1.4em;'}
          ${disabled &&
            `
            cursor: inherit !important;
            pointer-events: none;
            user-select: none;
          `}
        `}
        name={`${fieldName}__select`}
        role={disableTyping ? 'button' : 'input'}
        type={disableTyping ? 'button' : 'input'}
        error={error || errorProp}
        value={inputText}
        autoComplete="off"
        onChange={text => {
          if (fieldValue && text.length < inputText.length) {
            clear();
            setMenuOpen(true);
          }
        }}
        {...propsUtils.onClick(() => setMenuOpen(true))}
        dropdown={
          menuOpen &&
          !disabled && (
            <>
              <DropdownMenu
                displayOnTop={displayResultsOnTop}
                ref={current =>
                  current &&
                  scrollIntoView(current, {
                    scrollMode: 'if-needed',
                  })
                }
                tabIndex="-1">
                {displayResultsOnTop && <DropupArrow />}
                {!displayResultsOnTop && <DropdownArrow />}
                {(loading && (
                  <Cell padding="14px">
                    <Label color="inherit" size="0.9em">
                      <Icon
                        size="0.8em"
                        marginRight="0.5em"
                        className="fa fa-spin fa-spinner"
                      />
                      Laster...
                    </Label>
                  </Cell>
                )) ||
                  (options && Array.isArray(options) && (
                    <ResultsList>
                      {options.map((option, key) => (
                        <Option
                          {...optionProps(key)}
                          {...propsUtils.onClick(e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setValue(key);
                          })}
                          key={key}>
                          {renderOption
                            ? renderOption(option)
                            : (formatValue && formatValue(option)) || option}
                        </Option>
                      ))}
                    </ResultsList>
                  ))}
                {dropdown}
              </DropdownMenu>
            </>
          )
        }
        {...props}>
        {fieldValue && showClearButton && (
          <ClearButton onClick={clear}>
            <Icon className="fa fa-times" />
          </ClearButton>
        )}
      </StyledInput>
      <input ref={ref} type="hidden" name={fieldName} value={fieldValue} />
    </>
  );
};

DropdownSelect.propTypes = {
  name: PropTypes.string.isRequired,
  formatValue: PropTypes.func,
  renderOption: PropTypes.func,
  options: PropTypes.array,
  loading: PropTypes.bool,
  showClearButton: PropTypes.bool,
  onChange: PropTypes.func,
  dropdown: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  error: PropTypes.string,
  displayResultsOnTop: PropTypes.bool,
};

DropdownSelect.defaultProps = {
  formatValue: null,
  renderOption: null,
  options: null,
  loading: false,
  showClearButton: false,
  onChange: null,
  dropdown: undefined,
  disabled: false,
  value: null,
  error: null,
  displayResultsOnTop: false,
};

export default DropdownSelect;
