import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnVisibilityChange } from '@src/hooks/hooks';
import { Cell } from './Layout';

const AspectRatio = ({ ratio, children, style, ...props }) => {
  const [height, setHeight] = useState(null);
  const refWrapper = useRef();

  const updateHeight = () => {
    if (!refWrapper.current) {
      return;
    }
    if (!ratio) {
      return;
    }
    const { width } = refWrapper.current.getBoundingClientRect();

    const newHeight = width / ratio;
    if (newHeight !== height) {
      setHeight(width / ratio);
    }
  };

  useOnVisibilityChange(updateHeight);

  return (
    <Cell
      {...props}
      ref={current => {
        if (!current) {
          return;
        }
        if (!refWrapper.current) {
          refWrapper.current = current;
        }
        if (!height) {
          updateHeight();
        }
      }}
      style={{
        ...(style || {}),
        height,
      }}>
      {children}
    </Cell>
  );
};

AspectRatio.propTypes = {
  ratio: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export const AspectRatioImage = ({ src, backgroundPosition, ...props }) => (
  <AspectRatio {...props}>
    <div
      css={`
        height: 100%;
        background-image: url(${src});
        background-position: ${backgroundPosition || 'center'};
        background-size: cover;
        background-repeat: no-repeat;
      `}
    />
  </AspectRatio>
);

export default AspectRatio;
