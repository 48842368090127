import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Section, Label } from '@src/components/Layout';
import Button from '@src/components/Button';
import PointSeparator from '@src/components/PointSeparator';
import InfoSection from './InfoSection';

const CVView = ({ experiencesDividedByType, description }) => {
  const Description = styled.p`
    margin-top: 0;
    margin-bottom: 0;
  `;

  const MissingDescription = styled(Description)`
    font-style: italic;
  `;

  let noExperiences = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const [, val] of Object.entries(experiencesDividedByType)) {
    if (val && val.length > 0) {
      noExperiences = false;
      break;
    }
  }

  const noCVData = noExperiences && !description;
  if (noCVData) {
    const MissingCV = () => {
      const MissingCVTxt = styled.p`
        font-size: 16px;
      `;

      const AddCVDataTxt = styled.p`
        font-size: 14px;
      `;

      return (
        <>
          <MissingCVTxt>Du har ikke lagt inn din CV enda</MissingCVTxt>
          <AddCVDataTxt>
            <Label>Trykk på {'«Rediger CV»'} for å legge inn.</Label>
          </AddCVDataTxt>
        </>
      );
    };
    return (
      <Section>
        <MissingCV />
      </Section>
    );
  }

  return (
    <>
      <InfoSection title="Om" style={{ marginTop: '40px' }}>
        {description && <Description>{description}</Description>}
        {!description && (
          <MissingDescription>
            Ingen beskrivelse er lagt inn.
          </MissingDescription>
        )}
      </InfoSection>
      <InfoSection title="Erfaring">
        <WorkElems data={experiencesDividedByType.work || []} />
      </InfoSection>
      <InfoSection title="Utdanning">
        <EducationItems data={experiencesDividedByType.education || []} />
      </InfoSection>
      <InfoSection title="Prosjekt">
        <ProjectItems data={experiencesDividedByType.project || []} />
      </InfoSection>
      {/* todo: Frivillig arbeiod */}
      <InfoSection title="Språk">
        <LanguageItems data={experiencesDividedByType.language || []} />
      </InfoSection>
    </>
  );
};

const formatDate = (fromDateStr, toDateStr, onlyYears = false) => {
  if (!fromDateStr && !toDateStr) {
    return null;
  }

  const isYearOnly = dateStr => /^[0-9]{4}$/.test(dateStr);
  const getDateFromYear = year => {
    let date = new Date();
    date = new Date();
    date.setDate(0);
    date.setMonth(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setMilliseconds(0);
    date.setFullYear(parseInt(year, 10));
    return date;
  };

  let dateFrom;
  let dateTo;
  if (isYearOnly(fromDateStr)) {
    dateFrom = getDateFromYear(fromDateStr);
  } else {
    dateFrom = new Date(fromDateStr);
  }
  if (isYearOnly(toDateStr)) {
    dateTo = getDateFromYear(toDateStr);
  } else {
    dateTo = new Date(toDateStr);
  }

  const monthIdxToName = {
    0: 'jan.',
    1: 'feb.',
    2: 'mar.',
    3: 'apr.',
    4: 'mai.',
    5: 'jun.',
    6: 'jul.',
    7: 'aug.',
    8: 'sept.',
    9: 'okt.',
    10: 'nov.',
    11: 'des.',
  };

  const dateFromValid = !Number.isNaN(dateFrom.getTime());
  const dateToValid = !Number.isNaN(dateTo.getTime());

  const fromMonthPres = dateFromValid
    ? monthIdxToName[dateFrom.getMonth()]
    : '';
  const fromYearPres = dateFromValid ? dateFrom.getFullYear() : '?';
  const toMonthPres = dateToValid ? monthIdxToName[dateTo.getMonth()] : 'nå';
  const toYearPres = dateToValid ? dateTo.getFullYear() : '';

  const dateNow = new Date();

  let timespan = '';
  if (dateFromValid) {
    const monthsBetween = Math.abs(
      ((dateTo ? dateTo.getFullYear() : dateNow.getFullYear()) -
        dateFrom.getFullYear()) *
        12 -
        Math.abs(
          // Add one to make the month ranges inclusive(eg jan -> march is 3 months)
          (dateTo ? dateTo.getMonth() : dateNow.getMonth()) -
            dateFrom.getMonth()
        )
    );

    const yearsBetween = Math.floor(monthsBetween / 12);
    const remainingMonthsBetween = monthsBetween % 12;
    const months =
      remainingMonthsBetween !== 0 ? `${remainingMonthsBetween} mnd ` : '';
    const years = yearsBetween !== 0 ? `${yearsBetween} år ` : '';
    timespan = `${years}${months}`;
  }

  if (onlyYears) {
    return `${fromYearPres} - ${toYearPres}`;
  }

  return (
    <span>
      {fromMonthPres} {fromYearPres} - {toMonthPres} {toYearPres}
      {timespan && <PointSeparator />}
      {timespan && timespan}
    </span>
  );
};

const Details = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #494949;

  ${props =>
    props.last
      ? css`
          margin-bottom: 10px;
        `
      : ''}
`;

const LanguageName = styled.p`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 0;
`;

const LanguageDesc = styled.p`
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
`;

const SubCont = styled.div`
  margin-bottom: 10px;
  min-height: 72px;
`;

const Header = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1em;
`;
const LanguageCont = styled.div``;

const Description = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

const ActionBtn = ({ children, ...props }) => (
  <Button small alternative {...props}>
    {children}
  </Button>
);
const EditBtnExperience = styled(ActionBtn)`
  float: right;
`;

const EditIcon = styled.i`
  margin-right: 5px;
`;

const NoDataDesc = styled(Label)`
  font-style: italic;
  margin-top: 7px;
`;

const EditExperienceButtonAndIcon = ({ experienceUID, onEditClick }) => (
  <EditBtnExperience onClick={() => onEditClick(experienceUID)}>
    <EditIcon className="fas fa-pencil-alt" />
    Endre
  </EditBtnExperience>
);

EditExperienceButtonAndIcon.propTypes = {
  experienceUID: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
export const WorkElems = ({ data, displayEditBtn = false, onEditClick }) =>
  data && data.length ? (
    data.map(e => (
      <SubCont key={`${e.UID}`}>
        <Header>{e.designation}</Header>
        {displayEditBtn && (
          <EditExperienceButtonAndIcon
            experienceUID={e.UID}
            onEditClick={onEditClick}
          />
        )}
        <Description>{e.name}</Description>
        <Details>{formatDate(e.startdate, e.enddate)}</Details>
      </SubCont>
    ))
  ) : (
    <NoDataDesc>Du har ikke lagt til noen arbeidserfaringer enda.</NoDataDesc>
  );

// eslint-disable-next-line react/prop-types
export const EducationItems = ({ data, displayEditBtn = false, onEditClick }) =>
  data && data.length ? (
    data.map(e => (
      <SubCont key={`${e.UID}`}>
        <Header>{e.designation}</Header>
        {displayEditBtn && (
          <EditExperienceButtonAndIcon
            experienceUID={e.UID}
            onEditClick={onEditClick}
          />
        )}
        <Description>{e.name}</Description>
        <Details>{formatDate(e.startdate, e.enddate, true)}</Details>
      </SubCont>
    ))
  ) : (
    <NoDataDesc>Du har ikke lagt til noen utdanninger enda.</NoDataDesc>
  );

// eslint-disable-next-line react/prop-types
export const ProjectItems = ({ data, displayEditBtn = false, onEditClick }) =>
  data && data.length ? (
    data.map(e => (
      <SubCont key={`${e.UID}`}>
        <Header>{e.designation}</Header>
        {displayEditBtn && (
          <EditExperienceButtonAndIcon
            experienceUID={e.UID}
            onEditClick={onEditClick}
          />
        )}
        <Description>{e.name}</Description>
        <Details>{formatDate(e.startdate, e.enddate)}</Details>
      </SubCont>
    ))
  ) : (
    <NoDataDesc>Du har ikke lagt til noen prosjekter enda.</NoDataDesc>
  );

// eslint-disable-next-line react/prop-types
export const LanguageItems = ({ data, displayEditBtn = false, onEditClick }) =>
  data ? (
    data.map(e => (
      <LanguageCont key={`${e.UID}`}>
        <LanguageName>{e.name}</LanguageName>
        <PointSeparator />
        <LanguageDesc>{e.designation}</LanguageDesc>
        {displayEditBtn && (
          <EditExperienceButtonAndIcon
            experienceUID={e.UID}
            onEditClick={onEditClick}
          />
        )}
      </LanguageCont>
    ))
  ) : (
    <NoDataDesc>Du har ikke lagt til noen språkferdigheter enda.</NoDataDesc>
  );

export default CVView;
