import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import FormInput from '@components/form-input';

import './styles/login-form.scss';

const ChangePwdForm = ({ handleSubmit, handleCancel }) => (
  <form className="login-form__container" onSubmit={handleSubmit}>
    <div className="login-form__input-wrapper">
      <Field
        autoComplete="username"
        className="login-form__input"
        component={FormInput}
        label="Brukernavn:"
        name="username"
        type="text"
      />
    </div>

    <div className="login-form__submit-button-wrapper">
      <button className="btn login-form__submit-button" type="submit">
        Få nytt passord
      </button>
      <button
        className="btn login-form__forgot-button"
        type="button"
        onClick={handleCancel}>
        Avbryt
      </button>
    </div>
  </form>
);

ChangePwdForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'changepwd',
})(ChangePwdForm);
