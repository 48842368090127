import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Block = styled.div`
  background: ${({ background }) => background};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};
  min-width: min-content;
`;

Block.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Block.defaultProps = {
  background: 'none',
  padding: '0',
  paddingTop: null,
  paddingRight: null,
  paddingBottom: null,
  paddingLeft: null,
  margin: '0',
  marginTop: null,
  marginRight: null,
  marginBottom: null,
  marginLeft: null,
};

export const TextElement = styled(Block)`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  width: fit-content;
  text-align: ${({ align }) => align};
`;

TextElement.PropTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  align: PropTypes.string,
};

TextElement.defaultProps = {
  size: null,
  color: null,
  align: null,
};

export const A = styled.a`
  &,
  &:link,
  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
`;
