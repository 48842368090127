import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { all } from 'redux-saga/effects';
import {
  responsiveStoreEnhancer,
  responsiveStateReducer,
} from 'redux-responsive';

import authReducer from '@reducers/auth.reducer';
import alertReducer from '@reducers/alert.reducer';
import profileReducer from '@reducers/profile.reducer';
import employeesReducer from '@reducers/employees.reducer';
import notificationsReducer from '@reducers/notifications.reducer';

import authSagas from '@sagas/auth.sagas';
import profileSagas from '@sagas/profile.sagas';
import employeesSagas from '@sagas/employees.sagas';
import competencesSagas from '@sagas/competences.sagas';
import { reducer as formReducer } from 'redux-form';
import cvsSagas from '@sagas/cvs.sagas';
import cvsReducer from '@reducers/cvs.reducer';
import competencesReducer from '@reducers/competences.reducer';

export const configureStore = initialState => {
  const reducers = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    profile: profileReducer,
    cv: cvsReducer,
    competences: competencesReducer,
    form: formReducer,
    employees: employeesReducer,
    browser: responsiveStateReducer,
    notifications: notificationsReducer,
  });
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware];

  /* eslint no-underscore-dangle: 0 */
  const devTools =
    process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({
          trace: true,
        })
      : undefined;

  const enhancer = devTools
    ? compose(
        applyMiddleware(...middlewares),
        responsiveStoreEnhancer,
        devTools
      )
    : compose(
        applyMiddleware(...middlewares),
        responsiveStoreEnhancer
      );

  const store = createStore(reducers, initialState, enhancer);

  function* rootSaga() {
    yield all([
      ...authSagas,
      ...profileSagas,
      ...employeesSagas,
      ...cvsSagas,
      ...competencesSagas,
    ]);
  }
  sagaMiddleware.run(rootSaga);

  return store;
};
