import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Section, Row } from '@src/components/Layout';
import { Block } from '@src/components/LayoutBase';
import PointSeparator from '@src/components/PointSeparator';
import InfoSection from './InfoSection';

const AgreementsWaitingForSign = ({
  agreementsForSign,
  onGotoAgreementSigning,
}) => {
  const StyleInfoSection = { width: '100%' };
  const StyleTitle = { color: '#767676' };
  const Wrapper = styled.div`
    background-color: #fafafa;
    padding-top: 65px;
    padding-bottom: 30px;
  `;

  const ContentWrapper = styled.div`
    width: fit-content;
  `;

  const Description = styled.p`
    color: #464646;
  `;

  const NoMarginP = styled.p`
    margin: 0;
  `;

  const SignDate = styled(NoMarginP)`
    font-weight: 500;
    color: #191919;
    margin-bottom: 2px;
  `;

  const SignInfo = styled(NoMarginP)`
    font-weight: 500;
  `;

  const GotoAgreementIcon = styled.i`
    color: #1573e6;
    margin-left: 6px;
    transition: transform 0.18s ease-out;
  `;

  const GotoAgreementButton = styled.button`
    border: 1px solid #1573e6;
    width: 199px;
    height: 30px;
    color: #1573e6;
    margin-top: 15px;

    :hover {
      background-color: #e7eef7;
      cursor: pointer;

      ${GotoAgreementIcon} {
        transform: translateX(6px);
      }
    }
  `;

  return (
    <Wrapper>
      <Section>
        <ContentWrapper>
          <InfoSection
            style={StyleInfoSection}
            title="Avtaler til signering"
            titleStyle={StyleTitle}>
            <Description>
              {`Du har ${agreementsForSign.length} avtale${
                agreementsForSign.length > 1 ? 'r' : ''
              }
              som venter på signering.`}
            </Description>

            <Row>
              {agreementsForSign.map(a => (
                <Block marginTop="10px" marginRight="45px" key={a.date}>
                  <SignDate>{a.date}</SignDate>
                  <SignInfo>
                    {a.conductedBy.fullname}
                    <PointSeparator /> Ikke signert
                  </SignInfo>
                  <GotoAgreementButton
                    small
                    onClick={() => onGotoAgreementSigning(a)}>
                    Gå til avtale{' '}
                    <GotoAgreementIcon className="fas fa-arrow-right" />
                  </GotoAgreementButton>
                </Block>
              ))}
            </Row>
          </InfoSection>
        </ContentWrapper>
      </Section>
    </Wrapper>
  );
};

export default AgreementsWaitingForSign;

AgreementsWaitingForSign.propTypes = {
  agreementsForSign: PropTypes.array.isRequired,
  onGotoAgreementSigning: PropTypes.func.isRequired,
};

AgreementsWaitingForSign.defaultProps = {};
