import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { propsUtils } from '@src/utils/index';
import { Cell, Row } from './Layout';
import { colors } from '../styles';

const cssActiveMixin = `
  color: ${colors.font};
  border-bottom: 2px solid ${colors.font};
  margin-bottom: 0;
  `;

const StyledTab = styled(Cell)`
  margin: 2px 1em;
  cursor: pointer;
  line-height: 2.5;
  font-weight: 550;
  color: ${colors.fontLight};

  ${({ active }) => active && cssActiveMixin};

  :hover,
  :focus {
    ${cssActiveMixin}
  }
`;

export const Tab = ({ value, onClick, children, ...props }) => {
  return (
    <StyledTab {...propsUtils.onClick(onClick)} {...props}>
      {children}
    </StyledTab>
  );
};

const Tabs = ({ value: valueProp, onChange, children, ...props }) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <Row wrap {...props}>
      {React.Children.map(children, (child, key) => {
        return React.cloneElement(child, {
          onClick:
            child.props.onClick ||
            (() => {
              setValue(child.props.value || key);
            }),
          active: value === child.props.value || value === key,
        });
      })}
    </Row>
  );
};

export default Tabs;
