/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Section } from '@src/components/Layout';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@src/components/Button';
import { Block } from '@src/components/LayoutBase';
import Table from '@src/components/Table';
import posed from 'react-pose';
import { bindActionCreators } from 'redux';
import {
  profileAddMultiplePersoncompetences,
  profileEditMultiplePersoncompetences,
} from '@actions/competences.actions';
import InfoCollection from './InfoCollection';
import { viewModeView, viewModeEdit } from './MyProfile';
import UnsavedChangesDisplay from './UnsavedChangesBanner';

const competencePassedPercToNameMapping = {
  'Ikke svart': 'Ikke svart',
  '?': 'Ikke svart',
  '0': 'Ikke svart',
  '20': 'Ingen kjennskap til',
  '40': 'Litt kjennskap',
  '60': 'Handlingskompetent',
  '80': 'Dybdekompetanse',
  '100': 'Formidlingskompetanse',
};

const splitCompetenceDataByGroup = (competenceData, personcompetenceData) => {
  const dividedByGroup = {};
  competenceData // competences without a competence-group comes last and is labeled 'Annet'
    .sort(a => (a.competence_groups.length ? -1 : 1))
    .forEach(c => {
      let currGroupName;
      if (c.competence_groups.length) {
        currGroupName = c.competence_groups[0].title;
      } else {
        currGroupName = 'Annet';
      }
      if (!dividedByGroup[currGroupName]) {
        dividedByGroup[currGroupName] = [];
      }

      let showPassedTxtForPerson;
      const matchingCompetence = personcompetenceData.find(
        p => p.competence.id === c.id
      );
      if (matchingCompetence) {
        showPassedTxtForPerson = matchingCompetence.passed;
      } else {
        showPassedTxtForPerson = 0;
      }

      const competenceWithPassedTxt = {
        competence: c,
        passed: showPassedTxtForPerson,
      };
      dividedByGroup[currGroupName].push(competenceWithPassedTxt);
    });

  return dividedByGroup;
};

const dividePersonCompetencesByGroupIfInCompetences = personCompetences => {
  const dividedByGroup = {};
  if (!personCompetences) {
    return dividedByGroup;
  }

  personCompetences // competences without a competence-group comes last and is labeled 'Annet'
    .sort(a => (a.competence_groups && a.competence_groups.length ? -1 : 1))
    .forEach(c => {
      let currGroupName;
      if (c.competence_groups && c.competence_groups.length) {
        currGroupName = c.competence_groups[0].title;
      } else {
        currGroupName = 'Annet';
      }
      if (!dividedByGroup[currGroupName]) {
        dividedByGroup[currGroupName] = [];
      }
      dividedByGroup[currGroupName].push(c);
    });

  return dividedByGroup;
};

// -------------------------------------------------
const CompetenceManage = ({
  personcompetences,
  competences,
  manageMode,
  onModeChange,
  profile,
  profileAddMultiplePersoncompetences,
  profileEditMultiplePersoncompetences,
}) => {
  const allCompetencesWithLevelByGroupRef = useRef({}); // to avoid rerendering the entire compoennt tree when comeptence-levels are changed
  const competenceTitlesWithChangedLevelRef = useRef([]);
  const [personCompetencesByGroup, setPersonCompetencesByGroup] = useState({});
  const [, forceRerender] = useState();

  useEffect(() => {
    if (personcompetences.data && competences.data) {
      allCompetencesWithLevelByGroupRef.current = splitCompetenceDataByGroup(
        competences.data,
        personcompetences.data
      );
      forceRerender({});
    }
  }, [competences.data, personcompetences.data]);

  useEffect(() => {
    if (personcompetences.data) {
      setPersonCompetencesByGroup(
        dividePersonCompetencesByGroupIfInCompetences(
          personcompetences.data,
          competences.data
        )
      );
    }
  }, [personcompetences.data]);

  const modeStrings = {};
  modeStrings[viewModeView] = {
    actionBtnTxt: 'Rediger kompetanse',
    header: 'Kompetanse',
  };
  modeStrings[viewModeEdit] = {
    actionBtnTxt: 'Tilbake',
    header: 'Rediger kompetanse',
  };

  const HeaderCategory = styled.h3`
    font-weight: 450;
    font-size: 1.2em;
  `;
  const NameCol = styled.td`
    width: 60%;
  `;
  const LevelCol = styled.td`
    width: 40%;
  `;

  const StyledTable = styled(Table)`
    width: 55%;
    @media screen and (max-width: 1100px) {
      width: 80%;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  `;

  const ContentWrapper = styled.div``;

  const NoDataSection = styled(Section)`
    height: 500px;
  `;

  const InfoCollectionStatusMsg = styled.p`
    font-size: 1.5em;
    position: relative;
    top: 34%;
    transform: translate(0, -50%);
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
  `;

  const ErrorIcon = styled.i`
    font-size: 1.5em;
    font-size: 2em;
    margin-right: 5px;
    position: relative;
    top: 9px;
  `;

  const onSaveData = () => {
    const personcompetenceNameToPassed = {};
    Object.getOwnPropertyNames(
      allCompetencesWithLevelByGroupRef.current
    ).forEach(competenceGroupName => {
      allCompetencesWithLevelByGroupRef.current[competenceGroupName].forEach(
        personcompetence => {
          personcompetenceNameToPassed[personcompetence.competence.title] =
            personcompetence.passed;
        }
      );
    });

    const changedPersonCompetencesToPut = [];
    const addedPersonCompetencesToPost = [];

    const competencenameToPersoncompetenceID = {};
    personcompetences.data.forEach(pc => {
      competencenameToPersoncompetenceID[pc.competence.title] = pc.id;
    });

    competenceTitlesWithChangedLevelRef.current.forEach(ct => {
      const competenceForPersonalCompetence = competences.data.find(
        e => e.title === ct
      );
      if (competenceForPersonalCompetence) {
        if (
          Object.getOwnPropertyNames(
            competencenameToPersoncompetenceID
          ).includes(ct)
        ) {
          if (competenceForPersonalCompetence) {
            changedPersonCompetencesToPut.push({
              passed: personcompetenceNameToPassed[ct],
              person_competence_id: competencenameToPersoncompetenceID[ct],
            });
          }
        } else {
          addedPersonCompetencesToPost.push({
            competence_id: competenceForPersonalCompetence.id,
            passed: `${personcompetenceNameToPassed[ct]}`,
            person_id: profile.person_id,
          });
        }
      }
    });

    // todo- loading
    if (addedPersonCompetencesToPost.length) {
      profileAddMultiplePersoncompetences({
        personcompetences: addedPersonCompetencesToPost.map(pc => {
          const progressMap = { 0: 0, 20: 1, 40: 2, 60: 3, 80: 4, 100: 5 };
          const curr = pc;
          curr.passed = progressMap[curr.passed];
          return { personcompetence: curr };
        }),
      });
    }

    if (changedPersonCompetencesToPut.length) {
      profileEditMultiplePersoncompetences({
        personcompetences: changedPersonCompetencesToPut.map(pc => {
          return { personcompetence: pc };
        }),
      });
    }

    if (
      changedPersonCompetencesToPut.length ||
      addedPersonCompetencesToPost.length
    ) {
      competenceTitlesWithChangedLevelRef.current = [];
      onModeChange();
    }
  };

  const onChangeLevelsForGroup = (group, title, newData) => {
    allCompetencesWithLevelByGroupRef.current[group] = newData;
    if (!competenceTitlesWithChangedLevelRef.current.includes(title)) {
      competenceTitlesWithChangedLevelRef.current.push(title);
    }
  };

  const viewModeAndError =
    manageMode === viewModeView && personcompetences.error;
  const editModeAndError = manageMode === viewModeEdit && competences.error;
  const viewModeAndLoading =
    (manageMode === viewModeView && personcompetences.isFetching) ||
    !personcompetences.data;
  const editModeAndLoading =
    manageMode === viewModeEdit &&
    (competences.ifFetching || !competences.data);

  const viewModeAndNoData =
    personcompetences &&
    !personcompetences.isFetching &&
    manageMode === viewModeView &&
    ((personcompetences.data && personcompetences.data.length === 0) ||
      !personcompetences.data);

  if (viewModeAndError || editModeAndError) {
    return (
      <NoDataSection>
        <InfoCollection title={modeStrings[manageMode].header} />
        <InfoCollectionStatusMsg>
          <ErrorIcon className="fas fa-exclamation-circle" /> Det oppsto en feil
          ved henting av dataen
        </InfoCollectionStatusMsg>
      </NoDataSection>
    );
  }

  if (viewModeAndLoading || editModeAndLoading) {
    return (
      <NoDataSection>
        <InfoCollection title={modeStrings[manageMode].header} />
        <InfoCollectionStatusMsg>
          <i className="fa fa-spin fa-spinner" /> Laster...
        </InfoCollectionStatusMsg>
      </NoDataSection>
    );
  }

  return (
    <Section>
      <InfoCollection
        style={{ paddingBottom: '25px' }}
        title={modeStrings[manageMode].header}
        actionButton={
          <Button small alternative onClick={onModeChange}>
            {modeStrings[manageMode].actionBtnTxt}
          </Button>
        }>
        {(viewModeAndNoData && (
          <NoDataSection>
            <InfoCollectionStatusMsg>
              Ingen kompetanser er lagt til.
            </InfoCollectionStatusMsg>
            <InfoCollectionStatusMsg>
              Trykk på {'«Rediger kompetanse»'} for å legge til .
            </InfoCollectionStatusMsg>
          </NoDataSection>
        )) || (
          <ContentWrapper>
            {manageMode === viewModeView &&
              Object.keys(personCompetencesByGroup)
                .sort()
                .map(competenceGroupName => (
                  <Block marginTop="40px">
                    <HeaderCategory>{competenceGroupName}</HeaderCategory>
                    <StyledTable highlightOddRows headings={[null, null]}>
                      {personCompetencesByGroup[competenceGroupName].map(e => (
                        <tr id={e.competence.id}>
                          <NameCol>{e.competence.title}</NameCol>
                          <LevelCol>
                            {e.passed === '?' || !e.passed
                              ? 'Ikke svart'
                              : competencePassedPercToNameMapping[e.passed]}
                          </LevelCol>
                        </tr>
                      ))}
                    </StyledTable>
                  </Block>
                ))}

            {manageMode === viewModeEdit &&
              Object.keys(allCompetencesWithLevelByGroupRef.current)
                .sort()
                .sort(a => (a === 'Annet' ? 1 : 0))
                .map(competencesKey => (
                  <CompetenceEditCompetenceCategory
                    header={competencesKey}
                    key={competencesKey}
                    onSaveData={onSaveData}
                    onChangeLevelsForGroup={onChangeLevelsForGroup}
                    competencesWithLevelForGroup={
                      allCompetencesWithLevelByGroupRef.current[competencesKey]
                    }
                  />
                ))}
          </ContentWrapper>
        )}
      </InfoCollection>
    </Section>
  );
};

const SliderThumbStyle = css`
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 30px;
  background: #1573e6;
  box-shadow: 0px 2px 10px -2px black(1);
  border: none;
  cursor: pointer;
`;

const StyledSlider = styled.input`
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  -webkit-appearance: none;
  z-index: 3;
  background: transparent;

  /*Has to be divided for styling to work*/
  &::-moz-range-thumb {
    ${SliderThumbStyle}
  }
  &::-webkit-slider-thumb {
    ${SliderThumbStyle}
  }

  :focus {
    &::-webkit-slider-thumb {
      background: #0c57b3;
    }
    &::-moz-range-thumb {
      background: #0c57b3;
    }
  }

  &::-webkit-slider-runnable-track,
  &::-moz-slider-runnable-track {
    -webkit-appearance: none;
  }
`;

const TickMark = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #eaeaea;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-left: 3px;
  &:last-child {
    margin-right: 2px;
  }
  :hover {
    cursor: pointer;
  }
`;

const SliderWrapper = styled.div`
  display: inline-block;
`;

const SliderTicks = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10px;
  position: relative;
  top: 12px;
  z-index: 2;
`;

const SliderRail = styled.div`
  display: inline-block;
  background-color: #eaeaea;
  height: 5p6px;
  position: relative;
  top: 27px;
  width: 88%;
  height: 5px;
  margin-left: 5px;
  margin-right: -49px;
`;

const CompetenceTitle = styled.p`
  font-weight: bold;
  font-size: 1.2em;
  display: inline-block;
  margin: 10px 0;
`;

const CompetenceLevelTxt = styled.p`
  font-size: 1.2em;
  display: inline-block;
  margin: 10px 0;
`;

const CompetenceLevel = styled(Block)`
  display: flex;
  flex-direction: column;
  height: 89px;
`;
const Header = styled.h4`
  margin: 0;
`;

const Wrapper = styled(Block)`
  margin-top: 40px;
`;

const HeaderWrapper = styled(Block)`
  border: 1px solid #eaebed;
  width: 100%;
  padding: 8px;
  display: flex;
  :hover {
    cursor: pointer;
  }

  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 4;
`;

const HeaderIconWrapper = styled.div`
  float: left;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #ecf4fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

const HeaderIcon = styled.i`
  color: #1573e6;
`;

const Competence = styled(Block)`
  border: 1px solid #eaebed;
  display: flex;
  flex-direction: column;
`;

const CompetemnceLevelSliderWrapper = styled.div`
  display: inline-block;
  position: relative;
  bottom: 30px;
`;

const DescPart = styled.div``;
const CompetenceTitleAndDescToggle = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ToggleDescButton = styled(Button)`
  color: #1573e6;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 33px;
  border-radius: 0;
  width: fit-content;
  :hover {
    background-color: transparent;
    border: none;
  }
`;

const DescTxt = styled.p`
  padding: 10px;
`;
const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const SecondRow = posed.div({
  shownCont: {
    'background-color': '#FAFAFA',
    height: 'auto',
    overflow: 'auto',
  },
  hiddenCont: {
    'background-color': '#FAFAFA',
    height: '0px',
    overflow: 'hidden',
  },
});

const Slider = ({ onChange, value, numSteps }) => {
  return (
    <SliderWrapper>
      <SliderRail />
      <SliderTicks>
        {Array.from(Array(numSteps)).map((_, i) => (
          <TickMark key={i} />
        ))}
      </SliderTicks>
      <StyledSlider
        type="range"
        defaultValue={value}
        min="0"
        step="20"
        max="100"
        onInput={e => {
          onChange(e.target.value);
        }}
      />
    </SliderWrapper>
  );
};

Slider.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  numSteps: PropTypes.number.isRequired,
};

// using class-based because of problems with useState in functional component beeing to slow
class CompetenceEditCompetenceCategory extends React.Component {
  showPassedNameToNum = {
    'Ikke svart': 0,
    'Ingen kjennskap til': 1,
    'Litt kjennskap': 2,
    Handlingskompetent: 3,
    Dybdekompetanse: 4,
    Formidlingskompetanse: 5,
  };

  showPassedNumToName = this.swapKeysAndValues(this.showPassedNameToNum);

  ContentWrapper = posed.div({
    shown: {
      height: 'auto',
      overflow: 'hidden',
      transition: {
        duration: this.clampNum(
          200 + this.props.competencesWithLevelForGroup.length * 20,
          100,
          400
        ),
        ease: 'easeIn',
      },
    },
    hidden: { height: 0, overflow: 'hidden ' },
    transition: {
      duration: this.clampNum(
        300 + this.props.competencesWithLevelForGroup.length * 20,
        120,
        600
      ),
      ease: 'easeIn',
    },
  });

  constructor(props) {
    super(props);
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
    this.state = {
      collapsed: true,
      competenceLevelValues: this.initializeCompetenceLevelValues(),
      storedCompetencesWithLevelForGroup: this.props
        .competencesWithLevelForGroup,
      descForCompetencTitleCollapsed: {},
      hasUnsavedChanges: false,
    };
  }

  onToggleDesc(competenceTitleToggled) {
    const copyOfToggleState = this.state.descForCompetencTitleCollapsed;
    copyOfToggleState[competenceTitleToggled] = !copyOfToggleState[
      competenceTitleToggled
    ];
    this.setState(copyOfToggleState);
  }

  onValueChange = (v, title) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const storedCompetenceLevelData = this.state
      .storedCompetencesWithLevelForGroup;
    const competenceToEdit = storedCompetenceLevelData.findIndex(
      e => e.competence.title === title
    );
    storedCompetenceLevelData[competenceToEdit].passed = parseInt(v, 10);

    // eslint-disable-next-line react/no-access-state-in-setstate
    const competenceLevelValueCopy = this.state.competenceLevelValues;
    competenceLevelValueCopy[title] = parseInt(v, 10);
    this.setState({
      competenceLevelValues: competenceLevelValueCopy,
    });
    this.setState({
      storedCompetencesWithLevelForGroup: storedCompetenceLevelData,
    });
    this.setState({ hasUnsavedChanges: true });

    this.props.onChangeLevelsForGroup(
      this.props.header,
      title,
      storedCompetenceLevelData
    );
  };

  clampNum(x, min, max) {
    if (x > max) {
      return max;
    }
    if (x < min) {
      return min;
    }
    return x;
  }

  toggleCollapsed() {
    const oldstateCollapsed = this.state.collapsed;
    if (this.state.collapsed === null) {
      this.setState({ collapsed: false });
    } else {
      this.setState({ collapsed: !oldstateCollapsed });
    }
  }

  initializeCompetenceLevelValues() {
    const competenceLevelValues = {};
    this.props.competencesWithLevelForGroup.forEach(e => {
      competenceLevelValues[e.competence.title] = e.passed;
    });
    return competenceLevelValues;
  }

  swapKeysAndValues(obj) {
    const newObj = {};
    Object.getOwnPropertyNames(obj).forEach(p => {
      newObj[obj[p]] = p;
    });
    return newObj;
  }

  shouldShowDesc(competenceTitle) {
    return this.state.descForCompetencTitleCollapsed[competenceTitle];
  }

  render() {
    const content = this.state.storedCompetencesWithLevelForGroup.map(c => (
      <Competence>
        <FirstRow>
          <CompetenceTitleAndDescToggle>
            <CompetenceTitle key={c.id}>{c.competence.title}</CompetenceTitle>
            <ToggleDescButton
              onClick={() => this.onToggleDesc(c.competence.title)}>
              {this.shouldShowDesc(c.competence.title)
                ? 'Skjul beskrivelse'
                : 'Vis beskrivelse'}
            </ToggleDescButton>
          </CompetenceTitleAndDescToggle>

          <CompetenceLevel>
            <CompetenceLevelTxt>
              {
                competencePassedPercToNameMapping[
                  this.state.competenceLevelValues[c.competence.title]
                ]
              }
            </CompetenceLevelTxt>
            <CompetemnceLevelSliderWrapper>
              <Slider
                numSteps={6}
                value={this.state.competenceLevelValues[c.competence.title]}
                onChange={v => this.onValueChange(v, c.competence.title)}
              />
            </CompetemnceLevelSliderWrapper>
          </CompetenceLevel>
        </FirstRow>
        <SecondRow
          pose={
            this.shouldShowDesc(c.competence.title) ? 'shownCont' : 'hiddenCont'
          }>
          <DescPart>
            {c.competence.description ||
              (true && (
                <>
                  <DescTxt>
                    Du vet hvem du kan kontakte for å få hjelp til å løse en
                    utfordring/problemstilling som har oppstått.
                    <br />
                    Du ser sammenhenger mellom ulike fagområder i organisasjonen
                    (både i egen enhet og på tvers av enheter) og har oversikt
                    over aktuelle samarbeidspartnere i organisasjonen.
                  </DescTxt>
                </>
              ))}
          </DescPart>
        </SecondRow>
      </Competence>
    ));

    return (
      <>
        {this.state.hasUnsavedChanges && (
          <UnsavedChangesDisplay
            onSave={() =>
              this.props.onSaveData(
                this.props.header,
                this.state.competenceLevelValues
              )
            }
          />
        )}
        <Wrapper>
          <HeaderWrapper onClick={this.toggleCollapsed} role="button">
            <HeaderIconWrapper>
              <HeaderIcon className="fas fa-list" />
            </HeaderIconWrapper>
            <Header>{this.props.header}</Header>
          </HeaderWrapper>
          <this.ContentWrapper pose={this.state.collapsed ? 'hidden' : 'shown'}>
            {content}
          </this.ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

CompetenceManage.propTypes = {
  personcompetences: PropTypes.array.isRequired,
  competences: PropTypes.array.isRequired,
  manageMode: PropTypes.oneOf([viewModeEdit, viewModeView]).isRequired,
  onModeChange: PropTypes.func.isRequired,
  profileAddMultiplePersoncompetences: PropTypes.func.isRequired,
  profileEditMultiplePersoncompetences: PropTypes.func.isRequired,
  profile: PropTypes.shape({}).isRequired,
};

CompetenceManage.defaultProps = {};
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      profileAddMultiplePersoncompetences,
      profileEditMultiplePersoncompetences,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetenceManage);

CompetenceEditCompetenceCategory.propTypes = {
  header: PropTypes.string.isRequired,
  competencesWithLevelForGroup: PropTypes.shape(PropTypes.array).isRequired,
  onSaveData: PropTypes.func.isRequired,
  onChangeLevelsForGroup: PropTypes.func.isRequired,
};
