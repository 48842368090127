import {
  format as formatFn,
  formatDistance as formatDistanceFn,
  formatDistanceToNow as formatDistanceToNowFn,
  formatRelative as formatRelativeFn,
} from 'date-fns';
import nbLocale from 'date-fns/locale/nb';

const supressExceptions = (fn, ...params) => {
  try {
    return fn(...params);
  } catch {
    return null;
  }
};

const injectLocale = options =>
  Object.assign(options || {}, { locale: nbLocale });

export const format = (date, format, options) =>
  supressExceptions(formatFn, date, format, injectLocale(options));
export const formatDistance = (date, baseDate, options) =>
  supressExceptions(formatDistanceFn, date, baseDate, injectLocale(options));
export const formatDistanceToNow = (date, options) =>
  supressExceptions(formatDistanceToNowFn, date, injectLocale(options));
export const formatRelative = (date, baseDate, options) =>
  supressExceptions(formatRelativeFn, date, baseDate, injectLocale(options));

// https://stackoverflow.com/a/44198641
export const isValidDate = date =>
  date &&
  Object.prototype.toString.call(date) === '[object Date]' &&
  !Number.isNaN(date);

export const emptyDate = () => {
  const d = new Date();
  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
  d.setFullYear(0);
  d.setMonth(0);
  d.setDate(1);
  return d;
};
