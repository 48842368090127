const prefix = 'action';
export const ACTION_INIT = `${prefix} init request`;
export const ACTION_SUCCESS = `${prefix} success success`;
export const ACTION_LOADING = `${prefix} loading failure`;
export const ACTION_GOTO = `${prefix} goto request`;
export const ACTION_BLUR = `${prefix} blur success`;
export const ACTION_CLEAR = `${prefix} clear success`;
export const ACTION_ERROR = `${prefix} error success`;

export const actionInit = () => ({
  type: ACTION_INIT,
});

export const actionSuccess = () => ({
  type: ACTION_SUCCESS,
});

export const actionLoading = () => ({
  type: ACTION_LOADING,
});

export const actionBlur = () => ({
  type: ACTION_BLUR,
});

export const actionClear = () => ({
  type: ACTION_CLEAR,
});
export const actionError = () => ({
  type: ACTION_ERROR,
});
