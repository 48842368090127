/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  Icon,
  Row,
  Column,
  Cell,
  Label,
  LinkLabel,
} from '@src/components/Layout';
import { Input, DatePicker } from '@src/components/Input';
import { colors } from '@src/styles/index';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { useField } from '@rocketseat/unform';
import Modal from '@src/components/Modal';
import { useDebounce, useAxiosRequest } from '@src/hooks/hooks';
import { backendUrl } from '@config';
import { propsUtils } from '@src/utils/index';
import scrollIntoView from 'scroll-into-view-if-needed';
import Tabs, { Tab } from '@src/components/Tabs';
import { format } from '@src/utils/date.utils';
import Table from '../../components/Table';
import { Context } from '../../Context';
import Button from '../../components/Button';
import { A } from '../../components/LayoutBase';
import { HTML, CircleIcon } from '../../components/Layout';
import DropdownSelect from '../../components/DropdownSelect';

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 1.5em;
  top: 50%;
  color: ${colors.fieldIcon};
  line-height: 0;
  font-size: 0.8em;
  pointer-events: none;
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0 0.5em;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  right: 0.2em;
  color: ${colors.fieldIcon};
  cursor: pointer;
  font-size: 0.8em;
`;

const DropdownMenu = styled(Column)`
  margin-top: 18px;
  padding: 8px;
  background: #fff;
  border: 1px solid ${colors.cellBorder};
  width: 400px;
  max-height: 400px;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  ${({ displayOnTop }) =>
    displayOnTop
      ? css`
          position: relative;
          bottom: 473px;
          height: 493px;
        `
      : ''}
`;

const DropdownArrow = styled.div`
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  top: 0;
  left: 40px;
  margin-top: -8px;
  border-top: none;
  border-bottom-color: ${colors.cellBorder};

  ::after {
    content: '';
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    margin-top: 1px;
    left: -7px;
    border-top: none;
    border-bottom-color: #fff;
  }
`;

const DropupArrow = styled.div`
  && {
    position: absolute;
    box-sizing: content-box;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    top: -47px;
    left: 40px;
    margin-top: -8px;
    border-bottom: none;
    border-top-color: ${colors.cellBorder};
    ::after {
      content: '';
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      margin-top: 1px;
      bottom: 2px;
      left: -7px;
      border-bottom: none;
      border-top-color: #fff;
    }
  }
`;

const ResultsList = styled(Column)`
  max-height: 40vh;
  overflow-y: auto;
`;

const Option = styled(Cell)`
  border-radius: 6px;
  padding: 14px;

  ${({ selected }) =>
    selected &&
    `
      background: ${colors.link};
      color: #fff;
  `}

  :hover {
    cursor: pointer;
  }
`;

const OptionFritekst = styled(Cell)`
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 14px;

  ${({ selected }) =>
    selected &&
    `
      border: 1px solid ${colors.link};
  `}
`;

const FilterLabel = styled.label`
  display: block;
  position: relative;
  ${({ text }) => text && 'cursor: text;'}
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 40%;
    cursor: inherit !important;
  `}

  ${Label} {
    font-size: 0.9em;
    font-style: italic;
    pointer-events: none;
    user-select: none;
    color: ${colors.fontLight};
  }
`;

const StyledFilterInput = styled.input`
  width: 100%;
  line-height: 1.5;
  margin-top: 0.2em;
  border: 0;
  font-weight: ${({ value }) => (value ? 'bold' : 'normal')};
  appearance: none;
  padding-right: 1.4em;

  ::placeholder {
    font-weight: normal;
  }
`;

const FilterIcon = styled.div`
  position: absolute;
  right: 0;
  text-align: right;
  line-height: 0;
  top: 50%;
  color: ${colors.fontLight};
  font-size: 0.8em;
`;

const ResultsTable = styled(Table)`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const FilterInput = ({ onChange, onClear, ...props }) => {
  const [value, setValue] = useState();

  return (
    <div
      css="
      position: relative;
      width: 100%;
    ">
      <StyledFilterInput
        value={value}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value);
          }
          setValue(e.target.value);
        }}
        {...props}
      />
      {value && onClear && (
        <ClearButton onClick={onClear}>
          <Icon className="fa fa-times" />
        </ClearButton>
      )}
    </div>
  );
};

const FilterSelect = styled(DropdownSelect)`
  width: 100%;
  line-height: 1.5;
  margin-top: 0.2em;
  border: 0;
  font-weight: ${({ value }) => (value ? 'bold' : 'normal')};
  appearance: none;

  ::placeholder {
    font-weight: normal;
  }

  padding: 0;
  border-radius: 0;
  margin-bottom: 0;

  &,
  :focus {
    border: none;
    box-shadow: none;
  }
`;

const FilterDatepicker = styled(DatePicker)`
  width: 100%;
  line-height: 1.5;
  margin-top: 0.2em;
  border: 0;
  font-weight: bold;
  appearance: none;

  ::placeholder {
    font-weight: normal;
  }

  padding: 0;
  border-radius: 0;
  margin-bottom: 0;

  &,
  :focus {
    border: none;
    box-shadow: none;
  }
`;

const FilterResetButton = styled(A)`
  cursor: pointer !important;
  text-decoration: none !important;
  text-transform: uppercase;
`;

const tabs = Object.freeze({
  competences: Symbol('competences'),
  courses: Symbol('courses'),
});

const CourseSearch = ({
  name,
  error: errorProp,
  onChange,
  displayCoursesResultsOnTop,
  ...props
}) => {
  const [menuOpen, setMenuOpen] = useState(true);

  const [advancedModalOpen, setAdvancedModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs.competences);
  const [previewCourseId, setPreviewCourseId] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [filterCoursegroup, setFilterCoursegroup] = useState(null);
  const [filterCoursetype, setFilterCoursetype] = useState(null);
  const [filterStartdate, setFilterStartdate] = useState(null);
  const [filterEnddate, setFilterEnddate] = useState(null);
  const [filterLocation, setFilterLocation] = useState(null);

  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [fieldValue, setFieldValue] = useState(defaultValue);
  const [inputText, setInputText] = useState(
    typeof fieldValue === 'string' ? fieldValue : ''
  );
  const hasSelection = fieldValue && typeof fieldValue !== 'string';

  const debouncedSearch = useDebounce(inputText, 700);

  const {
    data: { competences: resultsCompetences },
    loading: loadingCompetences,
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        children: 1,
        'types[]': filterCoursetype || 'complex,ecourse,equivalents,course',
        'competence_group_ids[]': filterCoursegroup,
        view: 'full',
        fields:
          'id,title,competence_type,competence_type_id,competence_group_ids',
        term: debouncedSearch,
        limit: 501,
      },
      url: `${backendUrl}/api/competences`,
      withCredentials: true,
    },
    [debouncedSearch, filterCoursetype, filterCoursegroup]
  );

  const {
    data: { events: resultsCourses },
    loading: loadingCourses,
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        view: 'full',
        fields: 'title,startdate,competence_id,enddate,location',
        startdate: format(filterStartdate, 'dd.MM.yyyy HH:mm'),
        enddate: format(filterEnddate, 'dd.MM.yyyy HH:mm'),
        location_id:
          (filterStartdate && filterEnddate && filterLocation) || null,
        // term: debouncedSearch,
        limit: 501,
      },
      url: `${backendUrl}/api/events`,
      withCredentials: true,
    },
    [/* debouncedSearch, */ filterStartdate, filterEnddate, filterLocation]
  );

  const {
    data: { competences: previewCourse },
    loading: previewCourseLoading,
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        fields:
          'description,files,title,course_type,competence_type_id,competence_type,durations,children(competence_type,description,competence_type_id,title,competence_id,files)',
        view: 'full',
        children: '1',
      },
      url: `${backendUrl}/api/competences/${previewCourseId}`,
      withCredentials: true,
    },
    [previewCourseId],
    previewCourseId
  ) || { data: { competences: [null] }, loading: false };

  const {
    data: { events: previewCourseEvents },
    loading: previewCourseEventsLoading,
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        fields: 'title,startdate,competence_id,enddate,description,location',
        view: 'full',
        children: '1',
      },
      url: `${backendUrl}/api/competences/${previewCourseId}/events`,
      withCredentials: true,
    },
    [previewCourseId],
    previewCourseId
  );

  const loading = loadingCompetences || loadingCourses;

  const {
    data: { locations: loctionsData },
  } = useAxiosRequest(
    {
      method: 'GET',
      params: {
        startdate: format(filterStartdate, 'dd.MM.yyyy HH:mm'),
        enddate: format(filterEnddate, 'dd.MM.yyyy HH:mm'),
        fields: 'title,id',
      },
      url: `${backendUrl}/api/locations`,
      withCredentials: true,
    },
    [filterStartdate, filterEnddate]
  );

  const locations =
    (loctionsData &&
      loctionsData.reduce(
        (obj, { id, title }) => ({ ...obj, [id]: title }),
        {}
      )) ||
    {};

  const resultsView = expanded
    ? (Array.isArray(resultsCompetences) && resultsCompetences) || []
    : (Array.isArray(resultsCompetences) && resultsCompetences.slice(0, 5)) ||
      [];
  const showExpand =
    !expanded &&
    Array.isArray(resultsCompetences) &&
    resultsCompetences.length > 5;

  const setCourse = data => {
    setFieldValue(data);
    setAdvancedModalOpen(false);
    setPreviewCourseId(null);
    setMenuOpen(false);
    setSelectedIndex(0);
  };

  const setValue = (index, isCourse) => {
    let data;
    if (isCourse) {
      data = resultsCourses[index];
    } else {
      data = resultsCompetences[index];
    }
    setCourse(data);
  };

  const clear = () => {
    if (ref.current) {
      ref.current.focus();
    }
    setFieldValue('');
    setInputText('');
  };

  const resetFilters = () => {
    setInputText('');
    setFilterCoursegroup(null);
    setFilterCoursetype(null);
    setFilterStartdate(null);
    setFilterEnddate(null);
    setFilterLocation(null);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    if (selectedIndex > 5) {
      setExpanded(true);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (onChange) {
      onChange(fieldValue);
    }
    if (hasSelection) {
      setInputText((fieldValue && fieldValue.title) || '');
    } else {
      setInputText(fieldValue || '');
    }
  }, [fieldValue]);

  useEffect(() => {
    if (!advancedModalOpen) {
      setFilterCoursegroup(null);
      setFilterCoursetype(null);
      setSelectedTab(tabs.competences);
    }
  }, [advancedModalOpen]);

  const onKeyUp = useCallback(
    e => {
      if (!menuOpen || advancedModalOpen) {
        return true;
      }
      if (e.code === 'Enter') {
        if (inputText && selectedIndex === 0) {
          setMenuOpen(false);
        } else {
          setValue(selectedIndex - 1);
        }
        return false;
      }
      if (e.code === 'ArrowUp') {
        setSelectedIndex(Math.max(inputText ? 0 : 1, selectedIndex - 1));
        return false;
      }
      if (e.code === 'ArrowDown') {
        setSelectedIndex(
          Math.min(
            selectedIndex + 1,
            resultsCompetences ? resultsCompetences.length : 0
          )
        );
        return false;
      }
      return true;
    },
    [resultsCompetences, selectedIndex]
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  const optionProps = key => ({
    ref: current =>
      current &&
      selectedIndex === key &&
      scrollIntoView(current, {
        scrollMode: 'if-needed',
      }),
    selected: selectedIndex === key,
    onMouseOver: () => setSelectedIndex(key),
    onFocus: () => setSelectedIndex(key),
  });

  const { competencegroups, competencetypes } = useContext(Context);

  const CompetenceRow = ({
    index,
    competence: { id, title, competence_type, competence_group_ids },
    competence,
  }) => {
    const [showButton, setShowButton] = useState(false);
    return (
      <tr
        onMouseOver={() => setShowButton(true)}
        onFocus={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
        onBlur={() => setShowButton(false)}>
        <td>
          <LinkLabel
            color={colors.font}
            stealth
            onClick={() => setPreviewCourseId(id)}>
            {title}
            <Label inline size="0.5em">
              <CircleIcon
                background="#ccc"
                marginLeft="1em"
                className="fa fa-info"
              />
            </Label>
          </LinkLabel>
        </td>
        <td>{competence_type}</td>
        <td css=":after { display: none !important; }">
          {competence_group_ids &&
            competence_group_ids
              .map(id => competencegroups[id])
              .filter(x => x)
              .join(', ')}
        </td>
        <td width="120px" height="74px">
          {showButton && (
            <Button onClick={() => setCourse(competence)} small alternative>
              Legg til
            </Button>
          )}
        </td>
      </tr>
    );
  };

  CompetenceRow.propTypes = {
    index: PropTypes.number.isRequired,
    competence: PropTypes.shape({
      title: PropTypes.string,
      competence_type: PropTypes.string,
      competence_group_ids: PropTypes.array,
    }).isRequired,
  };

  const CourseRow = ({
    index,
    course: {
      competence_id,
      title,
      startdate,
      enddate,
      location: { title: location },
    },
    course,
    simple,
  }) => {
    const [showButton, setShowButton] = useState(false);
    return (
      <tr
        onMouseOver={() => setShowButton(true)}
        onFocus={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
        onBlur={() => setShowButton(false)}>
        {!simple && (
          <>
            <td>
              <LinkLabel
                color={colors.font}
                onClick={() => setPreviewCourseId(competence_id)}>
                {title}
                <Label inline size="0.5em">
                  <CircleIcon
                    background="#ccc"
                    marginLeft="1em"
                    className="fa fa-info"
                  />
                </Label>
              </LinkLabel>
            </td>
          </>
        )}
        <td>{location}</td>
        <td data-sort={startdate || null}>
          {startdate && format(new Date(startdate), "d. MMMM yyyy 'kl.' HH:mm")}
        </td>
        <td
          data-sort={enddate || null}
          css=":after { display: none !important; }">
          {enddate && format(new Date(enddate), "d. MMMM yyyy 'kl.' HH:mm")}
        </td>
        <td width="120px" height="74px">
          {showButton && (
            <Button onClick={() => setCourse(course)} small alternative>
              Legg til
            </Button>
          )}
        </td>
      </tr>
    );
  };

  CourseRow.propTypes = {
    index: PropTypes.number.isRequired,
    course: PropTypes.shape({}).isRequired,
    simple: PropTypes.bool,
  };

  CourseRow.defaultProps = {
    simple: false,
  };

  return (
    <>
      <Input
        {...props}
        css={`
          padding-left: 3em;
          padding-right: 1.4em;
          min-width: 130px;
          color: ${hasSelection ? colors.link : colors.font};
        `}
        name={`${fieldName}__select`}
        error={error || errorProp}
        value={inputText}
        autoComplete="off"
        onChange={text => {
          if (!hasSelection) {
            setFieldValue(text);
            setSelectedIndex(text ? 0 : 1);
            setMenuOpen(true);
          } else if (text.length < inputText.length) {
            clear();
          }
        }}
        dropdown={
          menuOpen &&
          !hasSelection && (
            <>
              <DropdownMenu
                ref={current =>
                  current &&
                  scrollIntoView(current, {
                    scrollMode: 'if-needed',
                  })
                }
                displayOnTop={displayCoursesResultsOnTop}
                tabIndex="-1">
                {!displayCoursesResultsOnTop && <DropdownArrow />}
                <Row padding="14px">
                  <Cell grow />
                  <Cell>
                    <LinkLabel onClick={() => setAdvancedModalOpen(true)}>
                      <Icon marginRight="0.5em" className="fa fa-sliders-h" />
                      Full visning
                    </LinkLabel>
                  </Cell>
                </Row>
                <ResultsList>
                  {inputText && (
                    <OptionFritekst
                      role="button"
                      {...optionProps(0)}
                      {...propsUtils.onClick(() => setMenuOpen(false))}>
                      <Label lineHeight="1.4">{inputText}</Label>
                      <Label lineHeight="1.4" size="0.9em" italic>
                        Fritekst
                      </Label>
                    </OptionFritekst>
                  )}
                  {Array.isArray(resultsView) &&
                    resultsView.map(({ title, competence_type }, key) => (
                      <Option
                        role="button"
                        {...optionProps(key + 1)}
                        {...propsUtils.onClick(() => setValue(key))}
                        key={key}>
                        <Label lineHeight="1.4">{title}</Label>
                        <Label lineHeight="1.4" size="0.9em">
                          {competence_type}
                        </Label>
                      </Option>
                    ))}
                </ResultsList>
                {(loading && (
                  <Cell padding="14px">
                    <Label color="inherit" size="0.9em">
                      <Icon
                        size="0.8em"
                        marginRight="0.5em"
                        className="fa fa-spin fa-spinner"
                      />
                      Laster...
                    </Label>
                  </Cell>
                )) ||
                  (showExpand && (
                    <Cell padding="14px">
                      <LinkLabel
                        onClick={() => setExpanded(true)}
                        color="inherit"
                        size="0.9em">
                        Vis mer...
                      </LinkLabel>
                    </Cell>
                  ))}
              </DropdownMenu>
              {displayCoursesResultsOnTop && <DropupArrow />}
            </>
          )
        }>
        <SearchIcon className="fa fa-search" />
        {inputText && (
          <ClearButton onClick={clear}>
            <Icon className="fa fa-times" />
          </ClearButton>
        )}
      </Input>
      <input ref={ref} type="hidden" name={fieldName} value={fieldValue} />
      {advancedModalOpen && (
        <Modal
          width="1200px"
          height="100%"
          closable
          onClose={() => setAdvancedModalOpen(false)}>
          <Column
            css={css`
              overflow: hidden;
            `}
            grow>
            <Label size="1.5em" marginBottom="1em">
              Full visning
            </Label>
            <Tabs
              marginBottom="2em"
              value={selectedTab}
              onChange={setSelectedTab}>
              <Tab value={tabs.competences}>Kompetanser</Tab>
              <Tab value={tabs.courses}>Kurs</Tab>
            </Tabs>
            {selectedTab === tabs.competences ? (
              <Table border>
                <tr>
                  <td>
                    <FilterLabel text>
                      <Label>Filtrer etter kompetanse</Label>
                      <FilterInput
                        type="text"
                        placeholder="Kompetanse"
                        value={inputText}
                        onChange={setInputText}
                        onClear={clear}
                      />
                    </FilterLabel>
                  </td>
                  {competencetypes && (
                    <td>
                      <FilterLabel>
                        <Label>Filtrer etter type</Label>
                        <FilterSelect
                          name="filter_competencetypes"
                          options={[null, ...Object.keys(competencetypes)]}
                          disableTyping
                          formatValue={option =>
                            competencetypes[option] ||
                            (option === null && 'Kurs')
                          }
                          value={filterCoursetype}
                          onChange={setFilterCoursetype}
                        />
                        <FilterIcon className="fa fa-sort" />
                      </FilterLabel>
                    </td>
                  )}
                  {competencegroups && (
                    <td>
                      <FilterLabel>
                        <Label>Filtrer etter gruppe</Label>
                        <FilterSelect
                          name="filter_competencegroups"
                          disableTyping
                          options={[null, ...Object.keys(competencegroups)]}
                          formatValue={option =>
                            competencegroups[option] ||
                            (option === null && 'Alle kompetansegrupper')
                          }
                          value={filterCoursegroup}
                          onChange={setFilterCoursegroup}
                        />
                        <FilterIcon className="fa fa-sort" />
                      </FilterLabel>
                    </td>
                  )}
                  <td>
                    <FilterResetButton onClick={() => resetFilters()}>
                      <Cell grow>
                        <Cell margin="auto">
                          <Label size="0.9em">
                            <Icon marginRight="1em" className="fa fa-undo" />
                            Nullstill
                          </Label>
                        </Cell>
                      </Cell>
                    </FilterResetButton>
                  </td>
                </tr>
              </Table>
            ) : (
              <Table border>
                <tr>
                  {/*
                  <td>
                    <FilterLabel text>
                      <Label>Filtrer etter kurs</Label>
                      <FilterInput
                        type="text"
                        placeholder="Kurs"
                        value={inputText}
                        onChange={setInputText}
                        onClear={clear}
                      />
                    </FilterLabel>
                  </td>
                  */}
                  <td>
                    <FilterLabel
                      disabled={!filterStartdate || !filterEnddate}
                      title={
                        (!filterStartdate || !filterEnddate) &&
                        'Velg startdato og sluttdato for å velge lokasjon'
                      }>
                      <Label>Filtrer etter lokasjon</Label>
                      <FilterSelect
                        name="filter_location"
                        options={[null, ...Object.keys(locations)]}
                        formatValue={option =>
                          locations[option] ||
                          (option === null && 'Alle lokasjoner')
                        }
                        value={filterLocation}
                        onChange={setFilterLocation}
                        disabled={!filterStartdate || !filterEnddate}
                      />
                      <FilterIcon className="fa fa-sort" />
                    </FilterLabel>
                  </td>
                  <td>
                    <FilterLabel>
                      <Label>Filtrer etter startdato</Label>
                      <FilterDatepicker
                        type="text"
                        simple
                        placeholder="Velg startdato"
                        name="filter_startdate"
                        value={filterStartdate}
                        onChange={setFilterStartdate}
                      />
                      <FilterIcon className="fa fa-calendar" />
                    </FilterLabel>
                  </td>
                  <td>
                    <FilterLabel>
                      <Label>Filtrer etter sluttdato</Label>
                      <FilterDatepicker
                        type="text"
                        simple
                        placeholder="Velg sluttdato"
                        name="filter_enddate"
                        value={filterEnddate}
                        onChange={setFilterEnddate}
                      />
                      <FilterIcon className="fa fa-calendar" />
                    </FilterLabel>
                  </td>
                  <td>
                    <FilterResetButton onClick={() => resetFilters()}>
                      <Cell grow>
                        <Cell margin="auto">
                          <Label size="0.9em">
                            <Icon marginRight="1em" className="fa fa-undo" />
                            Nullstill
                          </Label>
                        </Cell>
                      </Cell>
                    </FilterResetButton>
                  </td>
                </tr>
              </Table>
            )}
            {(loading && (
              <Label marginTop="1em" marginBottom="2em">
                <Icon
                  size="0.8em"
                  marginRight="0.5em"
                  className="fa fa-spin fa-spinner"
                />
                Laster...
              </Label>
            )) || (
              <Label marginTop="1em" marginBottom="2em">
                Søket returnerte{' '}
                {selectedTab === tabs.competences ? (
                  <Label inline bold>
                    {resultsCompetences ? resultsCompetences.length : 0}
                  </Label>
                ) : (
                  <Label inline bold>
                    {resultsCourses ? resultsCourses.length : 0}
                  </Label>
                )}{' '}
                treff.
              </Label>
            )}

            {selectedTab === tabs.competences ? (
              <ResultsTable
                highlightOddRows
                sortable={false}
                headings={['Kompetanse', 'Type', 'Kompetansegruppe', null]}>
                {resultsCompetences &&
                  resultsCompetences.map((competence, key) => (
                    <CompetenceRow
                      competence={competence}
                      index={key}
                      key={key}
                    />
                  ))}
              </ResultsTable>
            ) : (
              <ResultsTable
                highlightOddRows
                sortable={false}
                headings={['Kurs', 'Lokasjon', 'Start', 'Slutt', null]}>
                {resultsCourses &&
                  resultsCourses.map((course, key) => (
                    <CourseRow course={course} index={key} key={key} />
                  ))}
              </ResultsTable>
            )}
          </Column>
        </Modal>
      )}
      {previewCourseId && (
        <Modal
          width={!previewCourseLoading && '900px'}
          closable
          onClose={() => setPreviewCourseId(null)}>
          {previewCourseLoading ? (
            <Cell>
              <Label margin="auto" lineHeight="1em">
                <Icon className="fa fa-spin fa-spinner" />
              </Label>
            </Cell>
          ) : (
            (({
              title,
              startdate,
              competence_id,
              competence_type,
              enddate,
              description,
              location,
            }) => (
              <Column
                css={css`
                  overflow: hidden;
                `}>
                <Label size="1.5em" marginBottom="0.5em">
                  <Label bold inline marginRight="1em">
                    {title}
                  </Label>
                  <Label inline>{competence_type}</Label>
                </Label>
                <Label>
                  <HTML>{description}</HTML>
                </Label>
                {previewCourseEvents && !!previewCourseEvents.length && (
                  <ResultsTable
                    marginTop="1em"
                    highlightOddRows
                    sortable={false}
                    headings={['Lokasjon', 'Start', 'Slutt', null]}>
                    {previewCourseEvents.map((course, key) => (
                      <CourseRow simple course={course} index={key} key={key} />
                    ))}
                  </ResultsTable>
                )}
              </Column>
            ))(previewCourse[0])
          )}
        </Modal>
      )}
    </>
  );
};

CourseSearch.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

CourseSearch.defaultProps = {
  onChange: null,
  error: null,
};

export default CourseSearch;
