import {
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_SHOW,
  NOTIFICATIONS_HIDE,
} from '@actions/notifications.actions';

const initialState = {
  shown: [],
  hidden: [],
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case NOTIFICATIONS_ADD: {
      const { notification } = action.payload;
      return {
        ...state,
        shown: [...state.shown, notification],
      };
    }
    case NOTIFICATIONS_SHOW: {
      const { notification } = action.payload;
      return {
        ...state,
        shown: [...state.shown, notification],
        hidden: [
          ...state.hidden,
          notifications.filter(({ id }) => id !== notification.id),
        ],
      };
    }
    case NOTIFICATIONS_HIDE: {
      const { notification } = action.payload;
      return {
        ...state,
        shown: [...state.shown.filter(({ id }) => id !== notification.id)],
        hidden: [...state.hidden, notification],
      };
    }
    default:
      return state;
  }
};

export default notifications;
