import { number, string, shape, arrayOf, oneOf } from 'prop-types';

const personInfoShape = () =>
  shape({
    code: string,
    deactivated: number,
    sex: string,
    postcode: string,
    email2: string,
    extern_person_id: null,
    city: string,
    user_id: number,
    employee_code: shape,
    ts: string,
    source: string,
    communication: shape,
    person_id: number,
    email: string,
    notice: string,
    firstname: string,
    impersonated_by: shape,
    lastname: string,
    nationality_country_country_id: shape,
    country_country_id: shape,
    mobile: string,
    address: string,
    data: shape,
    language_language_id: string,
    birthdate: string,
    points: number,
    jobtitle: string,
  });
const experienceShape = () =>
  shape({
    startdate: string,
    et_experience_type_id: number,
    enddate: string,
    name: string,
    designation: string,
    created: string,
    deactivated: number,
    external_experience_id: shape,
    person_person_id: number,
    experience_type: oneOf([
      'project',
      'language',
      'elected',
      'work',
      'education',
    ]),
    edited: string,
    experience_id: number,
    contact_person: string,
    external_ts: shape,
    description: string,
  });

export const CVShape = () =>
  shape({
    me: personInfoShape,
    id: number,
    description: string,
    /* Work, education, projects and languages */
    experiences: arrayOf(experienceShape),
  });
