export default {
  font: '#0a0a0a',
  fontLight: '#515254',
  link: '#1573E6',
  cellBorder: '#eaebed',
  warn: '#f33535',
  fieldIcon: '#777777',
  oddBackground: '#fefefe',
  blue: '#0a62cd',
};
