import { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { condition } from '../utils/logic.utils';

export const useOnClickOutside = (callback, refs) => {
  const listener = event => {
    const forAny = fn =>
      Array.isArray(refs)
        ? refs.reduce(
            (bool, ref) => !!(bool || (ref && ref.current && fn(ref.current))),
            false
          )
        : refs && refs.current && fn(refs.current);
    if (
      !forAny(current => {
        return current.contains(event.target);
      })
    ) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener, callback, ...refs]);
};

export const useOnVisibilityChange = callback => {
  useEffect(() => {
    window.addEventListener('DOMContentLoaded', callback);
    window.addEventListener('load', callback);
    window.addEventListener('orientationchange', callback);
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('DOMContentLoaded', callback);
      window.removeEventListener('load', callback);
      window.removeEventListener('orientationchange', callback);
      window.removeEventListener('resize', callback);
    };
  }, [callback]);
};

export const useMediaQuery = query => {
  const [mq, setMq] = useState(window.matchMedia(query));

  useEffect(() => {
    const mq = window.matchMedia(query);
    mq.addListener(setMq);
    return () => {
      mq.removeListener(setMq);
    };
  }, []);

  return mq;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebug = (...params) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(...params);
  }, [...params]);
};

export const useAxiosRequest = (config, deps, conditional) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (condition(conditional)) {
      let unmounted = false;
      const source = axios.CancelToken.source();
      setLoading(true);
      axios
        .request(config)
        .then(a => {
          if (!unmounted) {
            setData(a.data);
            setLoading(false);
          }
        })
        .catch(error => {
          if (!unmounted) {
            setError(error);
            setLoading(false);
          }
        });
      return () => {
        unmounted = true;
        source.cancel();
      };
    }
    return undefined;
  }, [...(deps || [])]);

  return { data, loading, error };
};

export const useEasterEgg = (keyword, callback) => {
  const lettersTyped = useRef([]);

  const onKeyPress = useCallback(
    event => {
      if (event.target.tagName !== 'BODY') {
        return;
      }
      const char = event.which || event.keyCode;
      lettersTyped.current.push(String.fromCharCode(char));
      if (!keyword.startsWith(lettersTyped.current.join(''))) {
        lettersTyped.current.length = 0;
      }
      if (lettersTyped.current.join('') === keyword) {
        callback();
        lettersTyped.current.length = 0;
      }
    },
    [keyword, callback]
  );

  useEffect(() => {
    window.addEventListener(
      'keypress',
      onKeyPress,
      { capture: false, passive: true },
      false
    );

    return () => {
      window.removeEventListener(
        'keypress',
        onKeyPress,
        { capture: false, passive: true },
        false
      );
    };
  }, [onKeyPress]);
};

export const useStateLocalStorage = (key, initialState) => {
  const [state, setState] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem(key) || initialState);
    } catch (error) {
      console.error(error, 'occurred when getting value for', key);
      return initialState;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.error(error, 'occurred when storing value for', key);
    }
  }, [key, state]);

  return [state, setState];
};
