import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Seperator } from '@src/components/Layout';
import Button from '@src/components/Button';
import { Form } from '@rocketseat/unform';
import { emptyDate } from '@src/utils/date.utils';

const [viewMode, editMode, addMode] = ['viewMode', 'editMode', 'addMode'];

/* Adding and changing of cv-entities of a specific type(eg education) */
const CVEditExperienceType = ({
  title,
  description,
  experienceListComponent,
  manageExperienceFormElementsComponent,
  addNewBtnTxt,
  formSchema,
  onAlteredData,
  experienceKind,
  initialExperienceData,
}) => {
  const Header = styled.h3`
    color: #494949;
    font-weight: 500;
    margin-top: 0;
  `;
  const Description = styled.p`
    width: 60%;
  `;

  const [manageMode, setManageMode] = useState(viewMode);
  const [experienceData, setExperienceData] = useState(initialExperienceData);
  const [experienceUIDtoEdit, setExperienceUIDtoEdit] = useState(null);
  const Wrapper = styled.div`
    border: 1px solid #e5e5e5;
    margin-bottom: 60px;
    padding: 25px;
  `;

  const enterAddMode = () => {
    setManageMode(addMode);
  };

  const enterEditMode = experienceUID => {
    setManageMode(editMode);
    setExperienceUIDtoEdit(experienceUID);
  };

  const getExperienceByUID = UID => {
    return experienceData.find(elem => elem.UID === UID);
  };

  const getIndexOfExperienceByUID = UID =>
    experienceData.findIndex(elem => elem.UID === UID);

  const handleDate = date => {
    const isYearOnly = !/[0-9]{4}/.test(date);
    if (isYearOnly) {
      const d = emptyDate();
      d.setFullYear(date);
      return d;
    }
    // is full date
    return new Date(date);
  };

  const onManageExperienceSubmit = formData => {
    let copyOfExperienceData;
    if (manageMode === editMode) {
      const experienceToEdit = getExperienceByUID(experienceUIDtoEdit);
      if (experienceToEdit) {
        const editedExperience = { ...experienceToEdit, ...formData };

        if (formData.startdate) {
          editedExperience.startdate = handleDate(formData.startdate);
        } else if (formData.enddate) {
          editedExperience.enddate = handleDate(formData.enddate);
        }

        copyOfExperienceData = experienceData;
        copyOfExperienceData[
          getIndexOfExperienceByUID(experienceUIDtoEdit)
        ] = editedExperience;
      }
    } else if (manageMode === addMode) {
      const defaultData = {
        contact_person: '',
        experience_id: '',
        experience_type: experienceKind,
      };
      copyOfExperienceData = Array.from(experienceData);
      const newEntity = { ...defaultData, ...formData };

      if (formData.startdate) {
        newEntity.startdate = handleDate(formData.startdate);
      } else if (formData.enddate) {
        newEntity.enddate = handleDate(formData.enddate);
      }

      copyOfExperienceData.push(newEntity);
    }
    setExperienceData(copyOfExperienceData);
    onAlteredData(experienceKind, copyOfExperienceData);
    setManageMode(viewMode);
    setExperienceUIDtoEdit(null);
  };

  const onManageExperienceDelete = () => {
    const indexToDelete = experienceData.findIndex(
      e => e.UID === experienceUIDtoEdit
    );

    if (indexToDelete >= 0) {
      const copyOfExperienceData = experienceData;
      copyOfExperienceData.splice(indexToDelete, 1);
      setExperienceData(copyOfExperienceData);
      onAlteredData(experienceKind, copyOfExperienceData);
      setManageMode(viewMode);
      setExperienceUIDtoEdit(null);
    }
  };

  const onManageExperienceCancel = () => {
    setManageMode(viewMode);
    setManageMode(viewMode);
  };

  const AddNewBtn = styled.button`
    cursor: pointer;
    color: #1573e6;
    margin-right: 15px;
    margin: 30px 0;
    display: flex;
    align-items: center;
  `;

  const AddNewBtnContent = styled.div`
    margin-bottom: 2px;
  `;

  const AddNewIcon = styled.i`
    ::after {
      content: '+';
    }

    font-size: 1.5em;
    background-color: #f4f8fc;
    border: 1px solid #ceddeb;
    font-style: initial;
    color: #1573e6;
    padding: 5px 15px;
    border-radius: 3px;
    margin-right: 20px;
    padding-bottom: 7px;
  `;

  return (
    <Wrapper>
      <Header>{title}</Header>
      {description && (
        <>
          <Seperator />
          <Description>{description}</Description>
        </>
      )}
      <Seperator />
      {manageMode === viewMode && (
        <AddNewBtn onClick={enterAddMode}>
          <AddNewIcon />
          <AddNewBtnContent>{addNewBtnTxt}</AddNewBtnContent>
        </AddNewBtn>
      )}

      {manageMode === addMode && (
        <ManageExperience
          mode={addMode}
          title={addNewBtnTxt}
          onSubmit={onManageExperienceSubmit}
          formSchema={formSchema}
          formElements={manageExperienceFormElementsComponent}
          onCancel={onManageExperienceCancel}
        />
      )}
      {manageMode === editMode && (
        <ManageExperience
          mode={editMode}
          title={`Endre ${title.toLowerCase()}`}
          onSubmit={onManageExperienceSubmit}
          onDelete={onManageExperienceDelete}
          formSchema={formSchema}
          formElements={manageExperienceFormElementsComponent}
          initialFormData={getExperienceByUID(experienceUIDtoEdit)}
          onCancel={onManageExperienceCancel}
        />
      )}
      <Seperator />
      {React.createElement(experienceListComponent, {
        displayEditBtn: true,
        data: experienceData,
        onEditClick: experienceUID => {
          enterEditMode(experienceUID);
        },
      })}
    </Wrapper>
  );
};

const ManageExperience = ({
  title,
  mode,
  formElements,
  onCancel,
  onSubmit,
  onDelete,
  initialFormData,
  formSchema,
}) => {
  const Header = styled.h4`
    color: #494949;
    font-weight: 450;
    margin-bottom: 7px;
    font-size: 1.025em;
  `;

  const Wrapper = styled.div``;
  console.log('initial form data');
  console.log(initialFormData);

  Wrapper.propTypes = {
    openedWrapper: PropTypes.bool,
  };

  const FormHelp = styled.p`
    margin-top: 0;
    font-size: 0.8em;
    max-height: 200px;
  `;

  const CancelButton = styled.button`
    &:hover {
      cursor: pointer;
    }
  `;

  const DeleteButton = styled(Button)`
    background-color: #e0383e;
    float: right;
    border: none;
    :hover,
    :active,
    :hover:active {
      background-color: #cc2a30;
    }
  `;

  const ActionButtonsWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
  `;

  const StyledForm = styled(Form)`
    label {
      span:nth-child(2) {
        color: red; /*Error messages*/
      }
    }
  `;

  return (
    <Wrapper>
      <Header>{title}</Header>
      <FormHelp>* må fylles ut</FormHelp>
      <StyledForm
        initialData={initialFormData}
        autofill="off"
        autoComplete="off"
        schema={formSchema}
        onSubmit={(data, { resetForm }) => {
          onSubmit(data);
          resetForm();
          return true;
        }}>
        {React.createElement(formElements, {})}
        <ActionButtonsWrapper>
          <Button type="submit" action small marginRight="10px">
            {mode === editMode ? 'Endre' : 'Legg til'}
          </Button>
          <CancelButton type="button" onClick={onCancel}>
            Avbryt
          </CancelButton>
          {mode === editMode && (
            <DeleteButton type="button" action small onClick={onDelete}>
              Fjern erfaring
            </DeleteButton>
          )}
        </ActionButtonsWrapper>
      </StyledForm>
    </Wrapper>
  );
};

ManageExperience.propTypes = {
  title: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([editMode, addMode]).isRequired,
  formElements: PropTypes.element.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  initialFormData: PropTypes.shape({}),
};

ManageExperience.defaultProps = {
  formSchema: null,
  initialFormData: {},
};

export default CVEditExperienceType;

CVEditExperienceType.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  addNewBtnTxt: PropTypes.string.isRequired,
  experienceListComponent: PropTypes.element.isRequired,
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manageExperienceFormElementsComponent: PropTypes.element.isRequired,
  onAlteredData: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  experienceKind: PropTypes.string.isRequired,
};

CVEditExperienceType.defaultProps = {
  formSchema: null,
};
