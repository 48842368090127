import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@src/styles/index';
import Button from './Button';
import { Column } from './Layout';

export const OKButtonLarge = styled(Button)`
  flex: 1 1 auto;
  margin: -40px;
  padding: 20px 0;
  margin-top: 0;
  border: none;
  border-top: 1px solid ${colors.cellBorder};
  background: transparent;
  border-radius: 0;
  color: ${colors.link};
`;

export const ModalHeader = styled(Column)`
  flex: 1 1 auto;
  margin: -40px;
  margin-bottom: 0;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 500;
  display: flex;
  align-items: center;
  ${({ expanded }) => !expanded && 'padding: 40px;'}
  overflow: hidden;
`;

const StyledModal = styled(Column.withComponent('dialog'))`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ expanded, height, width }) =>
    expanded
      ? css`
          margin: 0;
          border-radius: 0;
          ${ModalHeader} {
            border-radius: 0;
          }
        `
      : css`
          max-height: ${height || '100%'};
          max-width: ${width || '100%'};
          height: ${height || 'fit-content'};
          width: ${width || 'fit-content'};
          margin: auto;
          border-radius: 15px;
        `}
  padding: 40px;
  background: #fff;
  border: 1px solid ${colors.cellBorder};
  transition: width 0.15s ease-out, height 0.15s ease-out;
  overflow: visible;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  font-weight: 550;
  color: #555555;
  padding: 4px;
  border-radius: 3px;
  z-index: 2;
  border: 1px solid transparent;
  transition: border 0.085s ease-in, color 0.085s ease-in;

  ::after {
    content: '✕';
  }
  :hover {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #1573e6;
  }
`;

const ModalExpandButton = styled.button`
  position: absolute;
  top: 12px;
  right: 40px;
  color: #555555;
  padding: 4px;
  border-radius: 3px;
  z-index: 2;
  border: 1px solid transparent;
  transition: border 0.115s ease-in, color 0.115s ease-in;

  :hover {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #1573e6;
  }
`;

let instancesCount = 0;

const updateBodyScroll = () => {
  const { style: htmlStyle } = document.documentElement;
  const { style: bodyStyle } = document.body;
  if (instancesCount) {
    htmlStyle.overflow = 'hidden';
    bodyStyle.overflow = 'hidden';
  } else {
    htmlStyle.overflow = null;
    bodyStyle.overflow = null;
  }
};

const Modal = ({
  children,
  closable,
  onClose,
  width,
  height,
  expandable,
  expanded: expandedProp,
}) => {
  const [expanded, setExpanded] = useState(expandedProp);

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  const close = () => {
    if (closable) {
      onClose();
    }
  };

  useEffect(() => {
    const onKeyUp = e => {
      if (e.code === 'Escape') {
        close();
      }
    };

    instancesCount += 1;
    updateBodyScroll();

    window.addEventListener('keyup', onKeyUp);

    return () => {
      instancesCount -= 1;
      updateBodyScroll();

      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return createPortal(
    <ModalBackdrop onClick={() => close()} expanded={expanded}>
      <StyledModal
        open
        height={height}
        width={width}
        expanded={expanded}
        onClick={e => {
          e.stopPropagation();
          return false;
        }}>
        {closable && <ModalCloseButton onClick={() => close()} />}
        {expandable && (
          <ModalExpandButton
            className={`fa ${expanded ? 'fa-compress' : 'fa-expand'}`}
            onClick={() => setExpanded(!expanded)}
          />
        )}
        {children}
      </StyledModal>
    </ModalBackdrop>,
    document.getElementById('modal')
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
};

Modal.defaultProps = {
  closable: false,
  onClose: null,
  expandable: false,
  expanded: false,
};

export default Modal;
