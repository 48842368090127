const prefix = '[competences]';

export const PROFILE_FETCH_PERSONCOMPETENCES = `${prefix}/personcompetences/get/fetch`;
export const PROFILE_FETCH_PERSONCOMPETENCES_REQUEST = `${prefix}/personcompetences/get/request`;
export const PROFILE_FETCH_PERSONCOMPETENCES_FAILURE = `${prefix}/personcompetences/get/failure`;
export const PROFILE_FETCH_PERSONCOMPETENCES_SUCCESS = `${prefix}/personcompetences/get/success`;

export const PROFILE_EDIT_PERSONCOMPETENCE = `${prefix}/personcompetences/edit/edit`;
export const PROFILE_EDIT_PERSONCOMPETENCE_REQUEST = `${prefix}/personcompetences/edit/request`;
export const PROFILE_EDIT_PERSONCOMPETENCE_FAILURE = `${prefix}/personcompetences/edit/failure`;
export const PROFILE_EDIT_PERSONCOMPETENCE_SUCCESS = `${prefix}/personcompetences/edit/success`;

export const PROFILE_ADD_PERSONCOMPETENCE = `${prefix}/personcompetences/add/add`;
export const PROFILE_ADD_PERSONCOMPETENCE_REQUEST = `${prefix}/personcompetences/add/request`;
export const PROFILE_ADD_PERSONCOMPETENCE_FAILURE = `${prefix}/personcompetences/add/failure`;
export const PROFILE_ADD_PERSONCOMPETENCE_SUCCESS = `${prefix}/personcompetences/add/success`;

export const PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES = `${prefix}/personcompetencesMultiple/add/add`;
export const PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_REQUEST = `${prefix}/personcompetencesMultiple/add/request`;
export const PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_FAILURE = `${prefix}/personcompetencesMultiple/add/failure`;
export const PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_SUCCESS = `${prefix}/personcompetencesMultiple/add/success`;

export const PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES = `${prefix}/personcompetencesMultiple/edit/edit`;
export const PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_REQUEST = `${prefix}/personcompetencesMultiple/edit/request`;
export const PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_FAILURE = `${prefix}/personcompetencesMultiple/edit/failure`;
export const PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_SUCCESS = `${prefix}/personcompetencesMultiple/edit/success`;

export const FETCH_COMPETENCES = `${prefix}/competences/get/fetch`;
export const FETCH_COMPETENCES_REQUEST = `${prefix}/competences/get/request`;
export const FETCH_COMPETENCES_FAILURE = `${prefix}/competences/get/failure`;
export const FETCH_COMPETENCES_SUCCESS = `${prefix}/competences/get/success`;

// /////////////////////////////////////////////
export const profileFetchPersoncompetences = payload => ({
  type: PROFILE_FETCH_PERSONCOMPETENCES,
  payload,
});

export const profileFetchPersoncompetencesRequest = payload => ({
  type: PROFILE_FETCH_PERSONCOMPETENCES_REQUEST,
  payload,
});

export const profileFetchPersoncompetencesFailure = payload => ({
  type: PROFILE_FETCH_PERSONCOMPETENCES_FAILURE,
  payload,
});

export const profileFetchPersoncompetencesSuccess = payload => ({
  type: PROFILE_FETCH_PERSONCOMPETENCES_SUCCESS,
  payload,
});

// /////////////////////////////////////////////
export const profileEditPersoncompetence = payload => ({
  type: PROFILE_EDIT_PERSONCOMPETENCE,
  payload,
});

export const profileEditPersoncompetenceRequest = payload => ({
  type: PROFILE_EDIT_PERSONCOMPETENCE_REQUEST,
  payload,
});

export const profileEditPersoncompetenceFailure = payload => ({
  type: PROFILE_EDIT_PERSONCOMPETENCE_REQUEST,
  payload,
});

export const profileEditPersoncompetenceSuccess = payload => ({
  type: PROFILE_EDIT_PERSONCOMPETENCE_SUCCESS,
  payload,
});

// /////////////////////////////////////////////
export const profileAddPersoncompetence = payload => ({
  type: PROFILE_ADD_PERSONCOMPETENCE,
  payload,
});

export const profileAddPersoncompetenceRequest = payload => ({
  type: PROFILE_ADD_PERSONCOMPETENCE_REQUEST,
  payload,
});

export const profileAddtPersoncompetenceFailure = payload => ({
  type: PROFILE_ADD_PERSONCOMPETENCE_FAILURE,
  payload,
});

export const profileAddPersoncompetenceSuccess = payload => ({
  type: PROFILE_ADD_PERSONCOMPETENCE_SUCCESS,
  payload,
});

// /////////////////////////////////////////////
export const profileAddMultiplePersoncompetences = payload => ({
  type: PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES,
  payload,
});

export const profileAddMultiplePersoncompetencesRequest = payload => ({
  type: PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_REQUEST,
  payload,
});

export const profileAddMultiplePersoncompetencesFailure = payload => ({
  type: PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_FAILURE,
  payload,
});

export const profileAddMultiplePersoncompetencesSuccess = payload => ({
  type: PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_SUCCESS,
  payload,
});

// /////////////////////////////////////////////
export const profileEditMultiplePersoncompetences = payload => ({
  type: PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES,
  payload,
});

export const profileEditMultiplePersoncompetencesRequest = payload => ({
  type: PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_REQUEST,
  payload,
});

export const profileEditMultiplePersoncompetencesFailure = payload => ({
  type: PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_FAILURE,
  payload,
});

export const profileEditMultiplePersoncompetencesSuccess = payload => ({
  type: PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_SUCCESS,
  payload,
});
// /////////////////////////////////////////////
export const fetchCompetences = payload => ({
  type: FETCH_COMPETENCES,
  payload,
});

export const fetchCompetencesRequest = payload => ({
  type: FETCH_COMPETENCES_REQUEST,
  payload,
});

export const fetchCompetencesFailure = payload => ({
  type: FETCH_COMPETENCES_FAILURE,
  payload,
});

export const fetchCompetencesSuccess = payload => ({
  type: FETCH_COMPETENCES_SUCCESS,
  payload,
});
