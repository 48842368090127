import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ProfileLogo from '@components/profile-logo/profile-logo';

const ProfileCard = ({ person, color, style }) => {
  return (
    <CardCont style={style}>
      <ProfileLogo person={person} color={color} />
      <CardName>{person.fullname}</CardName>
    </CardCont>
  );
};

const CardCont = styled.div`
  display: inline-block;
`;

const CardName = styled.p`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  font-weight: 500;
`;

export default ProfileCard;

ProfileCard.propTypes = {
  person: PropTypes.shape({
    image_url: PropTypes.string,
    full_name: PropTypes.string,
    user_name: PropTypes.string,
  }),
  color: PropTypes.string,
  style: PropTypes.shape({}),
};

ProfileCard.defaultProps = {
  person: {},
  color: null,
  style: {},
};
