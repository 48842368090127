import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';

const Circle = styled.div`
  border-radius: 50%;
  background-color: #d1d1d1;
  position: fixed;
  bottom: 29px;
  right: 28px;
  z-index: 2;
  padding: 8px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.i`
  color: #808080;
`;

const ScrollToTop = ({ appearWhenScrolledPx = false }) => {
  const [display, setDisplay] = useState(!appearWhenScrolledPx);
  const onScroll = () => {
    if (window.pageYOffset >= appearWhenScrolledPx) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  if (!display) {
    return null;
  }
  return (
    <Circle
      onClick={() => animateScroll.scrollToTop({ duration: 400 })}
      role="button"
      title="Scroll til toppen"
      aria-label="Scroll til toppen">
      <Icon className="fas fa-arrow-up" />
    </Circle>
  );
};

ScrollToTop.propTypes = {
  appearWhenScrolledPx: PropTypes.number,
};

ScrollToTop.defaultProps = {
  appearWhenScrolledPx: null,
};

export default ScrollToTop;
