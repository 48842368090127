import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import seedRnd from 'random-seed';

/**
 * A rounded rectangle displaying either a given image or the initials of a given
 * name with a specified background color, if the image is not given.
 * Full name sould always be given.
 */

const defautlColor = '#3174e0';
const colorPool = [
  '#FF87A2',
  '#FE5E33',
  '#FFC641',
  '#16A3B2',
  '#51C5D2',
  '#06818E',
  '#111747',
];

// from https://stackoverflow.com/a/11868398
const isColorBright = hexcolor => {
  const color = hexcolor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128;
};

/**
 * Will pick a random color using the username.
 * A hex-color string is returned
 * Given the same username, the color will always be the same
 */
const pickColorByUsername = username => {
  const rndGen = seedRnd.create(username);
  const rndIdx = rndGen.intBetween(0, colorPool.length - 1);
  return colorPool[rndIdx];
};

const Container = styled.div`
  width: ${props => `${52 * props.sizeScale}px`};
  height: ${props => `${52 * props.sizeScale}px`};
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
`;

const ContentCont = styled.div`
  height: 100%;
  border-radius: 20px;
`;

const InitialCont = styled(ContentCont)`
  background-color: ${props => props.color || defautlColor};
`;

const Initials = styled.p`
  margin: 0;
  position: relative;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  user-select: none;
  color: ${props =>
    isColorBright(props.color || defautlColor) ? 'black' : 'white'};
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProfileLogo = ({ person, color, sizeScale }) => {
  if (person.profile_image && person.profile_image.url) {
    return (
      <Container sizeScale={sizeScale}>
        <Img
          className="profile-logo__image"
          src={person.profile_image.url}
          alt={person.fullname ? `portrait of ${person.fullname}` : ''}
        />
      </Container>
    );
  }

  const initials = (person.fullname || '')
    .split(' ')
    .map(namePart => namePart[0])
    .join('');

  const colorToUse = color || pickColorByUsername(person.user_name);

  return (
    <Container sizeScale={sizeScale}>
      <InitialCont color={colorToUse}>
        <Initials color={colorToUse} className="profile-logo-initials-text">
          {initials}
        </Initials>
      </InitialCont>
    </Container>
  );
};

ProfileLogo.propTypes = {
  person: PropTypes.shape({
    image_url: PropTypes.string,
    fullname: PropTypes.string,
    user_name: PropTypes.string,
  }),
  color: PropTypes.string,
  sizeScale: PropTypes.number,
};

ProfileLogo.defaultProps = {
  person: {},
  color: null,
  sizeScale: 1,
};

export default ProfileLogo;
