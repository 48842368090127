import {
  EMPLOYEES_GET_REQUEST,
  EMPLOYEES_GET_SUCCESS,
  EMPLOYEES_GET_FAILURE,
  EMPLOYEES_SEARCH_REQUEST,
  EMPLOYEES_SEARCH_SUCCESS,
  EMPLOYEES_SEARCH_FAILURE,
  EMPLOYEES_SEARCH_PHRAZE_CHANGE,
  EMPLOYEES_PERSON_SELECT,
  EMPLOYEES_FETCH_FUNCTIONS_REQUEST,
  EMPLOYEES_FETCH_FUNCTIONS_SUCCESS,
  EMPLOYEES_FETCH_FUNCTIONS_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST,
  EMPLOYEES_FETCH_STATISTICS_GET_REQUEST,
  EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS,
  EMPLOYEES_FETCH_STATISTICS_GET_FAILURE,
  EMPLOYEES_FETCH_EXTRADATA_REQUEST,
  EMPLOYEES_FETCH_EXTRADATA_SUCCESS,
  EMPLOYEES_FETCH_EXTRADATA_FAILURE,
  EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST,
  EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS,
  EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE,
  EMPLOYEES_REPORT_VIEW_FAILURE,
  EMPLOYEES_REPORT_VIEW_REQUEST,
  EMPLOYEES_REPORT_VIEW_SUCCESS,
  EMPLOYEES_SAVE_VERIFICATION_REQUEST,
  EMPLOYEES_SAVE_VERIFICATION_SUCCESS,
  EMPLOYEES_SAVE_VERIFICATION_FAILURE,
  EMPLOYEES_SAVE_REQUIREMENTS_REQUEST,
  EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS,
  EMPLOYEES_SAVE_REQUIREMENTS_FAILURE,
  EMPLOYEES_SAVE_ROLE_REQUEST,
  EMPLOYEES_SAVE_ROLE_SUCCESS,
  EMPLOYEES_SAVE_ROLE_FAILURE,
  EMPLOYEES_FETCH_EVENTS_REQUEST,
  EMPLOYEES_FETCH_EVENTS_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST,
  EMPLOYEES_FETCH_EVENTS_FAILURE,
} from '@actions/employees.actions';
import {
  EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE,
  EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST,
  EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS,
  SWITCH_EMPLOYEE_TAB,
} from '../actions/employees.actions';

const initialState = {
  list: {
    isFetching: false,
    hasMore: false,
    data: null,
  },
  filteredList: {
    isFetching: false,
    hasMore: false,
    data: null,
  },
  selectedPerson: {
    userName: null,
    isFetching: false,
    elements: {
      checklists: {
        isFetching: false,
      },
      positions: {
        isFetching: false,
      },
      summary: {
        isFetching: false,
      },
      activities: {
        isFetching: false,
      },
    },
    data: null,
    tab: 'role',
    error: null,
    report: {
      isFetching: false,
    },
  },
  statistics: {
    isFetching: false,
    data: null,
    error: null,
  },
  checklists: {
    isFetching: false,
    data: null,
    error: null,
  },
  extraData: {
    isFetching: false,
    error: null,
    data: null,
  },
  activities: {
    isFetching: false,
    data: null,
    error: null,
  },
  report: {
    isFetching: false,
    data: null,
    error: null,
  },
  functions: {
    isFetching: false,
    data: [],
    error: null,
  },
  saving: {
    isSaving: false,
    error: null,
  },
  events: {
    isFetching: false,
    data: null,
    error: null,
  },
  searchPhraze: '',
};

const employees = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case EMPLOYEES_GET_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };
    case EMPLOYEES_SEARCH_REQUEST:
      return {
        ...state,
        filteredList: {
          ...state.filteredList,
          isFetching: true,
        },
      };
    case EMPLOYEES_GET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          hasMore: action.payload.hasMore,
          data: action.payload.employees,
        },
      };
    case EMPLOYEES_SEARCH_SUCCESS:
      return {
        ...state,
        filteredList: {
          ...state.filteredList,
          isFetching: false,
          hasMore: action.payload.hasMore,
          data: action.payload.employees,
        },
      };
    case EMPLOYEES_GET_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      };
    case EMPLOYEES_SEARCH_FAILURE:
      return {
        ...state,
        filteredList: {
          ...state.filteredList,
          isFetching: false,
        },
      };
    case EMPLOYEES_SEARCH_PHRAZE_CHANGE:
      return {
        ...state,
        searchPhraze: action.payload.searchPhraze,
      };
    case SWITCH_EMPLOYEE_TAB:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          tab: action.payload,
        },
      };
    case EMPLOYEES_PERSON_SELECT:
      return {
        ...state,
        selectedPerson: {
          ...initialState.selectedPerson,
          userName: action.payload.userName,
        },
      };
    case EMPLOYEES_FETCH_FUNCTIONS_REQUEST: {
      return {
        ...state,
        functions: {
          ...state.functions,
          isFetching: true,
        },
      };
    }
    case EMPLOYEES_FETCH_FUNCTIONS_SUCCESS: {
      return {
        ...state,
        functions: {
          ...state.functions,
          data: action.payload.functions,
          isFetching: false,
        },
      };
    }
    case EMPLOYEES_FETCH_FUNCTIONS_FAILURE: {
      return {
        ...state,
        functions: {
          ...state.functions,
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    case EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: true,
          data: null,
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          data: action.payload.person,
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          data: {
            ...state.selectedPerson.data,
            summary: {
              ...state.selectedPerson.data.summary,
              isFetching: true,
            },
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          elements: {
            ...state.selectedPerson.elements,
            checklists: {
              isFetching: true,
            },
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          data: {
            ...state.selectedPerson.data,
            checklists: action.payload.data,
          },
          elements: {
            ...state.selectedPerson.elements,
            checklists: {
              isFetching: false,
            },
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          data: action.payload.data,
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          report: {
            isFetching: true,
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          report: {
            isFetching: false,
            data: action.payload.data,
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          report: {
            isFetching: false,
            data: null,
          },
        },
      };

    case EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          data: {
            ...state.selectedPerson.data,
            summary: {
              ...state.selectedPerson.data.summary,
              activities: {
                isFetching: true,
              },
            },
          },
        },
      };
    case EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS:
      return {
        ...state,
        selectedPerson: {
          ...state.selectedPerson,
          isFetching: false,
          data: action.payload.data,
        },
      };
    case EMPLOYEES_FETCH_STATISTICS_GET_REQUEST:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isFetching: true,
        },
      };
    case EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isFetching: false,
          data: action.payload.statistics,
        },
      };
    case EMPLOYEES_FETCH_STATISTICS_GET_FAILURE:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST:
      return {
        ...state,
        checklists: {
          ...state.checklists,
          isFetching: true,
        },
      };
    case EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS:
      return {
        ...state,
        checklists: {
          ...state.checklists,
          isFetching: false,
          data: action.payload.persons,
        },
      };
    case EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE:
      return {
        ...state,
        checklists: {
          ...state.checklists,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_FETCH_EXTRADATA_REQUEST:
      return {
        ...state,
        extraData: {
          ...state.extraData,
          isFetching: true,
        },
      };
    case EMPLOYEES_FETCH_EXTRADATA_SUCCESS:
      return {
        ...state,
        extraData: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    case EMPLOYEES_FETCH_EXTRADATA_FAILURE:
      return {
        ...state,
        extraData: {
          ...state.extraData,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST:
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: true,
        },
      };
    case EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: false,
          data: action.payload.activities,
        },
      };
    case EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_REPORT_VIEW_REQUEST:
      return {
        ...state,
        report: {
          data: null,
          isFetching: true,
        },
      };
    case EMPLOYEES_REPORT_VIEW_SUCCESS:
      return {
        ...state,
        report: {
          data: action.payload.data,
          isFetching: false,
        },
      };
    case EMPLOYEES_REPORT_VIEW_FAILURE:
      return {
        ...state,
        report: {
          data: null,
          isFetching: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_SAVE_VERIFICATION_REQUEST:
    case EMPLOYEES_SAVE_REQUIREMENTS_REQUEST:
    case EMPLOYEES_SAVE_ROLE_REQUEST:
      return {
        ...state,
        saving: {
          ...state.saving,
          isSaving: true,
        },
      };
    case EMPLOYEES_SAVE_VERIFICATION_FAILURE:
    case EMPLOYEES_SAVE_REQUIREMENTS_FAILURE:
    case EMPLOYEES_SAVE_ROLE_FAILURE:
      return {
        ...state,
        saving: {
          ...state.saving,
          isSaving: false,
          error: action.payload.error,
        },
      };
    case EMPLOYEES_SAVE_VERIFICATION_SUCCESS:
    case EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS:
    case EMPLOYEES_SAVE_ROLE_SUCCESS:
      return {
        ...state,
        saving: {
          ...state.saving,
          isSaving: false,
          error: null,
        },
      };
    case EMPLOYEES_FETCH_EVENTS_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          isFetching: true,
          error: null,
        },
      };
    case EMPLOYEES_FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          isFetching: false,
          data: action.payload.events,
        },
      };
    case EMPLOYEES_FETCH_EVENTS_FAILURE:
      return {
        ...state,
        events: {
          ...state.events,
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default employees;
