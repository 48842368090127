/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Page,
  Section,
  Row,
  Cell,
  Seperator,
  Column,
  Label,
  LinkLabel,
  CircleIcon,
  Icon,
} from '@src/components/Layout';
import Table from '@src/components/Table';
import { Input, InputChoices, DatePicker } from '@src/components/Input';
import Button from '@src/components/Button';
import ProfileCard from '@components/profile-card/profile-card';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { getProfile } from '@selectors/profile.selectors';
import { profileFetchPerson } from '@actions/profile.actions';
import { Form } from '@rocketseat/unform';
import { Context } from '@src/Context';
import Modal from '@src/components/Modal';
import { format } from '@src/utils/date.utils';
import * as Yup from 'yup';
import { PropTypes } from 'prop-types';
import { fetchedDataWrapperShape } from '@types/fetched-data';
import EmployeesList from '@routes/my-employees/EmployeesList';
import { Redirect, Prompt, withRouter } from 'react-router-dom';
import SignModalImage from '@src/assets/images/backdrop-signature.png';
import DocumentView from './DocumentView';
import ProgressTimeline from './ProgressTimeline';
import PersonalImprovementDashboard from './PersonalImprovementDashboard';
import { OKButtonLarge, ModalHeader } from '../../components/Modal';
import CourseSearch from './CourseSearch';
import { AspectRatioImage } from '../../components/AspectRatio';
import CourseEventPicker from './CourseEventPicker';
import { colors } from '../../styles';

const getDate = obj => {
  if (!obj) {
    return null;
  }
  if (obj.startdate) {
    return obj.startdate;
  }
  return obj;
};

const getTitle = obj => {
  if (!obj) {
    return null;
  }
  if (obj.title) {
    return obj.title;
  }
  return obj;
};

const isCourse = obj => obj && obj.competence_type_id === 3;

const EditableRow = ({
  task,
  onSave,
  onRemove,
  editingDisabled,
  displayCoursesResultsOnTop,
  isEditing: isEditingGlobal,
  setIsEditing: setIsEditingGlobal,
  profile,
  currentProfile,
}) => {
  const [isEditingTask, setIsEditingTask] = useState(false);
  const setIsEditing = bool => {
    setIsEditingTask(bool);
    setIsEditingGlobal(bool);
  };

  const [showButtons, setShowButtons] = useState(false);
  const [courseInput, setCourseInput] = useState(task[0]);
  const [dateInput, setDateInput] = useState(task[1]);
  const [responsibleInput, setResponsibleInput] = useState(task[2]);
  const [courseEvent, setCourseEvent] = useState(null);
  const [dateInputError, setDateInputError] = useState(null);
  const [courseInputError, setCourseInputError] = useState(null);
  const [responsibleInputError, setResponsibleInputError] = useState(null);

  const datepickerRef = useRef();

  useEffect(() => {
    if (!task.length) {
      setIsEditing(true);
    }
  }, [task]);

  useEffect(() => {
    if (courseInput !== courseEvent) {
      if (courseInput && courseInput.startdate) {
        setCourseEvent(courseInput);
      } else {
        setCourseEvent(null);
        if (courseEvent) {
          setDateInput(null);
        }
      }
    }
  }, [courseInput]);

  useEffect(() => {
    if (datepickerRef.current && isCourse(courseInput)) {
      datepickerRef.current.focus();
    }
  }, [courseInput, datepickerRef]);

  const save = () => {
    let success = true;
    if (!courseEvent && !courseInput) {
      setCourseInputError('Feltet må fylles ut');
      success = false;
    } else {
      setCourseInputError(null);
    }
    if (isCourse(courseInput) && !courseEvent) {
      setDateInputError('Kursdato ikke valgt');
      success = false;
    } else {
      setDateInputError(null);
    }
    if (!responsibleInput) {
      setResponsibleInputError('Ansvarlig er ikke valgt');
      success = false;
    } else {
      setResponsibleInputError(null);
    }

    if (success) {
      onSave([
        courseEvent || courseInput,
        courseEvent || dateInput,
        responsibleInput,
      ]);
      setIsEditing(false);
    }
  };

  const cancel = () => {
    setIsEditing(false);
  };

  const HtmlInputLabel = styled('label')``;

  const ActionButtonsRow = styled(Row)`
    ${({ invisible }) =>
      invisible
        ? css`
            visibility: hidden;
          `
        : ''}
  `;

  return (
    (isEditingTask && (
      <tr>
        <td css="padding: 4px 15px !important;" width="300px">
          <Form
            initialData={{
              course: task[0],
            }}>
            <CourseSearch
              small
              label="Kurs / Aktivitet / Mål"
              displayCoursesResultsOnTop={displayCoursesResultsOnTop}
              placeholder="Fritekst eller velg kompetanse"
              name="course"
              error={courseInputError}
              onChange={setCourseInput}
            />
          </Form>
        </td>
        <td css="padding: 4px 15px !important;" width="280px">
          <Form
            autoComplete="off"
            initialData={{
              date: task[1],
            }}>
            <HtmlInputLabel ref={datepickerRef}>
              {((isCourse(courseInput) || courseEvent) && (
                <CourseEventPicker
                  small
                  displayCoursesEventsOnTop={displayCoursesResultsOnTop}
                  label="Kursdato"
                  placeholder="Velg dato"
                  name="courseevent"
                  courseId={
                    (courseEvent && courseEvent.competence_id) ||
                    (courseInput &&
                      (courseInput.competence_id || courseInput.id))
                  }
                  value={courseEvent}
                  onChange={setCourseEvent}
                  error={dateInputError}
                  showClearButton
                />
              )) || (
                <DatePicker
                  small
                  label="Frist"
                  placeholder="Frist for gjennomførelse"
                  name="date"
                  showTimeInput
                  value={dateInput}
                  onChange={setDateInput}
                  showClearButton
                />
              )}
            </HtmlInputLabel>
          </Form>
        </td>
        <td
          css="padding: 4px 15px !important; :after { display: none !important; }"
          width="295px">
          <Form
            initialData={{
              responsible: task[2],
            }}>
            <InputChoices
              small
              name="responsible"
              label="Ansvarlig"
              choices={[profile.data.fullname, currentProfile.fullname, 'Begge']}
              values={[profile.data.fullname, currentProfile.fullname, 'Begge']}
              error={responsibleInputError}
              value={responsibleInput}
              onChange={data => setResponsibleInput(data)}
            />
          </Form>
        </td>
        <td
          css="padding: 4px 15px !important; white-space: nowrap;"
          width="195px"
          height="74px">
          <Row>
            <Cell grow />
            <Button type="submit" small action onClick={save}>
              Lagre
            </Button>
            <Button small warn onClick={cancel}>
              Avbryt
            </Button>
          </Row>
        </td>
      </tr>
    )) || (
      <tr
        onMouseOver={() => setShowButtons(true)}
        onFocus={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        onBlur={() => setShowButtons(false)}>
        <td width="300px">{getTitle(task[0])}</td>
        <td
          data-sort={getDate(task[1]) && new Date(getDate(task[1]))}
          width="280px">
          {getDate(task[1])
            ? format(new Date(getDate(task[1])), "d. MMMM yyyy 'kl.' HH:mm")
            : 'Ingen'}
        </td>
        <td css=":after { display: none !important; }" width="295px">
          {task[2]}
        </td>
        <td width="195px" height="74px">
          {!isEditingGlobal && (
            <ActionButtonsRow
              invisible={!showButtons || editingDisabled}
              aria-hidden={!showButtons}>
              <Cell grow />
              <Button small alternative onClick={() => setIsEditing(true)}>
                Rediger
              </Button>
              <Button small warn onClick={onRemove}>
                Slett
              </Button>
            </ActionButtonsRow>
          )}
        </td>
      </tr>
    )
  );
};

const PersonalImprovement = withRouter(( { profile, profileFetchPerson, location, history }) => {
  const [currentProfile, setCurrentProfile] = useState(null);
  const [step, setStep] = useState(0);
  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [signModalOpen, setSignModalOpen] = useState(false);
  const [shouldNavigateToMyPage, setShouldNavigateToMyPage] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);

  const [tasks, setTasks] = useState([]);
  console.log("tasks is", JSON.stringify(tasks));
  const [displayAddTasksTable, setDisplayAddTasksTable] = useState(
    tasks.length > 0
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isTaskEditOpen, setTaskEditOpen] = useState(false);
  const [currentEditing, setCurrentEditing] = useState(null);

  const [signatureLoading, setSignatureLoading] = useState(false);

  const { getEmployee } = useContext(Context);
  const { lastTalkDate, nextTalkDate, setTalkdate } = currentProfile
    ? getEmployee(currentProfile.user_name)
    : {};

  const {
    employees: { list: employees },
  } = useContext(Context);

  useEffect(() => {
    profileFetchPerson();
    if (
      location &&
      location.state &&
      location.state.employeeSign &&
      location.state.profile
    ) {
      setStep(steps.length - 1);
      onEmployeeTalkSelect(location.state.profile);
      setIsEmployee(true);
    } else {
      setStep(0);
      setTasks([]);
    }

    if (
      location &&
      location.state &&
      location.state.employeeSign &&
      location.state.tasks
    ) {
      setTasks(location.state.tasks);
    }

  }, [location.state]);

  useEffect(() => {
    if (location && location.state && location.state.profile) {
      onEmployeeTalkSelect(location.state.profile);
    }
  });

  const onSubmitSignature = ({ username, password }) => {
    if (!signatureLoading) {
      console.log('WIP', 'username', username, 'password', password);

      setSignatureLoading(true);
      setTimeout(() => {
        setTalkdate({ lastTalkDate: new Date(), nextTalkDate: null });

        setSignModalOpen(false);
        setSignatureLoading(false);
        setStep(steps.length);
        setHasSigned(true);
      }, 2500);
    }
  };

  const onEditClick = key => {
    setCurrentEditing(key);
    setTaskEditOpen(true);
  };

  const addNewTask = () => {
    const newTasks = Array.from(tasks);
    newTasks[newTasks.length] = [];
    setTasks(newTasks);
  };

  const saveTask = (key, data) => {
    const newTasks = Array.from(tasks);
    newTasks[key] = data;
    setTasks(newTasks);
    setIsEditing(false);
    setDisplayAddTasksTable(true);
  };
  const removeTask = key => {
    if (tasks.length <= 1) {
      setTasks([]);
      return;
    }
    const newTasks = Array.from(tasks);
    newTasks.splice(key, 1);
    setTasks(newTasks);
  };

  const onCancelEditTask = () => {
    if (tasks.length > 0) {
      const copyOfTasks = tasks;
      copyOfTasks.pop();
      setTasks(copyOfTasks);
    }
  };

  useEffect(() => {
    if (!isEditing && tasks.length > 0 && !tasks[tasks.length - 1][0]) {
      removeTask(tasks.length - 1);
    }
  }, [isEditing]);

  const steps = [
    'Innledning',
    'Strategi & Mål',
    'Dokumenter',
    'Oppfølging',
    'Signering',
  ];

  const documents = [
    [
      {
        title: 'Innledning',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.'`,
      },
      {
        title: 'E-DOCS (sagas.pdf)',
        file: '/pdf/sagas.pdf',
      },
    ],
    [
      {
        title: 'Strategi & Mål',
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.'`,
      },
      {
        title: 'E-DOCS (sagas.pdf)',
        file: '/pdf/sagas.pdf',
      },
    ],
    [
      {
        title: 'E-DOCS (sagas.pdf)',
        file: '/pdf/sagas.pdf',
      },
    ],
  ];

  const PersonInformationLoadingCont = styled.div`
    font-size: 1.5em;
    position: relative;
    top: 34%;
    transform: translate(0, -50%);
    text-align: center;
  `;

  const ProfileCardWrapper = styled.div`
    height: 60px;
  `;

  const profileDataLoading = profile.isFetching || !profile.data;

  const onEmployeeTalkSelect = profile => {
    setCurrentProfile(profile);
  };

  const cancelPersonalImprovementSetup = () => {
    const navigatedFromMyPage =
      location &&
      location.state &&
      location.state.employeeSign &&
      location.state.profile;

    if (navigatedFromMyPage) {
      setShouldNavigateToMyPage(true);
    } else {
      setCurrentProfile(null);
    }

    setStep(0);
  };

  const { body } = document;
  const html = document.documentElement;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const displayCoursesResultsOnTop = 850 + 80 * tasks.length > height;

  return (
    <Page title="Utviklingssamtale">
      {(!currentProfile && (
        <>
          <PersonalImprovementDashboard
            onEmployeeTalkSelect={onEmployeeTalkSelect}
            isLoading={!profile.data}
            completedTalks={employees.filter(e => e.lastTalkDate).length}
            plannedTalks={employees.filter(e => e.nextTalkDate).length}
          />
          <EmployeesList onEmployeeTalkSelect={onEmployeeTalkSelect} />
        </>
      )) || (
        <Section>
          <Prompt
            when={tasks.length && step < steps.length}
            message="Utviklingssamtalen vil bli avbrutt. Er du sikker på at du vil forlate siden?"
          />
          <Label marginTop="40px" marginBottom="1em" size="18px">
            Samtale – {format(new Date(), 'd. MMMM yyyy')}
          </Label>
          <ProfileCardWrapper>
            {(profileDataLoading && (
              <PersonInformationLoadingCont>
                <Icon className="fa fa-spin fa-spinner" />
                Laster...
              </PersonInformationLoadingCont>
            )) || <ProfileCard person={currentProfile || profile.data} />}
          </ProfileCardWrapper>

          <ProgressTimeline
            steps={steps}
            current={step}
            onStepClick={setStep}
          />
          {(step === 3 && (
            <Column border grow>
              <Row padding="14px">
                <Label bold noWrap lineHeight="2rem">
                  <CircleIcon
                    background="#ecf4fd"
                    color="#297fe8"
                    className="fa fa-tasks"
                    marginRight="1em"
                  />
                  Oppgaver
                </Label>
              </Row>
              <Seperator />
              <Cell>
                {(tasks.length && (
                  <Table
                    highlightOddRows
                    sortable={false}
                    headings={[null, 'Frist', 'Ansvarlig', null]}>
                    {tasks.map(([oppf, frist, ansvarlig], key) => (
                      <EditableRow
                        key={key}
                        task={tasks[key]}
                        onSave={data => saveTask(key, data)}
                        onRemove={() => removeTask(key)}
                        displayCoursesResultsOnTop={displayCoursesResultsOnTop}
                        isEditing={isEditing}
                        editingDisabled={hasSigned || isEmployee}
                        setIsEditing={setIsEditing}
                        profile={profile}
                        currentProfile={currentProfile}
                      />
                    ))}
                  </Table>
                )) || (
                  <Label marginLeft="38px" marginTop="25px">
                    {!hasSigned && !isEmployee
                      ? `Her kan du legge til oppgaver som skal gjennomføres i løpet
                    av neste periode. Trykk på «Legg til oppgave» for å legge
                    til nye oppgaver.` : ''
                    }
                    {hasSigned && !isEmployee ?  
                       'Du har ikke lagt til noen oppgaver.' : ''}
                    {isEmployee ? `
                      Her kan en leder legge til oppgaver som skal gjennomføres i løpet av neste periode.
                    `: ''}
                  </Label>
                )}
                {!isEditing && !hasSigned && !isEmployee && (
                  <Row padding="38px">
                    <Button small action onClick={addNewTask}>
                      <i className="fa fa-plus" /> Legg til oppgave
                    </Button>
                    <Cell grow />
                  </Row>
                )}
              </Cell>
            </Column>
          )) ||
            ((step >= 4 && (
              <Column border grow>
                <Row padding="14px">
                  <Label bold noWrap lineHeight="2rem">
                    <CircleIcon
                      background="#ecf4fd"
                      color="#297fe8"
                      className="fa fa-tasks"
                      marginRight="1em"
                    />
                    Signering av avtale
                  </Label>
                </Row>
                <Seperator />
                <Cell padding="36px">
                  {((step === 5 || (step === 4 && hasSigned)) && (
                    <>
                      <Row>
                        <Cell grow />
                        <Column marginTop="2em">
                          <Icon
                            margin="auto"
                            marginBottom="1.4em"
                            size="2.5em"
                            color="#67bd66"
                            css={`
                              position: relative;

                              ::after {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 2.5em;
                                height: 2.5em;
                                border: 2px solid ${colors.cellBorder};
                                border-radius: 50%;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                              }
                            `}
                            className="fa fa-check"
                          />
                          <Label marginBottom="0.5em" size="1.5em" bold>
                            Avtalen er signert
                          </Label>
                          <ul>
                            <li>
                              <LinkLabel
                                lineHeight="2em"
                                inline
                                to="/profile"
                                onClick={() => {
                                  setHasSigned(false);
                                  setStep(0);
                                }}>
                                Gå til min side
                              </LinkLabel>
                            </li>
                            <li>
                              <LinkLabel
                                lineHeight="2em"
                                inline
                                to="/profile"
                                onClick={() => {
                                  setHasSigned(false);
                                  setStep(0);
                                }}>
                                Gå til arkiv
                              </LinkLabel>
                            </li>
                            {((location && !location.state) ||
                              (location &&
                                location.state &&
                                !location.state.employeeSign)) && (
                              <li>
                                <LinkLabel
                                  lineHeight="2em"
                                  inline
                                  onClick={() => {
                                    setCurrentProfile(null);
                                    setHasSigned(false);
                                    setStep(0);
                                  }}>
                                  Gå tilbake til oversikt
                                </LinkLabel>
                              </li>
                            )}
                          </ul>
                        </Column>
                        <Cell grow />
                      </Row>
                    </>
                  )) || (
                    <>
                      <Label marginBottom="38px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Label>

                      {(tasks.length && (
                        <Table
                          sortable={false}
                          headings={['Oppfølging', 'Frist', 'Ansvarlig']}>
                          {tasks.map(([oppf, frist, ansvarlig], key) => (
                            <tr key={key}>
                              <td>{getTitle(oppf)}</td>
                              <td
                                data-sort={
                                  getDate(frist) && new Date(getDate(frist))
                                }
                                width="280px">
                                {getDate(frist)
                                  ? format(
                                      new Date(getDate(frist)),
                                      "d. MMMM yyyy 'kl.' HH:mm"
                                    )
                                  : 'Ingen'}
                              </td>
                              <td>{ansvarlig}</td>
                            </tr>
                          ))}
                        </Table>
                      )) || (
                        <Label italic size="1.1em">
                          {!isEmployee ? `
                          Ingen oppgaver er lagt til. Oppgaver kan legges til på
                          Oppfølging-siden. ` : `Ingen oppgaver er lagt til.`}
                        </Label>
                      )}
                    </>
                  )}
                </Cell>
              </Column>
            )) || (
              <DocumentView fullscreenButton grow documents={documents[step]} />
            ))}

          <Row marginBottom="40px" marginTop="28px">
            {step < steps.length && (
              <>
                { (!hasSigned && !shouldNavigateToMyPage) && (
                  <Cell>
                    <Button
                      onClick={(e) => {
                        // eslint-disable-next-line no-alert
                          if(isEmployee){
                            history.push('/profile');
                            setShouldNavigateToMyPage(true);
                          }
                          else if (
                              window.confirm(
                                'Utviklingssamtalen vil bli avbrutt. Er du sikker på at du vil forlate siden?'
                            )) {
                              cancelPersonalImprovementSetup();
                           }
                      }}>
                      Avbryt
                    </Button>
                  </Cell>
                )}
                <Cell grow />
                <Row>
                  {step > 0 && !(step === 4 && hasSigned) && (
                    <Button onClick={() => setStep(step - 1)}>Tilbake</Button>
                  )}
                  {(step === steps.length - 1 && !hasSigned && (
                    <Button
                      action={!signModalOpen}
                      onClick={() => setSignModalOpen(true)}>
                      <Icon marginRight="0.6em" className="fa fa-user-shield" />
                      Signer
                    </Button>
                  )) ||
                    (!(step === 4 && hasSigned) && (
                      <Button action onClick={() => setStep(step + 1)}>
                        Neste
                      </Button>
                    ))}
                </Row>
              </>
            )}
          </Row>
        </Section>
      )}
      {signModalOpen && (
        <Modal width="600px" onClose={() => setSignModalOpen(false)} closable>
          <ModalHeader>
            <AspectRatioImage
              ratio={11 / 3}
              src={SignModalImage}
              backgroundPosition="50% 39%"
            />
          </ModalHeader>
          <Label marginTop="0.5em" lineHeight="2" size="1.5em">
            Signering av utviklingssamtale
          </Label>
          <Label marginBottom="1em">{format(new Date(), 'd. MMMM yyyy')}</Label>
          <Form onSubmit={onSubmitSignature}>
            <Input name="email" label="E-post" placeholder="eksempel@nsf.no" />
            <Row grow>
              <Input
                name="password"
                type="password"
                label="Passord"
                placeholder="●●●●●●●●"
              />
              <Column>
                <Cell grow />
                {signatureLoading ? (
                  <Button
                    marginBottom="8px"
                    marginLeft="24px"
                    marginRight="0"
                    action>
                    <Icon
                      marginRight="0.6em"
                      className="fa fa-spin fa-spinner"
                    />
                    Signer
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    marginBottom="8px"
                    marginLeft="24px"
                    marginRight="0"
                    action>
                    <Icon marginRight="0.6em" className="fa fa-user-shield" />
                    Signer
                  </Button>
                )}
              </Column>
            </Row>
          </Form>
          <Row>
            <Cell grow />
            <Label marginTop="1em" size="0.8em">
              Du er i ferd med å signere som{' '}
              <Label inline bold>
                {profile.data.fullname}
              </Label>
              .
            </Label>
            <Cell grow />
          </Row>
        </Modal>
      )}
      {policyModalOpen && (
        <Modal width="800px" onClose={() => setPolicyModalOpen(false)} closable>
          <Label marginBottom="1em" bold>
            Behandling av sensitiv informasjon
          </Label>
          <Label marginBottom="2em">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum arcu risus, sagittis sit amet sagittis eu, fringilla ut
              tellus. Donec luctus elit nec orci malesuada, ac sollicitudin
              lacus rutrum. Integer sem nibh, elementum congue pulvinar eu,
              pulvinar nec velit. Duis eu tortor suscipit, consequat nunc vitae,
              ornare arcu. Pellentesque nec eleifend arcu, a tincidunt nibh.
              Interdum et malesuada fames ac ante ipsum primis in faucibus.
              Vestibulum vel posuere enim, ut fringilla sapien. Nunc quis
              suscipit metus. Vivamus in nunc sed metus luctus semper. Nam
              tincidunt metus nisi, vitae varius nunc scelerisque in. Nulla arcu
              risus, dignissim sed malesuada non, consectetur ut orci. Etiam
              aliquam porta purus. Sed quis enim sed felis convallis facilisis.
            </p>
            <p>
              Donec non ipsum ac metus volutpat aliquam in at augue. Nullam eu
              est dignissim, porta dolor sed, facilisis nulla. Nulla quis erat
              volutpat eros consequat luctus. Donec feugiat justo id metus
              malesuada, id egestas justo hendrerit. Donec quis justo ut arcu
              consectetur consequat. Suspendisse viverra risus blandit arcu
              vehicula, et pretium lectus lacinia. Cras ut orci id risus
              pulvinar lacinia. Sed orci libero, auctor vel accumsan sed,
              sollicitudin vel tellus. Morbi pretium, dui id finibus
              sollicitudin, justo ipsum tristique mauris, sit amet posuere nisl
              nisl eget metus. Praesent pharetra nibh lorem, vel faucibus turpis
              vestibulum eget. Donec consectetur accumsan nisl in finibus.
            </p>
            <p>
              Vestibulum lobortis tellus et commodo facilisis. Nulla rutrum
              lorem ac sodales sodales. Proin egestas ultrices eros non congue.
              Proin rhoncus lacus in velit molestie, quis sodales lorem
              vehicula. Sed eleifend urna arcu, vitae luctus massa finibus quis.
              Vivamus purus justo, bibendum mattis semper et, fringilla vitae
              lacus. Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos. Nunc maximus ex id lorem
              tincidunt, et tincidunt lectus pharetra. Aenean ut libero non odio
              ultrices dapibus vitae sit amet est. Mauris sit amet vulputate mi,
              sed eleifend orci.
            </p>
          </Label>
          <OKButtonLarge onClick={() => setPolicyModalOpen(false)}>
            OK
          </OKButtonLarge>
        </Modal>
      )}
    </Page>
  );
});

PersonalImprovement.propTypes = {
  profile: fetchedDataWrapperShape({}).isRequired,
  profileFetchPerson: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
});

const mapDispatchToProps = {
  profileFetchPerson,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonalImprovement));
