import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Block } from './LayoutBase';
import { Cell, Row } from './Layout';

/**
 * A styled button.
 * Name and/or icons can be passed in as children.
 * Atributes is passed as to a normal html-button.
 * The type of button is passed in using the type-prop:
 *    *alternative
 *    *action
 *    *secondary
 *
 * An action can either be:
 *    *An action to be performed with onclick
 *    *A link with href
 *    *A router link with to
 */

export const ButtonBase = styled(Block.withComponent('button')).attrs(
  props => ({
    className: props.className,
  })
)`
  position: relative;
  font-family: 'Roboto', sans-serif;
  flex: 0 1;
  padding: ${({ small }) => (small ? '8px 12px' : '16px 20px')};
  border-radius: ${({ small }) => (small ? '3px' : '10px')};
  cursor: pointer;
  color: #515254;
  transition: background 0.115s ease-in, border 0.115s ease-in,
    color 0.115s ease-in;
  white-space: nowrap;
  border: 1px solid #fff;
  background: #fff;
  line-height: 1.15;
  flex: 0;

  :hover,
  :focus:active {
    background-color: #e1e1e1;
  }

  &:disabled,
  &:disabled:hover {
    background-color: #f4f8fc;
    color: #c6c6c6;
    border: none;
    cursor: default;
  }
  &:focus {
    box-shadow: inset 0 1px 0 0 rgba(102, 191, 255, 0.5),
      0 0 0 4px rgba(0, 126, 215, 0.19);
  }

  ${Cell} > & {
    margin-top: 6px;
  }

  ${Row} > & {
    margin-top: 0;
    margin-left: 6px;
  }
`;

const AlternativeButton = styled(ButtonBase)`
  border: 1px solid #1573e6;
  color: #1473e6;

  :hover,
  :focus:active {
    color: #fff;
    background-color: #1573e6;
    outline: none;
  }
`;

const WarnButton = styled(ButtonBase)`
  border: 1px solid #f33535;
  color: #f33535;

  :hover,
  :focus:active {
    color: #fff;
    background-color: #f33535;
    outline: none;
  }
`;

const ActionButton = styled(ButtonBase)`
  border: 1px solid #1573e6;
  background-color: #1573e6;
  color: #fff;

  :hover,
  :focus:active {
    background-color: #0a62cd;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  border: 1px solid #eff0f1;
`;

const Button = ({
  action,
  secondary,
  className,
  alternative,
  warn,
  onClick,
  href,
  to,
  children,
  type,
  ...props
}) => {
  let linkOnClick = null;
  if (href) {
    linkOnClick = () => {
      if (onClick()) {
        return;
      }
      window.location.href = href;
    };
  }

  const onClickToPerform = linkOnClick || onClick;
  let StyledButton = ButtonBase;
  if (warn) {
    StyledButton = WarnButton;
  }
  if (alternative) {
    StyledButton = AlternativeButton;
  }
  if (action) {
    StyledButton = ActionButton;
  }
  if (secondary) {
    StyledButton = SecondaryButton;
  }

  const wrapAsRouterLink = elemToWrap =>
    to ? <Link to={to}>{elemToWrap}</Link> : elemToWrap;

  return wrapAsRouterLink(
    <StyledButton
      onClick={onClickToPerform}
      type={type}
      {...props}
      className={className}>
      {children}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  action: PropTypes.bool,
  secondary: PropTypes.bool,
  alternative: PropTypes.bool,
  warn: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
};

Button.defaultProps = {
  action: false,
  secondary: false,
  alternative: false,
  warn: false,
  small: false,
  onClick: null,
  to: null,
  href: null,
  children: null,
  type: 'button',
};
