export const getProfile = ({ profile: { person } }) => person;
export const getRoles = ({ profile: { roles } }) => roles;
export const getEvents = ({ profile: { events } }) => events;
export const getShowRoles = ({ profile: { show_role } }) => show_role;

export const getTab = ({ profile: { tab } }) => tab;
export const getCompetences = ({ profile: { competences } }) =>
  competences.data;
export const shouldUpdate = ({ profile: { updated } }) => {
  if (updated !== null) {
    return false;
  }
  return true;
};

export const getProfileId = ({
  profile: {
    person: { data },
  },
}) => data && data.id;

export const getIsFullPersonFetched = ({ profile: { person, roles } }) => {
  let progress = 0;
  if (person && person.data) {
    progress += 1;
  }
  if (roles && roles.data) {
    progress += 1;
  }
  if (progress === 2) {
    return 100;
  }

  return 100 / progress;
};

export const getOrganisation = ({ profile: { person } }) => {
  if (person.data) {
    let orgId = localStorage.getItem('orgId');

    if (typeof orgId === 'string') {
      orgId = parseInt(orgId, 0);
    }
    return (
      person.data.organisations.find(o => o.id === orgId) ||
      person.data.organisations[0]
    );
  }
  return null;
};

export const getOrganisationId = state => {
  const orgId = localStorage.getItem('orgId');

  if (state.profile.person.storeManager && orgId) {
    return orgId;
  }
  const org = getOrganisation(state);

  if (org) {
    return org.organisation_id;
  }
  return org;
};

export const getBrandId = state => {
  const org = getOrganisation(state);
  if (org) {
    return org.brand_id;
  }
  return org;
};

export const getSelectedRoleId = ({ profile: { show_role } }) => {
  if (show_role.data) {
    return show_role.data.id;
  }
  return null;
};

export const getMainOrganisations = ({ profile: { person } }) => {
  if (person.data) {
    const orgId = localStorage.getItem('orgId');
    if (orgId && person.organisations && Array.isArray(person.organisations)) {
      const can_we_return = person.organisations.filter(
        o => o.id === parseInt(orgId, 0)
      );
      if (can_we_return.length !== 0) {
        return {
          title: can_we_return[0].title,
          brand_id: person.data.organisations[0].brand_id,
          id: can_we_return[0].id,
        };
      }
    }
    return person.data.organisations[0];
  }
  return null;
};

export const isManager = ({ profile: { person } }) => {
  if (person) {
    return person.storeManager;
  }
  return false;
};

export const getProfileUserName = ({ profile: { person } }) => {
  if (person.data) {
    return person.data.user_name;
  }
  return null;
};

export const getProfileOrganisations = ({ profile: { person } }) =>
  person.data.organisations;

export const getMissingCompetencesIds = competences => {
  if (!competences.data) {
    return [];
  }

  const ids = [];
  for (let i = 0; i < competences.data.length; i += 1) {
    if (competences.data[i].passed !== 100) {
      ids.push(competences.data[i].competence_id);
    }
  }
  return ids;
};

export const getPassedIds = ({ profile: { passed } }) => {
  if (!passed.data) {
    return [];
  }
  const ids = [];
  for (let i = 0; i < passed.data.length; i += 1) {
    if (passed.data[i].passed === 100) {
      ids.push(passed.data[i].competence_id);
    }
  }
  return ids;
};

export const getPassedCompetencesIds = competences => {
  if (!competences.data) {
    return [];
  }

  const ids = [];
  for (let i = 0; i < competences.data.length; i += 1) {
    if (competences.data[i].passed === 100) {
      ids.push(competences.data[i].competence_id);
    }
  }
  return ids;
};

export const getCompetencesRoleIds = (roles, roleId) => {
  if (!roles.data) {
    return [];
  }
  const ids = [];
  for (let i = 0; i < roles.data.length; i += 1) {
    if (roles.data[i].id === roleId) {
      for (
        let ii = 0;
        ii < roles.data[i].required_competences.length;
        ii += 1
      ) {
        ids.push(roles.data[i].required_competences[ii].id);
      }
      break;
    }
  }
  return ids;
};

export const getRole = ({ profile: { roles } }, roleId = null) => {
  if (!roles.data) {
    return [];
  }
  if (roleId) {
    return roles.data.find(f => f.id === roleId);
  }
  return roles.data;
};

export const getRoleCompetencesIds = roles => {
  if (!roles.data) {
    return {};
  }

  const roles_ids = {};
  for (let i = 0; i < roles.data.length; i += 1) {
    for (let ii = 0; ii < roles.data[i].required_competences.length; ii += 1) {
      roles_ids[roles.data[i].required_competences[ii].id] =
        roles.data[i].title;
    }
  }

  return roles_ids;
};

export const getRoleCompetences = (
  { profile: { competences }, profile: { roles } },
  roleId,
  status = 'all'
) => {
  if (!competences.data) {
    return [];
  }
  const compsIds = getCompetencesRoleIds(roles, roleId);
  const comps = [];
  for (let i = 0; i < competences.data.length; i += 1) {
    if (compsIds.length === 0 && status === 'all') {
      comps.push(competences.data[i]);
    } else if (compsIds.indexOf(competences.data[i].competence_id) !== -1) {
      if (
        competences.data[i].passed === 100 &&
        (status === 'all' || status === 'passed')
      ) {
        comps.push(competences.data[i]);
      } else if (
        competences.data[i].passed !== 100 &&
        (status === 'all' || status === 'missing')
      ) {
        comps.push(competences.data[i]);
      }
    }
  }
  return comps;
};

const getPassedCompetencesCheck = competences => {
  if (!Array.isArray(competences.data)) {
    return [];
  }
  return competences.data.filter(competence => competence.passed === 100);
};

const getMissingCompetencesCheck = competences => {
  if (!Array.isArray(competences.data)) {
    return [];
  }
  return competences.data.filter(competence => competence.passed !== 100);
};

export const getPassedCompetences = ({ profile: { competences } }) => ({
  passed: getPassedCompetencesCheck(competences),
  keys: getPassedCompetencesIds(competences),
});

export const getMissingCompetences = ({
  profile: { competences },
  profile: { roles },
}) => ({
  missing: getMissingCompetencesCheck(competences),
  keys: getMissingCompetencesIds(competences),
  roleId: getRoleCompetencesIds(roles),
});

export const getPassedCompetencesGroupedByType = ({
  profile: { passed_full },
}) => {
  if (!passed_full.data) {
    return passed_full;
  }

  return {
    ...passed_full,
    data: Object.entries(
      passed_full.data.reduce((groupedCompetences, personcompetence) => {
        const { competence_type: competenceType } = personcompetence;

        return {
          ...groupedCompetences,
          [competenceType.id]: {
            ...competenceType,
            personcompetences: [
              ...(groupedCompetences[competenceType.id]
                ? groupedCompetences[competenceType.id].personcompetences
                : []),
              personcompetence,
            ],
          },
        };
      }, {})
    ).map(([, competenceTypeGroup]) => competenceTypeGroup),
  };
};

export const getRecommendedCompetences = ({
  profile: { recommendedCompetences },
}) => recommendedCompetences;
