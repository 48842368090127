import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { Redirect } from 'react-router-dom';

const AgreementsForSigningWarning = ({ agreements, profile }) => {
  const [
    shouldNavigateToAgreementSign,
    setShouldNavigateToAgreementSign,
  ] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const Wrapper = styled.div`
    background-color: #feeac8;
    border: 1px solid #e2b14b;
    border-radius: 5px;
  `;

  const GoToSign = styled(Link)`
    color: #1573e6;
    font-size: 0.9em;
    white-space: nowrap;
    margin-right: 10px;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `;

  const Content = styled.div`
    color: #6f5012;
    font-size: 0.9em;
    margin-left: 16px;
    margin-top: 14px;
    margin-bottom: 14px;
  `;

  const SignIcon = styled.i`
    color: #6f5012;
  `;

  const SignIconWrapper = styled.div`
    border-radius: 50%;
    padding: 4px;
    background-color: #c4ad7f;
    width: 25px;
    height: 25px;
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
  `;

  const Desc = styled.p`
    margin: 0;
    margin-left: 38px;
  `;

  const onGoToSign = () => {
    setSelectedAgreement(agreements[0]);
    setShouldNavigateToAgreementSign(true);
  };

  return (
    <Wrapper>
      {shouldNavigateToAgreementSign && (
        <Redirect
          to={{
            pathname: '/personal-improvement/sign',
            state: { sign: true, profile, tasks: selectedAgreement.tasks },
          }}
        />
      )}
      <Content>
        <SignIconWrapper>
          <SignIcon className="fas fa-exclamation" title="melding" />
        </SignIconWrapper>
        <Desc>
          {`Du har ${
            agreements.length === 1 ? 'èn' : agreements.length
          } avtale${agreements.length > 1 ? 'r' : ''} til signering.`}{' '}
          {(agreements.length > 1 && (
            <GoToSign to="archiveElem" smooth duration={550}>
              Gå til avtaler
            </GoToSign>
          )) || (
            <GoToSign as="button" onClick={onGoToSign}>
              Gå til signering
            </GoToSign>
          )}
        </Desc>
      </Content>
    </Wrapper>
  );
};

export default AgreementsForSigningWarning;

AgreementsForSigningWarning.propTypes = {
  agreements: PropTypes.array.isRequired,
  profile: PropTypes.shape({}).isRequired,
};

AgreementsForSigningWarning.defaultProps = {};
