import {
  ACTION_INIT,
  ACTION_SUCCESS,
  ACTION_LOADING,
  ACTION_ERROR,
  ACTION_GOTO,
  ACTION_BLUR,
  ACTION_CLEAR,
} from '@actions/alert.actions';

const initialState = {
  type: undefined,
  message: undefined,
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case ACTION_INIT:
      return {
        loaded: false,
      };
    case ACTION_SUCCESS:
      return {
        ...state,
        type: 'alert-success',
        message: action.message,
      };
    case ACTION_LOADING:
      return {
        ...state,
        type: 'alert-reloading',
        message: action.message,
      };
    case ACTION_GOTO:
      return {
        ...state,
        type: 'alert-goto',
        id: action.id,
        message: action.message,
      };
    case ACTION_BLUR:
      document.body.classList.add('block-scroll');
      return {
        ...state,
        type: 'blur-success',
        message: action.message,
      };
    case ACTION_ERROR:
      return {
        ...state,
        type: 'alert-danger',
        message: action.message,
      };
    case ACTION_CLEAR:
      document.body.classList.remove('block-scroll');
      return {
        type: '',
        message: '',
      };
    default:
      return state;
  }
};
export default alert;
