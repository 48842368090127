import 'react-hot-loader';
import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import ReactGA from 'react-ga';

import Loading from '@components/loading/loading';
import { getProfile } from '@selectors/profile.selectors';
import { LoginContainer } from '@routes/auth';
import PrivateRoute from '@src/components/PrivateRoute';

import { routes } from '@routes/routes.manifest';

import '@styles/app.scss';

import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import '@styles/foundation.min.css';

import Cookies from 'js-cookie';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faThumbsUp,
  faPlus,
  faUser,
  faCalendar,
  faCalendarAlt,
  faHourglass,
  faChevronUp,
  faUserCheck,
  faChevronDown,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel
);

import { hot } from 'react-hot-loader/root';
import MyEmployees from '@routes/my-employees/MyEmployees';

import { version, buildHash } from '@src/config';
import MyProfile from '@routes/my-profile/MyProfile';
import PersonalImprovement from '@routes/personal-improvement/PersonalImprovement';
import ScrollToTop from '@components/scroll-to-top-button/scroll-to-top-button';

import { profileFetchPerson } from '@actions/profile.actions';
import Dashboard from '@routes/dashboard/Dashboard';
import { setDefaultLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import { ContextProvider } from './Context';

setDefaultLocale(nb);

const App = ({
  sessionId,
  profile,
  alert,
}) => (
  <Router basename={process.env.REACT_APP_MOUNT_PATH}>
    <>
      {((!Cookies.get('tg-visit') || !sessionId || profile.error) && (
        <LoginContainer />
      )) || (
        <>
          <ContextProvider>
            <Menu />
            <ScrollToTop appearWhenScrolledPx={480} />
            <div className="main-content">
              <Switch>
                <PrivateRoute path={routes.dashboard} component={Dashboard} />
                <PrivateRoute path={routes.myProfile} component={MyProfile} />
                <PrivateRoute
                  path={routes.personalImprovement}
                  component={PersonalImprovement}
                />
                <PrivateRoute
                  path={routes.myEmployees}
                  component={MyEmployees}
                />
                <Redirect to={routes.personalImprovement} />
              </Switch>
              {(version || buildHash) && (
                <div
                  style={{
                    left: '10px',
                    bottom: '35px',
                    opacity: '0.3',
                    padding: '10px',
                    fontSize: '0.8rem',
                  }}>
                  {`${buildHash} v. ${version}`}
                </div>
              )}
            </div>
          </ContextProvider>
          <Notifications />
        </>
      )}
      {alert.type === 'alert-reloading' && <Loading />}
    </>
  </Router>
);

App.propTypes = {
  sessionId: PropTypes.string.isRequired,
  profile: PropTypes.shape({}).isRequired,
  alert: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  alert: state.alert,
  profile: getProfile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      profileFetchPerson,
    },
    dispatch
  );

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
