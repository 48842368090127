import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Seperator } from '@src/components/Layout';
/**
 * A sub-section of a InfoCollection
 */
const InfoSection = ({
  title,
  style,
  titleStyle,
  centerTitle,

  // eslint-disable-next-line react/prop-types
  children,
}) => {
  return (
    <Container style={style}>
      {title && (
        <>
          <Title centerTitle={centerTitle} style={titleStyle}>
            {title}
          </Title>{' '}
          <Seperator marginBottom="8" />
        </>
      )}
      {children}
    </Container>
  );
};

const Title = styled.h3`
  font-weight: 500;
  margin-bottom: 12px;
  ${props => (props.centerTitle ? 'left: 50%; position:relative;' : '')}
`;

const Container = styled.div`
  margin: 10px;
  width: 55%;
  margin-bottom: 40px;
`;

export default InfoSection;

InfoSection.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape({}),
  titleStyle: PropTypes.shape({}),
  centerTitle: PropTypes.bool,
};

InfoSection.defaultProps = {
  title: null,
  style: {},
  titleStyle: {},
  centerTitle: false,
};
