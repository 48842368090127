import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfoSection from '@routes/my-profile/InfoSection';
import { Section } from '@src/components/Layout';
import Table from '@src/components/Table';

const Wrapper = styled.div`
  background-color: #fafafa;
  padding-top: 50px;
  padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
  width: fit-content;
`;

const Description = styled.p`
  color: #464646;
  margin-bottom: 0;
`;
const StyleInfoSection = { width: '100%', marginBottom: '10px' };
const StyleTitle = { color: '#767676' };
const ClickableCell = styled.td`
  color: #1573e6;
  :hover {
    cursor: pointer;
  }
`;

const StyledTable = styled(Table)`
  margin-left: 10%;
  margin-right: 10%;

  @media screen and (max-width: 1600px) {
    margin-left: 60px;
    margin-right: 60px;
  }

  @media screen and (max-width: 1400px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const DoneTalksArchive = ({ talks }) => {
  return (
    <Wrapper>
      <Section>
        <ContentWrapper>
          <InfoSection
            title="Arkiv"
            style={StyleInfoSection}
            titleStyle={StyleTitle}>
            <Description>
              Arkivet består av tidligere gjennomførte utviklingssamtaler.
            </Description>
          </InfoSection>
        </ContentWrapper>
      </Section>
      <StyledTable
        highlightOddRows
        headings={[null, 'Ansvarlig', 'Gjennomført av']}>
        {talks.map(t => (
          <tr key={t.date}>
            <ClickableCell>{t.date}</ClickableCell>
            <td>{t.conductedBy.fullname}</td>
            <td>{t.responsible.fullname}</td>
          </tr>
        ))}
      </StyledTable>
    </Wrapper>
  );
};

DoneTalksArchive.propTypes = {
  talks: PropTypes.array.isRequired,
};

export default DoneTalksArchive;
