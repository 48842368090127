import * as cvActions from '@actions/cvs.actions';

const initialState = {
  isFetching: false,
  data: null,
  error: null,
  updated: null,
};

const cvsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;
    case cvActions.PROFILE_FETCH_CV_REQUEST:
      return {
        ...state,
        data: null,
        isFetching: true,
        updated: null,
      };
    case cvActions.PROFILE_FETCH_CV_FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload.error,
        updated: null,
      };

    case cvActions.PROFILE_EDIT_CV_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case cvActions.PROFILE_EDIT_CV_FAILURE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.payload.error,
        updated: null,
      };

    case cvActions.PROFILE_EDIT_CV_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
        updated: new Date(),
      };

    case cvActions.PROFILE_FETCH_CV_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
        updated: new Date(),
      };
    default:
      return state;
  }
};

export default cvsReducer;
