import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import FormInput from '@components/form-input';
import Button from '@src/components/Button';
import { Cell } from '@src/components/Layout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { profileEditPassword } from '@actions/profile.actions';

const isEmpty = string =>
  typeof string !== 'string' || string.trim().length <= 0;

const validate = values => {
  const errors = {};
  const {
    user_name,
    newPassword,
    repeatNewPassword,
    username,
    oldPassword,
  } = values;

  if (!/^[a-z0-9@._-]{3,}$/gi.test(user_name)) {
    errors.user_name =
      'Brukernavn kan ikke inneholde spesialsymboler eller mellomrom';
  }

  if (isEmpty(newPassword) && isEmpty(username) && isEmpty(oldPassword)) {
    const msg = 'Passord kan ikke være tomt';
    errors.newPassword = msg;
    errors.username = msg;
    return errors;
  }

  if (newPassword && newPassword.length) {
    if (newPassword.length < 8) {
      errors.newPassword = 'Passord må være mininum 8 tegn';
    }

    if (!/^[a-z0-9@._-]+$/gi.test(newPassword)) {
      errors.newPassword =
        'Passord kan ikke inneholde spesialsymboler eller mellomrom';
    }

    if (!oldPassword || !oldPassword.length) {
      errors.oldPassword = 'Må fylle inn gammelt passord';
    }

    if (repeatNewPassword !== newPassword) {
      errors.repeatNewPassword = 'Passordene er ikke like';
    }
  }

  return errors;
};

const InputsWrapper = styled(Cell)`
  :first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    :first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const ActionButtons = styled(Cell)`
  float: right;
  margin: 5px;
  margin-top: 25px;
  margin-bottom: 20px;
  color: red !important;
`;

const EditPasswordForm = ({
  onCancel,
  user_name,
  managerForm,
  handleSubmit,
  profileEditPassword,
}) => {
  const onSubmit = data => {
    profileEditPassword({ data });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      css="
      padding-top: 10px;
    ">
      {managerForm && (
        <>
          <div
            css="
          margin-bottom: 10px;
          color: #999;
          font-size: 0.8rem;

          strong {
            font-size: 0.9rem;
            color: black;
          }
        ">
            <h3
              css="
            margin: 30px 0 0;
            margin-top: 10px;
            color: initial;
            font-size: 1.2rem;
          ">
              Brukernavn
            </h3>
          </div>
          <Field
            component={FormInput}
            label=""
            name="user_name"
            defaultValue={user_name}
            type="text"
            css="
            padding: 0;

            label {
              color: #515254;
            }
          "
          />
        </>
      )}

      <h3
        css="
        margin: 30px 0 0;
        color: initial;
        font-size: 1.2rem;
      ">
        Passord
      </h3>
      <p className="edit-pass__description">
        Krav til passord: Minimum 8 tegn, bruke store og små bokstaver, samt
        tall eller tegn. Vær oppmerksom på at bokstavene æ-ø-å ikke fungerer som
        tegn i et passord.
      </p>

      {!managerForm && (
        <InputsWrapper
          css={`
            margin-bottom: 20px;

            .form-input {
              width: 100%;

              @include sm() {
                width: 41%;
              }
            }
          `}>
          <Field
            className="edit-pass__input"
            component={FormInput}
            label="Gammelt passord"
            name="oldPassword"
            type="password"
            touched
          />
        </InputsWrapper>
      )}

      <InputsWrapper>
        <Field
          className="edit-pass__input"
          component={FormInput}
          label="Nytt passord"
          name="newPassword"
          type="password"
        />
        <Field
          className="edit-pass__input"
          component={FormInput}
          label="Gjenta nytt passord"
          name="repeatNewPassword"
          type="password"
        />
      </InputsWrapper>
      <div
        css="
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      ">
        <ActionButtons>
          <Button secondary onClick={onCancel}>
            Avbryt
          </Button>
          <Button action type="submit">
            Lagre
          </Button>
        </ActionButtons>
      </div>
    </form>
  );
};

EditPasswordForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  managerForm: PropTypes.bool.isRequired,
  profileEditPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      profileEditPassword,
    },
    dispatch
  );

export default reduxForm({
  form: 'resetpassword',
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditPasswordForm)
);
