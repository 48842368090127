import * as competencesActions from '@actions/competences.actions';

const initialState = {
  competences: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  personcompetences: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
};

const competencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STORE':
      return initialState;

    // /////////////////////////////////////////////
    case competencesActions.PROFILE_FETCH_PERSONCOMPETENCES_REQUEST:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: true,
          updated: null,
        },
      };
    case competencesActions.PROFILE_FETCH_PERSONCOMPETENCES_FAILURE:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.PROFILE_FETCH_PERSONCOMPETENCES_SUCCESS:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: action.payload.data,
          isFetching: false,
          updated: new Date(),
        },
      };
    // /////////////////////////////////////////////
    case competencesActions.PROFILE_EDIT_PERSONCOMPETENCE_REQUEST:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: true,
        },
      };

    case competencesActions.PROFILE_EDIT_PERSONCOMPETENCE_FAILURE:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.PROFILE_EDIT_PERSONCOMPETENCE_SUCCESS:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: action.payload.data,
          isFetching: false,
          updated: new Date(),
        },
      };

    // /////////////////////////////////////////////
    case competencesActions.PROFILE_ADD_PERSONCOMPETENCE_REQUEST:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: true,
        },
      };

    case competencesActions.PROFILE_ADD_PERSONCOMPETENCE_FAILURE:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.PROFILE_ADD_PERSONCOMPETENCE_SUCCESS:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: action.payload.data,
          isFetching: false,
          updated: new Date(),
        },
      };
    // /////////////////////////////////////////////
    case competencesActions.PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_REQUEST:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: true,
        },
      };

    case competencesActions.PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_FAILURE:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.PROFILE_ADD_MULTIPLE_PERSONCOMPETENCES_SUCCESS:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: false,
          updated: new Date(),
        },
      };

    // /////////////////////////////////////////////
    case competencesActions.PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_REQUEST:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: true,
        },
      };

    case competencesActions.PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_FAILURE:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.PROFILE_EDIT_MULTIPLE_PERSONCOMPETENCES_SUCCESS:
      return {
        ...state,
        personcompetences: {
          ...state.personcompetences,
          isFetching: false,
          updated: new Date(),
        },
      };
    // /////////////////////////////////////////////
    case competencesActions.FETCH_COMPETENCES_REQUEST:
      return {
        ...state,
        competences: {
          ...state.competences,
          isFetching: true,
        },
      };

    case competencesActions.FETCH_COMPETENCES_FAILURE:
      return {
        ...state,
        competences: {
          ...state.competences,
          data: null,
          isFetching: false,
          error: action.payload.error,
          updated: null,
        },
      };

    case competencesActions.FETCH_COMPETENCES_SUCCESS:
      return {
        ...state,
        competences: {
          ...state.competences,
          data: action.payload.data,
          isFetching: false,
          updated: new Date(),
        },
      };
    default:
      return state;
  }
};

export default competencesReducer;
