import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Seperator } from '@src/components/Layout';

/**
 * A container for displaying its child elements with a title and a border beneath the title.
 *
 * A action-button can be provided and will be placed at the top right of the container
 */
const InfoCollection = ({
  title,
  actionButton = null,
  children,
  style,
  titleStyle,
  centerTitle,
  missingContentComponent,
}) => {
  return (
    <Container style={style}>
      {title && (
        <Title centerTitle={centerTitle} style={titleStyle}>
          {title}
        </Title>
      )}
      <EditButtonCont>{actionButton}</EditButtonCont>
      {title && <Seperator />}
      {children ||
        (missingContentComponent && (
          <MissingContentCont>{missingContentComponent}</MissingContentCont>
        ))}
    </Container>
  );
};

const MissingContentCont = styled.div`
  color: #494949;
`;

const EditButtonCont = styled.span`
  padding: 4px;
  float: right;
`;

const Title = styled.h2`
  display: inline-block;
  margin-top: 0;
  font-weight: 500;
  ${props => (props.centerTitle ? 'left: 50%; position:relative;' : '')}
`;

const Container = styled.div`
  margin: 10px;
`;

export default InfoCollection;

InfoCollection.propTypes = {
  title: PropTypes.string,
  actionButton: PropTypes.element,
  missingContentComponent: PropTypes.element,
  style: PropTypes.shape({}),
  titleStyle: PropTypes.shape({}),
  centerTitle: PropTypes.bool,
  children: PropTypes.node,
};

InfoCollection.defaultProps = {
  title: null,
  actionButton: null,
  missingContentComponent: null,
  style: {},
  titleStyle: {},
  centerTitle: false,
  children: null,
};
