const prefix = '[cvs]';

export const PROFILE_FETCH_CV = `${prefix}/cvs/get/fetch`;
export const PROFILE_FETCH_CV_REQUEST = `${prefix}/cvs/get/request`;
export const PROFILE_FETCH_CV_FAILURE = `${prefix}/cvs/get/failure`;
export const PROFILE_FETCH_CV_SUCCESS = `${prefix}/cvs/get/success`;

export const PROFILE_EDIT_CV = `${prefix}/cvs/edit/edit`;
export const PROFILE_EDIT_CV_REQUEST = `${prefix}/cvs/edit/request`;
export const PROFILE_EDIT_CV_FAILURE = `${prefix}/cvs/edit/failure`;
export const PROFILE_EDIT_CV_SUCCESS = `${prefix}/cvs/edit/success`;

export const profileFetchCV = payload => ({
  type: PROFILE_FETCH_CV,
  payload,
});

export const profileEditCV = payload => ({
  type: PROFILE_EDIT_CV,
  payload,
});

export const profileEditCVRequest = payload => ({
  type: PROFILE_EDIT_CV_REQUEST,
  payload,
});

export const profileEditCVFailure = payload => ({
  type: PROFILE_EDIT_CV_REQUEST,
  payload,
});

export const profileEditCVSuccess = payload => ({
  type: PROFILE_EDIT_CV_SUCCESS,
  payload,
});

export const profileFetchCVRequest = payload => ({
  type: PROFILE_FETCH_CV_REQUEST,
  payload,
});

export const profileFetchCVFailure = payload => ({
  type: PROFILE_FETCH_CV_FAILURE,
  payload,
});

export const profileFetchCVSuccess = payload => ({
  type: PROFILE_FETCH_CV_SUCCESS,
  payload,
});
