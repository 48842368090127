import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import './notifications.scss';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notification from './notification';
import { getShownNotifications } from '../../../store/selectors/notifications.selectors';
import { notificationsHide } from '../../../store/actions/notifications.actions';

const Notifications = ({ notifications, hideNotification }) =>
  createPortal(
    <div className="notifications">
      <div className="notifications__wrapper">
        {notifications.map(
          ({ title, text, closeAfterMillis, redirectTo, color, id }, idx) => (
            <Notification
              title={title}
              closeAfterMillis={closeAfterMillis}
              color={color}
              onClose={() =>
                hideNotification({ notification: notifications[idx] })
              }
              key={`notification-${id}`}
              redirectTo={redirectTo}>
              {text}
            </Notification>
          )
        )}
      </div>
    </div>,
    document.getElementById('notifications')
  );

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: getShownNotifications(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideNotification: notificationsHide,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
