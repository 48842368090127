import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from '@src/utils/date.utils';
import styled, { css } from 'styled-components';
import { notificationsAdd } from '@actions/notifications.actions';
import ProfileLogo from '@components/profile-logo/profile-logo';
import { bindActionCreators } from 'redux';
import Button from '@src/components/Button';
import { Row, Column, Label, Cell } from '@src/components/Layout';
import { Form } from '@rocketseat/unform';
import { DatePicker } from '@src/components/Input';
import Modal from '@src/components/Modal';
import { Context } from '../../Context';

const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: #f3f4f4;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const ExpandShrink = ({ expand }) => {
  const Icon = styled.i`
    color: #162020;
  `;

  return (
    <IconWrapper role="button">
      <Icon
        className={expand ? 'fas fa-sort-down' : 'fas fa-sort-up'}
        title={
          expand
            ? 'Vis detaljer om utvilkignssamtale for medarbeider'
            : 'Skjul detaljer om utviklingssamtale for medarbeider'
        }
      />
    </IconWrapper>
  );
};

const EmploeeTalkInfo = ({ employee, onStartTalk, notificationsAdd }) => {
  if (!employee) {
    console.trace('employee is', employee, '???');
    return null;
  }

  const { getEmployee } = useContext(Context);
  const { lastTalkDate, nextTalkDate, setTalkdate } = getEmployee(
    employee.user_name
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [newPlanTalkDate, setNewPlanTalkDate] = useState(nextTalkDate || null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onSuccessChangedTalkDate = () => {
    const updatedExitingDate = nextTalkDate;
    setTalkdate({ nextTalkDate: newPlanTalkDate });
    if (updatedExitingDate) {
      notificationsAdd({
        notification: {
          text: 'Tidspunkt for samtalen er oppdatert.',
          color: 'green',
        },
      });
    } else {
      notificationsAdd({
        notification: {
          text: 'Innkalelse er sendt.',
          color: 'green',
        },
      });
    }
    setIsExpanded(false);
  };

  const onSubmitPlanTalk = () => {
    onSuccessChangedTalkDate();
  };

  const onChangePlanTalkDate = date => {
    // avoid recursive or useless updates
    if (!date) {
      return;
    }

    if (!newPlanTalkDate || date !== newPlanTalkDate) {
      setNewPlanTalkDate(date);
    }
  };

  const updatedDate =
    newPlanTalkDate &&
    nextTalkDate &&
    newPlanTalkDate !== new Date(nextTalkDate);

  const onClickDeleteButton = () => {
    setShowDeleteConfirmation(true);
  };

  const onCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const onConfirmDelete = () => {
    notificationsAdd({
      notification: {
        text: 'Samtalen er avlyst.',
        color: 'green',
        textColor: 'dark',
      },
    });
    setTalkdate({ nextTalkDate: null });
    setShowDeleteConfirmation(false);
    setIsExpanded(false);
    setNewPlanTalkDate(null);
    setIsExpanded(false);
  };

  const hoursSet = date => date.getHours() !== 0 || date.getMinutes() !== 0;

  const dateString = date => {
    const newDate = date && new Date(date);
    return (
      (newDate &&
        format(
          newDate,
          hoursSet(newDate) ? "dd.MM.yyyy'/'HH:mm" : 'dd.MM.yyyy'
        )) ||
      null
    );
  };

  const datePresentation = date => {
    const dateFormatted = dateString(date);
    if (dateFormatted) {
      return dateFormatted.split('/').map((date, key) => (
        <Label
          key={key}
          bold={key === 0}
          marginLeft={(key === 1 && 'auto') || '0'}>
          {date}
        </Label>
      ));
    }
    return null;
  };

  return (
    <Wrapper>
      <SummaryWrapper isExpanded={isExpanded}>
        <PersonInfoAndLogoCont>
          <ProfileLogo person={employee} />
          <PersonInfoCont>
            <EmployeeName>{employee.fullname}</EmployeeName>
          </PersonInfoCont>
        </PersonInfoAndLogoCont>

        <TalkInfoCont>
          <TalkInfo>
            <TalkInfoHeader>Siste samtale</TalkInfoHeader>
            <TalkInfoDescription>
              {datePresentation(lastTalkDate) || 'Ingen'}
            </TalkInfoDescription>
          </TalkInfo>
          <TalkInfo>
            <TalkInfoHeader>Neste samtale</TalkInfoHeader>
            <TalkInfoDescription>
              {datePresentation(nextTalkDate) || '' || 'Ikke planlagt'}
            </TalkInfoDescription>
          </TalkInfo>
        </TalkInfoCont>
        <TalkActions>
          {nextTalkDate && (
            <>
              <ActionRow>
                <ActionButton
                  action
                  onClick={() => onStartTalk(employee)}
                  start>
                  Start samtale
                </ActionButton>
              </ActionRow>
              {!isExpanded && (
                <EditTalkIconWrapper
                  title="Endre samtale"
                  role="button"
                  onClick={() => setIsExpanded(true)}>
                  <EditTalkIcon className="fas fa-pencil-alt" />
                </EditTalkIconWrapper>
              )}
              {isExpanded && (
                <EditTalkIconWrapper
                  role="button"
                  title="Lukk redigering"
                  onClick={() => setIsExpanded(false)}>
                  <EditTalkIcon className="fas fa-times" />
                </EditTalkIconWrapper>
              )}
            </>
          )}
          {!nextTalkDate && (
            <>
              <ActionRow>
                {!isExpanded && (
                  <ActionButton action onClick={() => setIsExpanded(true)} plan>
                    Planlegg samtale
                  </ActionButton>
                )}
                {isExpanded && (
                  <ActionButton
                    action
                    onClick={() => setIsExpanded(false)}
                    plan>
                    Lukk
                  </ActionButton>
                )}
              </ActionRow>
              <EditTalkIconWrapper placeholderColumn aria-hidden="true" />
            </>
          )}
        </TalkActions>
      </SummaryWrapper>

      {isExpanded && (
        <DetailsWrapper>
          <ContentWrapper>
            <LeftContDetails>
              <HeaderPersonalImprovement>
                Utviklingssamtale
              </HeaderPersonalImprovement>
              <Column>
                <Label color="#6a6a6a" marginBottom="12px">
                  {nextTalkDate && 'Endre tidspunkt for samtale'}
                  {!nextTalkDate && 'Sett opp tidspunkt for samtale'}
                </Label>
                <Row>
                  <Column>
                    <Form
                      onSubmit={onSubmitPlanTalk}
                      initialData={{
                        date: nextTalkDate ? new Date(nextTalkDate) : null,
                      }}
                      autofill="off"
                      autoComplete="off">
                      <DatePickerAndDeleteCont>
                        <DatePicker
                          name="date"
                          label="Tidspunkt"
                          showTimeInput
                          timeIntervals={15}
                          onChange={onChangePlanTalkDate}
                          value={newPlanTalkDate}
                        />
                        {nextTalkDate && (
                          <DeleteIconWrapper
                            role="button"
                            onClick={onClickDeleteButton}>
                            <DeleteIcon className="far fa-trash-alt" />
                          </DeleteIconWrapper>
                        )}
                      </DatePickerAndDeleteCont>
                      {updatedDate && nextTalkDate && newPlanTalkDate && (
                        <ActionButton action type="submit" whiteText>
                          Oppdater tidspunkt
                        </ActionButton>
                      )}

                      {newPlanTalkDate && !nextTalkDate && (
                        <ActionButton action type="submit" whiteText>
                          Send innkallelse
                        </ActionButton>
                      )}
                    </Form>
                  </Column>
                </Row>
              </Column>
            </LeftContDetails>
          </ContentWrapper>
        </DetailsWrapper>
      )}
      {showDeleteConfirmation && (
        <Modal closable onClose={onCancelDelete} width="520px">
          <Label marginBottom="30px" css="font-weight: 450;" size="1.4em">
            Er du sikker på at du vil avlyse samtalen?
          </Label>
          <Label color="#464646" marginBottom="45px">
            Samtale med{' '}
            <Label inline bold>
              {employee.fullname}
            </Label>
            <Label marginTop="0.5em" color="#464646" marginBottom="45px">
              {format(
                newPlanTalkDate || new Date(nextTalkDate),
                "dd.MM.yyyy 'kl.' HH:mm"
              )}
            </Label>
          </Label>
          <Row>
            <Button onClick={onCancelDelete}>Avbryt</Button>
            <Button warn onClick={onConfirmDelete}>
              Avlys samtale
            </Button>
          </Row>
        </Modal>
      )}
    </Wrapper>
  );
};

const ActionButton = styled(Button)`
    && {
      border-radius: 2px;
      padding: 8px;
      width: 163px;
      color: black;
      ${({ whiteText }) =>
        whiteText
          ? css`
              color: white;
            `
          : ''}
      display: block;
      border:none;
      height: 42px;
      ${({ plan, start }) =>
        plan || start
          ? css`
              width: 145px;
            `
          : ''}
      ${({ plan }) =>
        plan
          ? css`
              background-color: #f4c296;
              :hover {
                background-color: #e5b68a;
              }
              :active,
              :active:hover {
                background-color: #e5b68a;
              }
            `
          : ''}
      ${({ start }) =>
        start
          ? css`
              background-color: #aae8af;
              :hover {
                background-color: #9edda3;
              }
              :active,
              :active:hover {
                background-color: #9edda3;
              }
            `
          : ''}

      ${({ disabled }) =>
        disabled
          ? css`
              color: gray;
              background-color: #f4f4f4;

              :hover {
                color: gray;
                background-color: #f4f4f4;
              }
            `
          : ''}
    }
  `;

const DeleteButton = styled(ActionButton)`
  && {
    border: none;
    color: white;
    background-color: #e20e0e;

    :hover {
      background-color: #cb1c1c;
    }
  }
`;

const IconWrapperBase = styled.div`
  background-color: #ecf4fd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

const DeleteIconWrapper = styled(IconWrapperBase)`
  position: relative;
  bottom: 21px;
  margin-left: 8px;
  align-self: flex-end;
`;

const EditTalkIconWrapper = styled(IconWrapperBase)`
  margin-left: 45px;
  align-self: center;
  ${({ placeholderColumn }) =>
    placeholderColumn
      ? css`
          visibility: hidden;
        `
      : ''}
`;

const EditTalkIcon = styled.i`
  color: gray;
`;

const DeleteIcon = styled.i`
  color: red;
`;

const DatePickerAndDeleteCont = styled.div`
  flex-direction: row;
  display: flex;
`;

const TalkActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionRow = styled.div`
  width: 150px;
  align-self: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftContDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const borderColor = '#eaebed';

const EmployeeName = styled.h4`
  align-self: center;
  width: 100%;
`;

const Wrapper = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  @media screen and (max-width: 1150px) {
    ${EmployeeName} {
      width: 74px;
    }
  }

  @media screen and (max-width: 850px) {
    ${EditTalkIconWrapper} {
      margin-left: 10px;
    }

    ${ActionButton}, ${DeleteButton} {
      width: 110px;
      white-space: pre-line;
      flex-basis: auto;
      height: 50px;
    }
  }
`;

const HeaderPersonalImprovement = styled.h5`
  && {
    font-size: 1.5em;
    margin-bottom: 15px;
    font-weight: 450;
  }
`;

const DetailsWrapper = styled.div`
  border: 1px solid ${borderColor};
  height: 400px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 25px;
  position: relative;
  background-color: white;
`;

const TalkInfoCont = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
`;

const TalkInfo = styled.div`
  margin-right: 8%;
  width: 50%;
  position: relative;

  :last-child {
    margin-right: 25px;
  }
`;

const PersonInfoCont = styled.div`
  flex-direction: column;
  margin-left: 25px;
  justify-content: center;
  display: flex;
`;
const PersonInfoAndLogoCont = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  height: fit-content;
  align-self: center;
`;
const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #191919;
  padding: 15px 15px;
  border: 1px solid #ffffff00; /*Invisible*/

  /*Make the shadow appear in front of other elements*/
  z-index: 2;
  position: relative;

  ${props =>
    props.isExpanded
      ? css`
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border: 1px solid ${borderColor};
          border-bottom: none;
        `
      : ''}

  @media screen and (max-width: 1150px) {
    ${TalkInfoCont} {
      width: 40%;
    }

    ${IconWrapper} {
      margin-left: 0;
    }

    ${PersonInfoAndLogoCont} {
      width: 30%;
    }

    ${PersonInfoCont} {
      margin-left: 8%;
    }
  }
`;

const TalkInfoHeader = styled.h5`
  font-weight: normal;
`;

const TalkInfoDescription = styled(Cell)`
  font-weight: bold;
  margin-right: 15px;
  width: fit-content;
`;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      notificationsAdd,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(EmploeeTalkInfo);

EmploeeTalkInfo.propTypes = {
  employee: PropTypes.shape({}).isRequired,
  onStartTalk: PropTypes.func.isRequired,
  notificationsAdd: PropTypes.func.isRequired,
};

EmploeeTalkInfo.defaultProps = {};

ExpandShrink.propTypes = {
  expand: PropTypes.bool.isRequired,
};
