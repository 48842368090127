import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormSubmitErrors, stopSubmit } from 'redux-form';

import * as authActions from '@actions/auth.actions';
import { Page, Section, Cell } from '@src/components/Layout';

import LoginForm from './LoginForm';
import ChangePwdForm from './ChangePwdForm';

import './styles/login-container.scss';

const isIE = false || !!document.documentMode;

class LoginContainer extends Component {
  state = {
    showSection: 'login',
  };

  handleForgot = ({ username }) => {
    const { authLoginNewPasswordRequest } = this.props;
    authLoginNewPasswordRequest({ username });
  };

  handleSelect = type => {
    const { authGotoNext } = this.props;
    authGotoNext(type);
  };

  handleLogin = ({ username, password }) => {
    const { authLoginRequest, stopSubmit } = this.props;
    authLoginRequest({ username, password });

    // Resets previous errrors in the login-form
    stopSubmit('login', {});
  };

  render() {
    const { forgotStatus, forgotStatusMsg, submitErrors } = this.props;
    const { showSection } = this.state;

    const loginFailure = submitErrors && submitErrors.errorMsg !== undefined;

    if (forgotStatus === 2 && showSection === 'forgot') {
      this.setState({ showSection: 'login' });
    }

    return (
      <Page title="Logg inn">
        <Section className="login-page__container">
          <Cell className="login-form">
            <div className="login_box">
              <div className="text">
                {
                  <>
                    <h2 className="login-page__title">NSF Utviklingssamtale</h2>

                    {isIE && (
                      <div className="red-warning">
                        Du er i en nettleser som ikke støttes. For å benytte
                        nettsiden må du åpne en annen nettleser, Chrome eller
                        Edge, utenfor Citrix-miljøet.
                      </div>
                    )}

                    <div className="login-page__form">
                      {forgotStatus !== -1 && <div>{forgotStatusMsg}</div>}
                      {(showSection === 'login' && (
                        <LoginForm
                          initialValues={{
                            username: '',
                            password: '',
                          }}
                          onSubmit={this.handleLogin}
                          invalidCredentials={loginFailure}
                          handleCancel={() =>
                            this.setState({ showSection: 'forgot' })
                          }
                        />
                      )) ||
                        (showSection === 'forgot' && (
                          <ChangePwdForm
                            initialValues={{
                              username: '',
                            }}
                            onSubmit={this.handleForgot}
                            handleCancel={() =>
                              this.setState({ showSection: 'login' })
                            }
                          />
                        ))}
                    </div>
                  </>
                }
              </div>
            </div>
          </Cell>
        </Section>
      </Page>
    );
  }
}

LoginContainer.propTypes = {
  forgotStatus: PropTypes.number,
  forgotStatusMsg: PropTypes.string,
  authLoginRequest: PropTypes.func.isRequired,
  authGotoNext: PropTypes.func.isRequired,
  authLoginNewPasswordRequest: PropTypes.func.isRequired,
  submitErrors: PropTypes.shape({}),
  stopSubmit: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
  forgotStatus: -1,
  forgotStatusMsg: undefined,
  submitErrors: {},
};

const mapStateToProps = state => ({
  forgotStatus: state.auth.newPwdStatus,
  forgotStatusMsg: state.auth.newPwd,
  submitErrors: getFormSubmitErrors('login')(state),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...authActions, stopSubmit }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
