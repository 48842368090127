import React from 'react';
import styled from 'styled-components';
import Button from '@src/components/Button';
import { createPortal } from 'react-dom';

const UnsavedChangesDisplay = ({ onSave }) => {
  const Wrapper = styled.div`
    background-color: #959595;
    color: white;
    font-size: 1.5em;
    position: fixed;
    top: 0;
    width: 93%;
    left: 124px;
    display: flex;
  `;

  const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `;

  const StyledButton = styled(Button)`
    height: 55px;
    font-size: 0.8em;
    /* padding: 10px 5px; */
    padding: 9px;
    margin-left: 10px;
    align-self: center;
  `;

  const UpdatedTxt = styled.p``;
  return createPortal(
    <Wrapper>
      <ContentWrapper>
        <UpdatedTxt>Du har endringer som ikke er lagret</UpdatedTxt>
        <StyledButton action onClick={onSave}>
          Lagre
        </StyledButton>
      </ContentWrapper>
    </Wrapper>,
    document.getElementById('unsavedChangesBanner')
  );
};

export default UnsavedChangesDisplay;
