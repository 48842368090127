import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { backendUrl } from '@config';
import axios from 'axios';
// import { getOrganisationId } from '@selectors/profile.selectors';
import {
  useAxiosRequest,
  useEasterEgg,
  useStateLocalStorage,
  useDebug,
} from './hooks/hooks';

export const Context = React.createContext({});

export const ContextProvider =
  // connect(state => ({
  //   orgId: getOrganisationId(state), // FIXME: conntecting provider to store breaks stuff.
  // }))(
  ({
    // orgId,
    children,
  }) => {
    const [competencegroups, setCompetencegroups] = useState({});
    const [competencetypes, setCompetencetypes] = useState({});

    const flattenCompetencegroups = groups =>
      groups
        ? groups.reduce(
            (obj, { id, title, children }) => ({
              ...obj,
              [id]: title,
              ...flattenCompetencegroups(children),
            }),
            {}
          )
        : {};

    const {
      data: { competencegroups: groups },
    } = useAxiosRequest(
      {
        method: 'GET',
        url: `${backendUrl}/api/competencegroups`,
        params: {
          fields: 'title,children(title)',
          competence_group_id: 1,
        },
        withCredentials: true,
      },
      []
    );
    useEffect(() => {
      setCompetencegroups(groups && flattenCompetencegroups(groups));
    }, [groups]);

    const {
      data: { competencetypes: types },
    } = useAxiosRequest(
      {
        method: 'GET',
        url: `${backendUrl}/api/competencetypes`,
        withCredentials: true,
      },
      []
    );
    useEffect(() => {
      setCompetencetypes(
        types &&
          types.reduce(
            (obj, { competence_type, title }) => ({
              ...obj,
              [competence_type]: title,
            }),
            {}
          )
      );
    }, [types]);

    const initialData = {
      employees: {
        erlendao: {
          firstname: 'Erlend',
          fullname: 'Erlend Østlie',
          lastname: 'Østlie',
          user_name: 'erlendao',
          email: 'erlend@mail.com',
          mobile: '94 939312',
          lastTalkDate: new Date('2018-12-04T10:00:00+02:00'),
        },
        perli02: {
          firstname: 'Per',
          fullname: 'Per Li',
          lastname: 'Li',
          user_name: 'perli02',
          email: 'perli96@hotmail.com',
          mobile: '94 911444',
          lastTalkDate: new Date('2019-03-21T14:15:00+01:00'),
          nextTalkDate: new Date('2019-12-17T15:30:00+01:00'),
        },
        jorgberg123: {
          firstname: 'Jørgen',
          fullname: 'Jørgen Berg',
          lastname: 'Berg',
          user_name: 'jorgberg123',
          email: 'jorgen.berg@mail.com',
          mobile: '23 939312',
          lastTalkDate: null,
          nextTalkDate: new Date('2019-12-10T09:00:00+01:00'),
        },
      },
    };

    const [store, setStore] = useStateLocalStorage('storeLocal', initialData);

    useDebug('store', store);

    useEasterEgg('reset', () => {
      setStore(initialData);
    });

    // eslint-disable-next-line no-unused-vars
    const reduceWithProperty = (objects, selector) =>
      objects.reduce(
        (rest, object) => ({
          ...rest,
          [selector(object)]: {
            ...rest[selector(object)],
            ...object,
          },
        }),
        {}
      );

    const API = baseUrl => {
      const request = method => (url, params) =>
        axios.request({
          method,
          params: params || {},
          url: `${baseUrl}${url}`,
          withCredentials: true,
        });
      return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
      };
    };

    const grape = API(backendUrl);

    const apiStore = (selector, keys) => {
      const navigate = (parent, key) => {
        if (!(key in parent)) {
          Object.assign(parent, {
            [key]: {},
          });
        }

        const current = key ? parent[key] : parent;

        return {
          get: key => navigate(current, key),
          current,
        };
      };

      const data = selector(navigate(store)).current;
      const setData = ({ ...value }) => {
        Object.assign(data, { ...value });
        setStore({ ...store });
      };

      return keys && keys({ data, setData });
    };

    const getEmployee = userName =>
      apiStore(
        store => store.get('employees').get(userName),
        ({ data, setData }) => ({
          ...data,
          editProfile: (fields, callback) =>
            grape
              .put(`/api/persons/${data.user_name}`, fields)
              .then(response => callback && callback(response)),
          setTalkdate: ({ ...values }) => {
            setData({
              ...values,
            });
          },
        })
      );

    const employees = apiStore(
      store => store.get('employees'),
      ({ data /* , setData */ }) => ({
        list: Object.values(data),
        fetch: () => /*  // see note above
          grape
            .get(`/api/organisations/${orgId}/persons`, {
              fields:
                'user_name,email,phone,mobile,fullname,firstname,lastname,positions(role_id,title,organisation_id),profile_image,data(avatar),tasks,is_absent',
              limit: 500,
            })
            .then(({ data: { persons } }) => {
              setData(reduceWithProperty(persons, person => person.user_name));
            }),
          */ {},
      })
    );

    return (
      <Context.Provider
        value={{ competencegroups, competencetypes, getEmployee, employees }}>
        {children}
      </Context.Provider>
    );
  }; // );

ContextProvider.propTypes = {
  // orgId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
