import PropTypes from 'prop-types';
import React from 'react';
import { Input } from '@rocketseat/unform';
import styled, { css } from 'styled-components';

const LabeledInput = ({ label, name, type, isRequired, ...props }) => {
  const Label = styled.label`
    display: flex;
    flex-direction: column;
    margin: 5px;
    color: #515254;

    /*Error messages from field validation*/
    span:nth-child(3) {
      color: #ec2e35;
    }
  `;

  const LabelTxt = styled.span`
    ${({ isRequired }) =>
      isRequired
        ? css`
            ::after {
              content: '*';
            }
          `
        : ''}
  `;

  const StyledInput = styled(Input)`
    border: 1px solid #a5a5a5;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    margin-top: 3px;

    :focus {
      box-shadow: inset 0 1px 0 0 #e3edfc, 0 0 0 4px #e3edfc;
    }
  `;

  return (
    <>
      <Label isRequired={isRequired}>
        <LabelTxt
          isRequired={isRequired}
          aria-label={isRequired ? 'Påkrevd felt' : ''}>
          {label}
        </LabelTxt>
        <StyledInput name={name} type={type} {...props} />
      </Label>
    </>
  );
};

export default LabeledInput;

LabeledInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
};

LabeledInput.defaultProps = {
  type: 'text',
};
