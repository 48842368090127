/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import EmploeeTalkInfo from '@routes/personal-improvement/EmployeeTalkInfo';
import { Seperator, Section, Cell } from '@src/components/Layout';
import { Context } from '@src/Context';
import { getProfile } from '../../store/selectors/profile.selectors';

const EmployeesList = ({ profile, onEmployeeTalkSelect }) => {
  const Header = styled.h1``;

  const {
    employees: { list, fetch },
    getEmployee,
  } = useContext(Context);

  const tabs = {
    tabAll: {
      header: 'Alle medarbeidere',
      tabHeader: 'Alle medarbeidere',
      filter: () => true,
    },
    tabPlanned: {
      header: 'Medarbeidere du har planlagt samtale med',
      tabHeader: 'Planlagt',
      filter: e => {
        return e.user_name && getEmployee(e.user_name).nextTalkDate;
      },
    },
    tabNotPlanned: {
      header: 'Medarbeidere du ikke har planlagt samtale med',
      tabHeader: 'Ikke planlagt',
      filter: e => {
        return e.user_name && !getEmployee(e.user_name).nextTalkDate;
      },
    },
  };

  const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);

  const Tab = styled.button`
    border: none;
    padding: 10px;
    margin-right: 10px;
    padding-bottom: 15px;
    border-radius: 0;
    border-bottom: 2px solid white;
    :hover {
      cursor: pointer;
    }
    ${props =>
      // eslint-disable-next-line react/prop-types
      props.active
        ? css`
            font-weight: bold;
            border-bottom: 2px solid #191919;
          `
        : ''}
  `;

  const TabsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  `;

  const FullWidthSeparator = styled(Seperator)``;

  const LoadingCont = styled.div`
    font-size: 1.5em;
    position: relative;
    top: 20%;
    transform: translate(0, -50%);
    text-align: center;
    left: 12px;
    margin-top: 32px;
  `;

  const TopPartWrapper = styled.div`
    margin-top: 12px;
  `;

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Cell>
      <TopPartWrapper>
        <Section>
          <Header>{tabs[activeTab].header}</Header>
        </Section>
        <FullWidthSeparator />
        <Section>
          <TabsWrapper>
            {Object.keys(tabs).map(key => (
              <Tab active={key === activeTab} onClick={() => setActiveTab(key)}>
                {tabs[key].tabHeader}
              </Tab>
            ))}
          </TabsWrapper>
        </Section>
        <FullWidthSeparator />
      </TopPartWrapper>

      <Section>
        <Cell marginTop="16px">
          {list
            .filter(e => e.user_name)
            .filter(e => profile.data && e.user_name !== profile.data.user_name)
            .filter(tabs[activeTab].filter)
            .map(
              e =>
                e && (
                  <EmploeeTalkInfo
                    key={e.user_name}
                    onStartTalk={employee => onEmployeeTalkSelect(employee)}
                    employee={e}
                  />
                )
            )}
          {(list.length === 0 || !profile.data) && (
            <LoadingCont>
              <i className="fa fa-spin fa-spinner" /> Laster...
            </LoadingCont>
          )}
        </Cell>
      </Section>
    </Cell>
  );
};

export default connect(state => ({
  profile: getProfile(state),
}))(EmployeesList);

EmployeesList.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  onEmployeeTalkSelect: PropTypes.func.isRequired,
};

EmployeesList.defaultProps = {};
