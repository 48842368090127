import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './modal.scss';
import { getIsMobile } from '@selectors/global.selectors';
import styled from 'styled-components';

const ModalCloseButton = styled.button`
  position: absolute;
  margin-top: 25px;
  margin-right: 25px;
  font-weight: 550;
  font-size: 1.1em;
  color: #555555;
  padding: 4px;
  border: 1px solid white;
  right: 0;
  z-index: 2;
  ::after {
    content: '✕';
  }
  :hover {
    cursor: pointer;
    border: 1px solid #eaebed;
    color: #1573e6;
  }
`;

const Modal = connect(state => ({
  isMobile: getIsMobile(state),
}))(
  ({
    isMobile,
    children,
    onBackdropClick,
    onClose,
    className,
    classNameContent,
    dataLoaded,
    waitForLoadedDataWithAnimation,
    contentContStyles,
  }) => {
    useEffect(() => {
      const onKeyUp = e => {
        if (e.code === 'Escape') {
          // eslint-disable-next-line no-use-before-define
          componentCleanup();
          onClose();
        }
      };

      const componentCleanup = () => {
        window.removeEventListener('keyup', onKeyUp);
      };

      window.addEventListener('keyup', onKeyUp);

      return function cleanup() {
        componentCleanup();
      };
    }, []);

    const LoadingStyles =
      waitForLoadedDataWithAnimation && !dataLoaded ? { display: 'none' } : {};

    return createPortal(
      <div
        className={classNames('modal', className, {
          'modal--mobile': isMobile,
        })}
        role="dialog">
        {/* eslint-disable-next-line */}
        <div className="modal__backdrop" onClick={onBackdropClick} />
        {waitForLoadedDataWithAnimation && !dataLoaded && <LoadingIndicator />}
        <div
          className={classNames('modal__content', classNameContent)}
          style={{ ...LoadingStyles, ...contentContStyles }}>
          <ModalCloseButton
            aria-label="Lukk modal"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          />
          {children}
        </div>
      </div>,
      document.getElementById('modal')
    );
  }
);

const LoadingIndicator = () => {
  const maxLoadingState = 3;
  const loadingChar = '.';
  const [loadingState, setLoadingState] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState(loadingState =>
        loadingState >= maxLoadingState ? 0 : loadingState + 1
      );
    }, 500);

    return () => clearInterval(interval);
  }, []);
  return (
    <p className="modal__loading-text">
      Laster {loadingChar.repeat(loadingState)}
    </p>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  onBackdropClick: PropTypes.func,
  onClose: PropTypes.func,
  contentContStyles: PropTypes.shape({}),
  // if the data for the modal is loaded
  dataLoaded: PropTypes.bool,

  // if true - displays a loading animation untill dataLoaded is true and then opens the modal
  waitForLoadedDataWithAnimation: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  classNameContent: null,
  onBackdropClick: () => {},
  dataLoaded: false,
  waitForLoadedDataWithAnimation: false,
  contentContStyles: {},
};

export default Modal;
