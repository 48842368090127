import React, { useEffect, useState } from 'react';
import PersonInformation, {
  PersonaliaEntity,
  PersonaliaTxt,
} from '@components/person-information/person-information';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { profileFetchPerson } from '@actions/profile.actions';
import { getProfile } from '@selectors/profile.selectors';
import PropTypes from 'prop-types';
import CVManage from '@routes/my-profile/CVManage';
import DoneTalksArchive from '@routes/my-profile/DoneTalksArchive';
import { Link, Element } from 'react-scroll';
import Modal from '@components/modal/modal';
import { Page, Section, Row, Seperator } from '@src/components/Layout';
import { profileFetchCV } from '@actions/cvs.actions';
import { getCV } from '@selectors/cvs.selectors';
import {
  getPersoncompetences,
  getCompetences,
} from '@selectors/competences.selectors';
import { Redirect } from 'react-router-dom';
import {
  profileFetchPersoncompetences,
  fetchCompetences,
} from '@actions/competences.actions';
import EditPasswordForm from './EditPasswordForm';
import ProfileEdit from './ProfileEdit';
import AgreementsWaitingForSign from './AgreementsWaitingForSign';
import AgreementsForSigningWarning from './AgreementsForSigningWarning';
import CompetenceManage from './CompetenceManage';

export const [viewModeView, viewModeEdit] = ['viewModeView', 'viewModeEdit'];
const MyProfile = ({
  profile,
  getProfileData,
  personcompetences,
  getCompetencesData,
  competences,
  cv,
  getCVData,
  getPersonCompetencesData,
}) => {
  useEffect(() => {
    getProfileData();
    getCVData();
    getPersonCompetencesData();
  }, []);

  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [isProfileEditModalOpen, setProfileEditModalOpen] = useState(false);
  const [CVManageMode, setCVManageMode] = useState(viewModeView);
  const [competenceManageMode, setCompetenceManageMode] = useState(
    viewModeView
  );

  const [viewModeCV, viewModeCompetence, viewModeAll] = [
    'viewModeCv',
    'viewModeCompetence',
    'viewModeAll',
  ];

  const [viewMode, setViewMode] = useState(viewModeAll);

  const [agreementToNavigateTo, setAgreementToNavigateTo] = useState(null);
  const agreements = [
    {
      date: '21 februar 2019',
      conductedBy: {
        fullname: 'Arne Sjef',
        firstname: 'Arne',
        lastname: 'Sjef',
        user_name: 'sjef02',
      },
      tasks: [[{"startdate":"2019-10-10 00:00:00","enddate":"2019-10-10 00:00:00","title":"AI i den gamle verden","location":{"city":null,"capacity":null,"description":null,"title":"Fredrikstad","longitude":null,"postcode":null,"address":"Testaddresse","latitude":null,"location_id":62,"id":62},"competence_id":2939,"id":52651},{"startdate":"2019-10-10 00:00:00","enddate":"2019-10-10 00:00:00","title":"AI i den gamle verden","location":{"city":null,"capacity":null,"description":null,"title":"Fredrikstad","longitude":null,"postcode":null,"address":"Testaddresse","latitude":null,"location_id":62,"id":62},"competence_id":2939,"id":52651},"Hans Testli"],["eksempeloppgave1",null,"Begge"],["eksempeloppgave2","2019-09-11T11:30:00.000Z","Hans Testli"]],
      responsible: [
        {
          fullname: 'Erlend',
          firstname: 'Erlend',
          lastname: 'Østlie',
        },
        { fullname: 'Hans' },
      ],
    },
    {
      date: '22 februar 2019',
      conductedBy: {
        fullname: 'Ole Leder',
        user_name: 'leder75',
      },
      tasks: [[{"startdate":"2019-10-10 00:00:00","enddate":"2019-10-10 00:00:00","title":"AI i den gamle verden","location":{"city":null,"capacity":null,"description":null,"title":"Fredrikstad","longitude":null,"postcode":null,"address":"Testaddresse","latitude":null,"location_id":62,"id":62},"competence_id":2939,"id":52651},{"startdate":"2019-10-10 00:00:00","enddate":"2019-10-10 00:00:00","title":"AI i den gamle verden","location":{"city":null,"capacity":null,"description":null,"title":"Fredrikstad","longitude":null,"postcode":null,"address":"Testaddresse","latitude":null,"location_id":62,"id":62},"competence_id":2939,"id":52651},"Hans Testli"],["eksempeloppgave1",null,"Begge"],["eksempeloppgave2","2019-09-11T11:30:00.000Z","Hans Testli"]],
      responsible: [
        {
          fullname: 'Erlend',
        },
        { fullname: 'Hans' },
      ],
    },
  ];

  const onCVManageModeChange = () => {
    if (CVManageMode === viewModeEdit) {
      setCVManageMode(viewModeView);
      setViewMode(viewModeAll);
    } else {
      setCVManageMode(viewModeEdit);
      setViewMode(viewModeCV);
    }
  };

  const onCompetenceManageModeChange = () => {
    if (competenceManageMode === viewModeEdit) {
      setCompetenceManageMode(viewModeView);
      setViewMode(viewModeAll);
    } else {
      setCompetenceManageMode(viewModeEdit);
      setViewMode(viewModeCompetence);
      if (!competences.data) {
        getCompetencesData();
      }
    }
  };

  const TopPart = () => {
    const linkColor = '#1573e6';
    const Wrapper = styled.div`
      width: 100%;
      background-color: #f7f9fc;
      border: 2px solid #ebebeb;
    `;

    const OptionsContentWrapper = styled.div`
      display: grid;
      grid-template-areas:
        'userActions archiveCont'
        'competenceCVActions . ';
      padding-right: 25px;
      padding-bottom: 33px;

      @media screen and (max-width: 1200px) {
        grid-template-areas:
          'userActions'
          'competenceCVActions'
          'archiveCont';
      }
    `;
    const OptionsCont = styled.div`
      display: inline-block;
      vertical-align: top;
      background-color: #fafafa;
      width: 40%;
      height: 100%;
      border-left: 1px solid #ebebeb;
      padding-left: 20px;
      padding-right: 20px;
    `;
    const PersonInformationCont = styled.div`
      width: 60%;
      padding: 30px;
      display: inline-block;

      @media screen and (max-width: 1200px) {
        ${PersonaliaEntity} {
          width: 100%;
          height: 62px;

          ${PersonaliaTxt} {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-5%);
          }
        }
      }
    `;

    const Header = styled.h3`
      color: #767676;
    `;
    const Desc = styled.p`
      color: #7e7e7e;
      margin-top: 0;
      margin-bottom: 6px;
      margin-right: 11px;
    `;

    const ActionLink = styled.a`
      color: ${linkColor} !important;
      display: block;
    `;

    const UserActionsCont = styled.div`
      grid-area: userActions;
    `;
    const CompetenceCVActionsCont = styled.div`
      grid-area: competenceCVActions;
    `;
    const ArchiveCont = styled.div`
      grid-area: archiveCont;
    `;

    const SubHeader = styled.h4`
      color: #464646;
      margin-bottom: 8px;
    `;

    const LinkWrapper = styled.div`
      &:hover {
        cursor: pointer;
      }
    `;

    const linkStyle = {
      color: linkColor,
    };

    const ActionButton = styled.button`
      color: ${linkColor};
      border: none;
      display: block;
      line-height: 1.5;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `;

    const PersonInformationLoadingCont = styled.div`
      font-size: 1.5em;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;
    `;

    const closeEditPassword = () => {
      setPasswordModalOpen(false);
    };

    const closeProfileEdit = () => {
      setProfileEditModalOpen(false);
    };

    const openEditPassword = () => {
      setPasswordModalOpen(true);
    };

    const openProfileEdit = () => {
      setProfileEditModalOpen(true);
    };

    const openCVEdit = () => {
      onCVManageModeChange();
    };

    const openCompetenceEdit = () => {
      setCompetenceManageMode(viewModeEdit);
      setViewMode(viewModeCompetence);
      if (!competences.data) {
        getCompetencesData();
      }
    };

    const EditPasswordFormWrapper = styled.div`
      padding: 27px;
    `;

    const EditPasswordFormHeader = styled.h2`
      margin-bottom: 0;
      margin-top: 0;
    `;

    const ContentContStyle = {
      width: '50%',
    };

    const profileCardStyle = {
      marginBottom: '20px',
    };

    const ErrorIcon = styled.i`
      font-size: 1.5em;
      font-size: 2em;
      margin-right: 5px;
      position: relative;
      top: 9px;
    `;

    const AgreementsForSigningWarningWrapper = styled.div``;
    return (
      <Page title="Min profil">
        <Wrapper>
          <Section>
            <Row>
              <PersonInformationCont>
                {(profile.error && (
                  <>
                    <ErrorIcon className="fas fa-exclamation-circle" />
                    <span>Det oppsto en feil ved henting av dataen</span>
                  </>
                )) ||
                  ((!profile.data || profile.isFetching) && (
                    <PersonInformationLoadingCont>
                      <i className="fa fa-spin fa-spinner" /> Laster...
                    </PersonInformationLoadingCont>
                  )) || (
                    <PersonInformation
                      person={profile.data}
                      profileCardStyle={profileCardStyle}
                    />
                  )}

                {agreements && agreements.length > 0 && profile.data && (
                  <AgreementsForSigningWarningWrapper>
                    <AgreementsForSigningWarning
                      agreements={agreements}
                      profile={profile}
                    />
                  </AgreementsForSigningWarningWrapper>
                )}
              </PersonInformationCont>
              <OptionsCont>
                <Header>Valg</Header>
                <Seperator />

                <OptionsContentWrapper>
                  <UserActionsCont>
                    <SubHeader>Bruker</SubHeader>
                    <Desc>Innstillinger knyttet til din bruker.</Desc>
                    <ActionButton style={linkStyle} onClick={openProfileEdit}>
                      Rediger profil
                    </ActionButton>
                    <ActionButton onClick={openEditPassword}>
                      Endre passord
                    </ActionButton>
                  </UserActionsCont>

                  <CompetenceCVActionsCont>
                    <SubHeader>Kompetanse og CV</SubHeader>
                    <Desc>Valg knyttet til din CV og kompetanse-profil.</Desc>
                    <LinkWrapper>
                      <Link
                        to="competenceElem"
                        smooth
                        onClick={openCompetenceEdit}
                        duration={550}
                        style={linkStyle}>
                        Rediger kompetanse
                      </Link>
                    </LinkWrapper>
                    <ActionButton onClick={openCVEdit}>Rediger CV</ActionButton>
                    <ActionLink>
                      Last ned din CV og kompetanse-profil
                    </ActionLink>
                  </CompetenceCVActionsCont>

                  <ArchiveCont>
                    <SubHeader>Arkiv</SubHeader>
                    <Desc>Gjennomførte samtaler.</Desc>
                    <LinkWrapper>
                      <Link
                        to="archiveElem"
                        smooth
                        duration={450}
                        style={linkStyle}>
                        Gå til arkiv
                      </Link>
                    </LinkWrapper>
                  </ArchiveCont>
                </OptionsContentWrapper>
              </OptionsCont>
            </Row>
          </Section>
        </Wrapper>

        {isPasswordModalOpen && (
          <Modal
            onBackdropClick={closeEditPassword}
            onClose={closeEditPassword}>
            <div className="employee-edit__card employee-edit__modal">
              <EditPasswordFormWrapper>
                <EditPasswordFormHeader className="employee-edit__modal-title">
                  Endre passord
                </EditPasswordFormHeader>
                <EditPasswordForm
                  managerForm={false}
                  onCancel={closeEditPassword}
                />
              </EditPasswordFormWrapper>
            </div>
          </Modal>
        )}

        {isProfileEditModalOpen && (
          <Modal
            onBackdropClick={closeProfileEdit}
            onClose={closeProfileEdit}
            contentContStyles={ContentContStyle}>
            <ProfileEdit
              onCancel={closeProfileEdit}
              profile={profile}
              onDone={closeProfileEdit}
            />
          </Modal>
        )}
      </Page>
    );
  };

  const onGotoAgreementSigning = agreement => {
    setAgreementToNavigateTo(agreement);
  };

  // eslint-disable-next-line no-unused-vars
  const competenceData = [
    {
      name: 'Kurs(Forhandlingsbestemmelser)',
      level: 1,
      category: 'Administrasjon og koordingering',
    },
    {
      name: 'Test2',
      level: 2,
      category: 'Administrasjon og koordingering',
    },
    {
      name: 'Test2',
      level: 3,
      category: 'Administrasjon og koordingering',
    },
    {
      name: 'Test2',
      level: 3,
      category: 'Drift og utvikling av bygg',
    },
    {
      name: 'Test2',
      level: 3,
      category: 'IT',
    },
  ];

  return (
    <Page title="Min profil">
      {agreementToNavigateTo && (
        <Redirect
          to={{
            pathname: '/personal-improvement/employeeSign',
            state: {
              employeeSign: true,
              profile: agreementToNavigateTo.conductedBy,
              tasks: agreementToNavigateTo.tasks,
            },
          }}
        />
      )}
      <TopPart />
      <CVManage
        CVData={cv}
        onModeChange={onCVManageModeChange}
        manageMode={CVManageMode}
      />
      {(viewMode === viewModeCompetence || viewMode === viewModeAll) && (
        <Element name="competenceElem">
          <CompetenceManage
            personcompetences={personcompetences}
            manageMode={competenceManageMode}
            onModeChange={onCompetenceManageModeChange}
            profile={profile}
            competences={competences}
          />
        </Element>
      )}
      {viewMode === viewModeAll && (
        <>
          {agreements && agreements.length > 0 && (
            <AgreementsWaitingForSign
              agreementsForSign={agreements}
              onGotoAgreementSigning={onGotoAgreementSigning}
            />
          )}
          <Element name="archiveElem">
            <DoneTalksArchive
              talks={[
                {
                  conductedBy: { fullname: 'Erlend Østlie' },
                  responsible: { fullname: 'Sjef Sjefsen' },
                  date: '7 August 2019',
                },
                {
                  conductedBy: { fullname: 'Per Jensen' },
                  responsible: { fullname: 'Leder Ledersen' },
                  date: '19 August 2018',
                },
                {
                  conductedBy: { fullname: 'Jens Persen' },
                  responsible: { fullname: 'CEO CEOsen' },
                  date: '1 April 2019',
                },
              ]}
            />
          </Element>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  cv: getCV(state),
  personcompetences: getPersoncompetences(state),
  competences: getCompetences(state),
});

const mapDispatchToProps = dispatch => {
  return {
    getProfileData: () => dispatch(profileFetchPerson()),
    getCVData: () => dispatch(profileFetchCV()),
    getCompetencesData: () => dispatch(fetchCompetences()),
    getPersonCompetencesData: () => dispatch(profileFetchPersoncompetences()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfile);

MyProfile.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  cv: PropTypes.shape({}).isRequired,
  competences: PropTypes.shape({}).isRequired,
  personcompetences: PropTypes.shape({}).isRequired,
  getProfileData: PropTypes.func.isRequired,
  getCVData: PropTypes.func.isRequired,
  getCompetencesData: PropTypes.func.isRequired,
  getPersonCompetencesData: PropTypes.func.isRequired,
};
