import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useField } from '@rocketseat/unform';
import { colors } from '@src/styles/index';
import { useOnClickOutside } from '@src/hooks/hooks';
import ReactDatepicker from 'react-datepicker';
import { isValidDate } from '@src/utils/date.utils';
import { isValid } from 'date-fns';
import { Label, Column, Row } from './Layout';
import Button from './Button';
import 'react-datepicker/dist/react-datepicker.css';
import { propsUtils } from '../utils';

const HtmlLabel = styled(Column.withComponent('label'))`
  position: relative;
  flex: 1 1 auto;
`;
export const DatepickerLabel = styled.span`
  position: relative;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: fit-content;
  }

  .react-datepicker {
    border: 1px solid ${colors.blue};
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

    &-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: ${colors.blue};

      ::before {
        border-bottom-color: ${colors.cellBorder};
      }
    }

    &__time-list-item--selected,
    &__time-list-item--selected {
      background-color: ${colors.blue};
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${colors.blue};
    }

    .react-datepicker__time-box {
      border-radius: 0;
    }

    &-popper {
      z-index: 3;
    }

    &__header {
      padding-left: 8px;
      padding-right: 8px;
      background-color: #0a62cd;
      border-bottom: 1px solid ${colors.blue};
      border-top-left-radius: 2px;
    }

    &-time__header,
    &__current-month,
    &__day-name {
      color: #fff;
    }

    &__navigation--previous {
      border-right-color: #fff;
    }

    &__navigation--next {
      border-left-color: #fff;
      :hover {
        border-left-color: #fff;
      }
    }

    &__day--selected,
    &__month-text--selected,
    &__day--keyboard-selected,
    &__month-text--keyboard-selected {
      &,
      :hover {
        background-color: ${colors.link};
      }
    }
    &__day--keyboard-selected,
    &__month-text--keyboard-selected {
      &,
      :hover {
        background-color: #ccc;
      }
    }
  }
`;

const StyledInput = styled.input`
  border: 1px solid ${({ error }) => (error ? colors.error : colors.cellBorder)};
  font-size: 1rem;
  padding: ${({ small }) => (small ? '10px 14px' : '16px 20px')};
  border-radius: ${({ small }) => (small ? '3px' : '10px')};
  width: 100%;
  margin: 8px 0;

  :focus {
    ${({ small }) =>
      small
        ? `border: 1px solid #1573e6;`
        : 'box-shadow: inset 0 1px 0 0 #e3edfc, 0 0 0 4px #e3edfc;'}
  }
`;

const StyledMultiline = styled.textarea`
  border: 1px solid ${({ error }) => (error ? colors.error : colors.cellBorder)};
  font-size: 1rem;
  padding: ${({ small }) => (small ? '10px 14px' : '16px 20px')};
  border-radius: ${({ small }) => (small ? '3px' : '10px')};
  width: 100%;

  resize: vertical;

  ${({ dropdownOpen }) =>
    dropdownOpen &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  :focus {
    ${({ small }) =>
      small
        ? `border: 1px solid #1573e6;`
        : 'box-shadow: inset 0 1px 0 0 #e3edfc, 0 0 0 4px #e3edfc;'}
  }
`;

const StyledDatePicker = styled(ReactDatepicker)`
  border: 1px solid ${({ error }) => (error ? colors.error : colors.blue)};
  font-size: 1rem;
  padding: ${({ small }) => (small ? '10px 14px' : '16px 20px')};
  border-radius: ${({ small }) => (small ? '3px' : '10px')};
  margin: 8px 0;

  :focus {
    ${({ small }) =>
      small
        ? css`
            border: 1px solid #1573e6;
          `
        : css`
            box-shadow: inset 0 1px 0 0 #e3edfc, 0 0 0 4px #e3edfc;
          `}
  }
`;

const InputWrapper = styled.span`
  position: relative;
  display: block;
`;

// const ClearButton = styled.div`
//   display: flex;
//   align-items: center;
//   position: absolute;
//   padding: 0 0.5em;
//   top: 0;
//   bottom: 0;
//   vertical-align: middle;
//   right: 0.2em;
//   color: ${colors.fieldIcon};
//   cursor: pointer;
//   font-size: 0.8em;
// `;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  margin-top: -8px;
  width: fit-content;
  z-index: 2;
`;

const ChoiceButton = styled(Button)`
  padding: ${({ small }) => (small ? '10px 14px' : '16px 20px')};
  border-radius: ${({ small }) => (small ? '3px' : '10px')};
  border: 1px solid #eff0f1;

  ${({ choice, small }) =>
    choice &&
    css`
      border: 1px solid #1573e6;
      color: #1473e6;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        border-top-right-radius: ${small ? '3px' : '10px'};
        width: ${small ? '10px' : '12px'};
        height: ${small ? '10px' : '12px'};
        background: inherit;
        transition: background 0.115s ease-in;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 50%;
        width: ${small ? '6px' : '8px'};
        height: ${small ? '6px' : '8px'};
        background: #1573e6;
      }
    `}
`;

const Error = ({ children }) => (
  <Label
    marginTop="-8px"
    marginBottom="8px"
    marginLeft="1em"
    size="0.9em"
    lineHeight="2em"
    color={colors.warn}>
    {children}
  </Label>
);

Error.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Input = ({
  name,
  label,
  placeholder,
  small,
  multiline,
  dropdown,
  onChange,
  onFocus,
  children,
  error: errorProp,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [value, setValue] = useState(defaultValue);

  useOnClickOutside(() => setDropdownOpen(false), [ref, dropdownRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  let Input;

  if (multiline) {
    Input = StyledMultiline;
  } else {
    Input = StyledInput;
  }

  return (
    <HtmlLabel grow>
      {label && (
        <Label
          color={colors.fontLight}
          marginLeft="4px"
          marginTop="6px"
          marginBottom="-2px">
          {label}
        </Label>
      )}
      <InputWrapper>
        <Input
          ref={ref}
          placeholder={placeholder}
          multiline={multiline}
          onFocus={() => {
            if (onFocus) {
              onFocus();
            }
            setDropdownOpen(true);
            return true;
          }}
          name={fieldName}
          value={value}
          small={small}
          onChange={e => {
            if (onChange) {
              onChange(e.target.value);
            }
            setValue(e.target.value);
            e.stopPropagation();
          }}
          {...(dropdown ? { autoComplete: 'off' } : {})}
          {...props}
        />
        {dropdownOpen && dropdown && (
          <DropdownMenu ref={dropdownRef}>{dropdown}</DropdownMenu>
        )}
        {children}
      </InputWrapper>
      {(error || errorProp) && <Error>{error || errorProp}</Error>}
    </HtmlLabel>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  small: PropTypes.bool,
  multiline: PropTypes.bool,
  dropdown: PropTypes.node,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.string,
};

Input.defaultProps = {
  label: null,
  small: false,
  multiline: false,
  dropdown: null,
  onChange: null,
  onFocus: null,
  children: null,
  error: null,
};

export const InputChoices = ({
  label,
  small,
  name,
  choices,
  values,
  onChange,
  value: valueProp,
  error: errorProp,
  children,
}) => {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selected, setSelected] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue || valueProp);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }

    if (ref.current) {
      setValue(selected);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(valueProp);
  }, [valueProp]);

  return (
    <Column grow>
      {label && (
        <Label
          color={colors.fontLight}
          marginLeft="4px"
          marginTop="6px"
          marginBottom="-2px">
          {label}
        </Label>
      )}
      <Row marginTop="8px" marginBottom="8px">
        {choices.map((choice, key) => (
          <ChoiceButton
            key={key}
            small={small}
            choice={values ? selected === values[key] : selected === key}
            onClick={e => {
              e.preventDefault();
              setSelected(values ? values[key] : key);
            }}>
            {choice}
          </ChoiceButton>
        ))}
        {children}
      </Row>
      <input ref={ref} type="hidden" name={fieldName} value={value || ''} />
      {(error || errorProp) && <Error>{error || errorProp}</Error>}
    </Column>
  );
};

InputChoices.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
  name: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.any,
  children: PropTypes.node,
  error: PropTypes.string,
};

InputChoices.defaultProps = {
  label: null,
  small: false,
  values: null,
  onChange: null,
  value: null,
  children: null,
  error: null,
};

/**
 * To be used in a unform-form.
 *
 * Has two modes of operation:
 *    *  (default) the date value(a valid date string or a date object) is set by the client each render. Value changes are spotted using onChange
 *     * (activated by setting valueControlledByForm to true) a default value may be specified, but value and value changes ae not handled by the client. The final value is retired on form submission.
 */
export const DatePicker = ({
  name,
  label,
  small,
  simple,
  placeholder,
  onChange,
  children,
  error: errorProp,
  showTimeInput,
  // showClearButton,
  timeIntervals,
  valueControlledByForm,
  value,
}) => {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(value || defaultValue);

  // const clear = () => {
  //   setDate(null);
  //   onChange(null);
  // };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]);

  if (!valueControlledByForm) {
    useEffect(() => {
      if (isValidDate(value)) {
        setDate(value);
      } else if (value) {
        setDate(new Date(value));
      } else {
        setDate(null);
      }
    }, [value]);
  }

  const SimpleDatePicker = ({ value, onClick }) => (
    <div
      {...propsUtils.onClick(onClick)}
      css={`
        margin-top: 0.2em;
        width: 100%;
        ${!value &&
          css`
            color: ${colors.fontLight};
          `}
      `}>
      {value || placeholder}
    </div>
  );

  const DatePickerButton = ({ value, onClick }) => (
    <ChoiceButton
      secondary
      small={small}
      onClick={onClick}
      css={
        !value &&
        css`
          color: ${colors.fontLight};
        `
      }>
      {value || 'Velg dato'}
    </ChoiceButton>
  );

  const TimePickerButton = ({ value, onClick }) => (
    <ChoiceButton
      secondary
      small={small}
      onClick={onClick}
      css={`
        margin-left: 6px;
        ${!value &&
          css`
            color: ${colors.fontLight};
          `}
      `}>
      {value || '00:00'}
    </ChoiceButton>
  );

  SimpleDatePicker.propTypes = {
    value: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  DatePickerButton.propTypes = {
    value: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  TimePickerButton.propTypes = {
    value: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  return (
    <DatepickerLabel grow>
      {label && (
        <Label
          color={colors.fontLight}
          marginLeft="4px"
          marginTop="6px"
          marginBottom="-2px">
          {label}
        </Label>
      )}
      <Row marginTop={!simple && '8px'} marginBottom={!simple && '8px'}>
        {simple ? (
          <StyledDatePicker
            ref={ref}
            small={small}
            dateFormat="d. MMMM yyyy HH:mm"
            name={fieldName}
            selected={isValid(date) ? date : null}
            onChange={date => {
              setDate(date);
              onChange(date);
            }}
            customInput={<SimpleDatePicker />}
          />
        ) : (
          <>
            <StyledDatePicker
              ref={ref}
              small={small}
              dateFormat="d. MMMM yyyy"
              name={fieldName}
              selected={isValid(date) ? date : null}
              onChange={date => {
                setDate(date);
                if (onChange) {
                  onChange(date);
                }
              }}
              customInput={<DatePickerButton />}
            />
            {showTimeInput && (
              <StyledDatePicker
                small={small}
                placeholderText="00:00"
                timeInput
                showTimeSelect
                showTimeSelectOnly
                timeInputLabel="Klokkeslett"
                dateFormat="HH:mm"
                timeIntervals={timeIntervals || 30}
                name={fieldName}
                selected={isValid(date) ? date : null}
                onChange={date => {
                  setDate(date);
                  onChange(date);
                }}
                customInput={<TimePickerButton />}
              />
            )}
          </>
        )}
      </Row>
      {/* {date && showClearButton && (
        <ClearButton onClick={clear}>
          <Icon className="fa fa-times" />
        </ClearButton>
      )} */}
      {children}
      {(error || errorProp) && <Error>{error || errorProp}</Error>}
    </DatepickerLabel>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  small: PropTypes.bool,
  simple: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.string,
  showTimeInput: PropTypes.bool,
  // showClearButton: PropTypes.bool,
  timeIntervals: PropTypes.number,
  value: PropTypes.any,

  // If the value should be controlled by an unform-form, else the valeu have to be set expicitly each render
  valueControlledByForm: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: null,
  placeholder: null,
  small: false,
  simple: false,
  onChange: null,
  children: null,
  error: null,
  showTimeInput: false,
  // showClearButton: false,
  timeIntervals: null,
  value: null,
  valueControlledByForm: false,
};
