import { takeLatest, put } from 'redux-saga/effects';
import {
  PROFILE_FETCH_CV,
  profileFetchCVRequest,
  profileFetchCVFailure,
  profileFetchCVSuccess,
  profileEditCVRequest,
  PROFILE_EDIT_CV,
  profileEditCVFailure,
  profileEditCVSuccess,
} from '@actions/cvs.actions';
import axios from 'axios';
import { authUnauthorized } from '@actions/auth.actions';
import retry from '@utils/sagas.utils';
import { backendUrl } from '@config';

function* fetchProfileCV() {
  yield put(profileFetchCVRequest());
  try {
    const cv = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      })
    );

    yield put(profileFetchCVSuccess({ data: cv.data.cv }));
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(profileFetchCVFailure({ error }));
    }
  }
}

function* editProfileCV(action) {
  yield put(profileEditCVRequest());

  try {
    const cv = yield retry(() =>
      axios.request({
        method: 'POST',
        params: { form_data: action.payload.data },
        url: `${backendUrl}/api/cvs`,
        withCredentials: true,
      })
    );

    yield put(profileEditCVSuccess({ data: cv.data.cv }));
  } catch (error) {
    if (error.status === 401) {
      yield put(authUnauthorized({ error }));
    } else {
      yield put(profileEditCVFailure({ error }));
    }
  }
}

export default [
  takeLatest(PROFILE_FETCH_CV, fetchProfileCV),
  takeLatest(PROFILE_EDIT_CV, editProfileCV),
];
